<div class="faq-container">
  <h3 class="section-title text-center">{{ headingText ? headingText : 'FAQs' }}</h3>
  <ng-container *ngFor="let FAQ of FAQs; let index = index">
    <div fxLayout="column" fxLayoutAlign="center center" class="margin-bottom-3">
      <mat-expansion-panel
        class="exp-panel-wrapper"
        [ngClass]="expanded.includes(index) ? 'open-shadow' : 'close-shadow'">
        <mat-expansion-panel-header class="header-wrapper" (click)="setActiveIndex(index)">
          <mat-panel-title>
            <h4 class="font-md margin-0"
            [ngStyle]="{
              color: expanded.includes(index)
                ? headingColor
                  ? headingColor
                  : '#f15a24'
                : '#232323'
            }">
            {{ FAQ.question }}
          </h4>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <p class="font-regular font-sm sparro-grey-900">{{ FAQ.answer }}</p>
      </mat-expansion-panel>
    </div>
  </ng-container>
</div>
