import { Component, OnInit } from '@angular/core';
import { UserService } from '@sparro/app/core/api/user/user.service';
import { User } from '@sparro/app/core/models/user.model';
import { MetaDefinition } from '@angular/platform-browser';
import { MetaService } from '@sparro/app/core/helpers/meta/meta.service';
import { AboutService } from '@about/app/core/api/about/about.service';
import { environment } from '@about/env/environment';

@Component({
  selector: 'app-business-pages',
  templateUrl: './business-pages.component.html',
  styleUrls: ['./business-pages.component.scss']
})
export class BusinessPagesComponent implements OnInit {
  user: User = null;
  defaultImage: string =
    'https://uni-competitions.s3.amazonaws.com/anonymous/xiao/%E5%B9%BB%E7%81%AF%E7%89%876.JPG';
  cardDetails: any = [];
  pageDetail: object = {
    title: 'UNI for Businesses',
    desc: `We want to help businesses explore a range of exclusive services on UNI. 
    We're experts when it comes to serving businesses in the construction industry, such as real 
    estate developers and building material companies.`,
    url: `${environment.aboutUrl}about/business`
  };
  constructor(
    private userService: UserService,
    private metaService: MetaService,
    private aboutService: AboutService
  ) {}

  ngOnInit(): void {
    this.getAuthUser();
    this.getPagesDetail();
  }

  getAuthUser(): void {
    this.userService.userInitialized.subscribe((initialized: boolean) => {
      if (initialized) {
        this.userService.user.subscribe((user: User) => {
          this.user = user;
        });
      }
    });
  }

  getPagesDetail() {
    this.aboutService.getBusinessPagesDetail('Businesses').subscribe(response => {
      this.cardDetails = response;
      this.updateMetaInfo();
    });
  }

  updateMetaInfo(): void {
    const title = 'UNI for Businesses';
    const description = `We want to help businesses explore a range of exclusive services on UNI. 
      We're experts when it comes to serving businesses in the construction industry, such as real 
      estate developers and building material companies.`;
    const cover = this.cardDetails[0].image;
    const metaInfo: MetaDefinition[] = [
      { name: 'title', content: title },
      { name: 'description', content: description },
      { name: 'url', content: window.location.href },
      { name: 'image', content: cover },
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:title', content: title },
      { name: 'twitter:description', content: description },
      { name: 'twitter:url', content: window.location.href },
      { name: 'twitter:image', content: cover },
      { property: 'og:title', content: title },
      { property: 'og:description', content: description },
      { property: 'og:url', content: window.location.href },
      { property: 'og:image', content: cover },
      { property: 'og:image:alt', content: 'UNI- Design competitions' },
      { property: 'og:type', content: 'article' }
    ];
    metaInfo.forEach(meta => {
      this.metaService.updateMetaInfo(meta);
    });
    this.metaService.updateMeta(metaInfo);
    this.metaService.updateTitle(title);
    this.metaService.createCanonicalURL();
  }
}
