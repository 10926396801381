<div class="main-container">
  <div class="cover-wrapper">
    <div class="cover-container" fxLayout="column">
      <app-landing-page-header [smallView]="smallView" [user]="user" [blackLogo]="false">
      </app-landing-page-header>
      <div class="content-container" fxLayout="column" fxLayoutAlign="space-between">
        <div class="main-content">
          <div class="text-section" fxLayout="column">
            <div class="title-section">
              <h1 class="title margin-0">Become a juror on UNI</h1>
            </div>
            <p class="subtitle margin-0">
              Guide the next generation of architects and designers by sharing your expertise to the
              vibrant community of UNI
            </p>
          </div>
          <div class="img-section">
            <uni-image
              src="https://uni-common-assets.s3.amazonaws.com/experts/juror/cover-image.svg"
              classes="absolute cover-img-center"
              alt="cover-image">
            </uni-image>
          </div>
        </div>
        <div class="sub-topic-cover-content">
          <div class="wrapper" fxLayout="column">
            <div class="text-section width-100 margin-auto" fxLayout="column">
              <div class="title-section">
                <h2 class="title margin-0">What is a juror on UNI?</h2>
              </div>
              <p class="subtitle margin-0">
                We believe a juror is someone who sympathizes with their competition and takes pride
                in the work they see coming from the community. At UNI, many young architects &
                designers submit their projects for varying disciplines in the field of architecture
                & design. They not only invest their time and energy into these projects but also
                wish to learn more about how to shape their projects better as they develop them
                over time. This is where a juror helps, who can give meaningful feedback that helps
                participants improve upon their existing entries!
              </p>
            </div>
            <div class="width-100" fxLayout="row" fxLayoutAlign="center center">
              <div class="card-container">
                <ng-container *ngFor="let card of platformFeatures">
                  <div class="platform-feature-card" fxLayout="column" fxLayoutGap="40px">
                    <uni-image [src]="card.icon" alt="card-icon" classes="big-dot"></uni-image>
                    <div
                      class="card-text-section"
                      fxLayout="column"
                      fxLayoutAlign="center"
                      fxLayoutGap="24px">
                      <h4 class="title margin-0">{{ card.title }}</h4>
                      <p class="description margin-0">{{ card.description }}</p>
                    </div>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="detail-section">
    <div fxLayout="column" fxLayoutAlign="center center" class="heading-section">
      <h2 class="margin-0 title sparro-grey-900">Why be a juror on UNI?</h2>
      <p class="subtitle margin-0">
        Being a juror on UNi provides you a host of opportunities to contribute to the architecture
        & design community and get recognition for the same.
      </p>
    </div>
    <div class="inner-container">
      <div class="sub-section">
        <div
          class="text-section"
          fxLayout="column"
          fxLayoutAlign="space-between start"
          fxLayoutGap="24px">
          <h3 class="title margin-0">Flexible timeline for evaluation</h3>
          <p class="description margin-0 sparro-grey-800">
            We present you with shortlisted entries for the competition you will be judging. The
            evaluation process can be flexibly completed between a period of 3 weeks.
          </p>
        </div>
        <div class="relative image-section">
          <uni-image
            class="section-1-bg absolute"
            alt="section-1-bg"
            src="https://uni-common-assets.s3.amazonaws.com/experts/juror/section-1-bg.svg"
            classes="fit-cover width-100 height-100"></uni-image>
          <uni-image
            class="evaluate-img absolute frame"
            alt="evaluate-img"
            src="https://uni-common-assets.s3.amazonaws.com/experts/juror/evaluate-img.svg"
            classes="fit-cover width-100 height-100"></uni-image>
          <uni-image
            class="saly-img absolute"
            alt="saly-img"
            src="https://uni-common-assets.s3.amazonaws.com/experts/juror/saly-img.svg"
            classes="fit-cover width-100 height-100"></uni-image>
        </div>
      </div>
      <div class="sub-section odd-section">
        <div class="relative image-section">
          <uni-image
            class="section-2-bg absolute"
            alt="dotted-circle"
            src="https://uni-common-assets.s3.amazonaws.com/experts/juror/dotted-circle.svg"
            classes="fit-cover width-100 height-100"></uni-image>
          <uni-image
            class="desktop-view-img frame absolute"
            alt="desktop-view-img"
            src="https://uni-common-assets.s3.amazonaws.com/experts/juror/desktop-2.svg"
            classes="fit-cover width-100 height-100"></uni-image>
          <uni-image
            class="mobile-view-img frame absolute"
            alt="mobile-view-img"
            src="https://uni-common-assets.s3.amazonaws.com/experts/juror/mobile-2.svg"
            classes="fit-cover width-100 height-100"></uni-image>
        </div>
        <div
          class="text-section"
          fxLayout="column"
          fxLayoutAlign="space-between start"
          fxLayoutGap="24px">
          <h3 class="title margin-0">Friendly interface</h3>
          <p class="description margin-0 sparro-grey-800">
            A simple dashboard with detailed analytics to access all the projects, and no emailing
            of entries or heavy downloads. The evaluation dashboard is compatible to various devices
            including mobile devices as well.
          </p>
        </div>
      </div>
      <div class="sub-section">
        <div
          class="text-section"
          fxLayout="column"
          fxLayoutAlign="space-between start"
          fxLayoutGap="24px">
          <h3 class="title margin-0">A token of honor</h3>
          <p class="description margin-0 sparro-grey-800">
            Jurors have always been at the pristine spot in design education. Practically nothing
            can be above than a badge of juror in a competition. Thus, to mark the position and
            extend our appreciation, you will receive a memento for being associated with our
            community.
          </p>
        </div>
        <div class="relative image-section">
          <uni-image
            class="section-3-bg absolute"
            alt="section-3-bg-img"
            src="https://uni-common-assets.s3.amazonaws.com/experts/juror/section-3-bg.svg"
            classes="fit-cover width-100 height-100"></uni-image>
        </div>
      </div>
      <div class="sub-section odd-section">
        <div class="relative image-section">
          <uni-image
            class="section-4-bg absolute"
            alt="section-4-bg-img"
            src="https://uni-common-assets.s3.amazonaws.com/experts/juror/section-4-bg.svg"
            classes="fit-cover width-100 height-100"></uni-image>
          <uni-image
            class="insta-img absolute frame"
            alt="insta-img"
            src="https://uni-common-assets.s3.amazonaws.com/experts/juror/insta-img.svg"
            classes="fit-cover width-100 height-100"></uni-image>
          <uni-image
            class="social-img absolute frame"
            alt="social-img"
            src="https://uni-common-assets.s3.amazonaws.com/experts/juror/social-img.svg"
            classes="fit-cover width-100 height-100"></uni-image>
        </div>
        <div
          class="text-section"
          fxLayout="column"
          fxLayoutAlign="space-between start"
          fxLayoutGap="24px">
          <h3 class="title margin-0 width-100">Media visibility and recognition</h3>
          <p class="description margin-0 sparro-grey-800">
            While you provide your invaluable inputs to the participants, we ensure to give
            visibility of your work and your profile to over A Million annual visitors on our
            platform.
          </p>
        </div>
      </div>
    </div>
  </div>

  <div
    fxLayout="column"
    fxLayoutAlign="center center"
    fxLayoutGap="64px"
    class="how-it-work-section margin-top-20 margin-bottom-30">
    <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="24px">
      <h2 class="margin-0 heading">Our Jurors</h2>
      <p class="margin-0 subtitle">Join the esteemed pool of 1000+ global experts on UNI</p>
    </div>

    <div fxLayout="row wrap" fxLayoutAlign="center center" class="juror-cards-container">
      <ng-container *ngFor="let juror of jurors">
        <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="24px" class="juror-card">
          <uni-user-avatar [user]="juror.user" avatarClass="juror-card-img"> </uni-user-avatar>
          <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="4px">
            <a [href]="getProfileUrl(juror)" class="margin-0 title no-decor">
              {{ juror.user.first_name + ' ' + juror.user.last_name | truncate : 22 }}
            </a>
            <p class="margin-0 desc">{{ juror.bio | truncate : 60 }}</p>
          </div>
        </div>
      </ng-container>
    </div>

    <ng-container *ngIf="isLoading; else buttonView">
      <div fxLayout="row" fxLayoutAlign="center center" class="padding-y-10">
        <uni-loader></uni-loader>
      </div>
    </ng-container>
    <ng-template #buttonView>
      <div class="button-section">
        <a [href]="getJurorPageUrl()" class="no-decor cursor-pointer button font-bolder">
          Explore more
        </a>
      </div>
    </ng-template>
  </div>

  <div
    fxLayout="column"
    fxLayoutAlign="center center"
    fxLayoutGap="64px"
    class="how-it-work-section">
    <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="24px">
      <h2 class="margin-0 heading">Become a torchbearer of the community</h2>
      <p class="subtitle margin-0">How it works?</p>
    </div>
    <div class="inner-section">
      <ng-container *ngFor="let step of workingSteps">
        <div
          fxLayout="column"
          fxLayoutAlign="center center"
          fxLayoutGap="24px"
          class="card-container">
          <uni-image [src]="step.icon" alt="step-icon" classes="steps-icon"></uni-image>
          <h4 class="margin-0 title">{{ step.title }}</h4>
          <p class="margin-0 subtitle">{{ step.description }}</p>
        </div>
      </ng-container>
    </div>
    <div class="button-section">
      <div class="cursor-pointer button font-bolder" (click)="openForm()">Get started</div>
    </div>
    <div class="form-section" *ngIf="formOpened">
      <div class="button-section">
        <button class="fab-button mat-mini-fab" (click)="close()">
          <i
            [ngClass]="{
              'icon-arrow-down-bold': smallView,
              'icon-arrow-right-bold': !smallView
            }"></i>
        </button>
      </div>
      <div class="iframe-container">
        <iframe
          allowtransparency="true"
          allowfullscreen="true"
          allow="geolocation; microphone; camera"
          src="https://forms.gozen.io/TEqSHEGGie0GsdifFDPz"
          frameborder="0">
        </iframe>
      </div>
    </div>
  </div>

  <uni-frequent-questions [headingColor]="faqHeadingColor" [FAQs]="frequentlyAskedQuestions">
  </uni-frequent-questions>
  <div class="banner-section">
    <div class="text-section margin-auto" fxLayout="column" fxLayoutAlign="center center">
      <h2 class="title margin-0">Heads up!! There are more ways to contribute on UNI</h2>
      <div class="button-section">
        <a [href]="getCuratorPageUrl()" class="no-decor cursor-pointer button font-bolder">
          Become a Curator
        </a>
        <a [href]="getMentorPageUrl()" class="no-decor cursor-pointer button font-bolder">
          Become a Mentor
        </a>
      </div>
    </div>
  </div>
</div>
<uni-public-footer [topMargin]="false"></uni-public-footer>
