import { Component, Input, OnInit } from '@angular/core';
import { User } from '@sparro/app/core/models/user.model';
import { UserService } from '@sparro/app/core/api/user/user.service';

@Component({
  selector: 'app-page-top-section',
  templateUrl: './page-top-section.component.html',
  styleUrls: ['./page-top-section.component.scss']
})
export class PageTopSectionComponent implements OnInit {
  @Input() data;
  @Input() smallView: boolean = false;
  @Input() platformFeatures;
  @Input() competitions;
  @Input() host: string;
  user: User;
  contributionForm: boolean = false;
  competitionLaunchForm: boolean = false;
  numericDetails = [
    { title: '250K+', description: 'Designers on UNI' },
    { title: '10K+', description: 'Exclusive projects ' },
    { title: '600+', description: 'Competitions' },
    { title: '1M+', description: 'Annual visitors' }
  ];

  constructor(private userService: UserService) {}

  ngOnInit(): void {
    this.getAuthUser();
  }

  getAuthUser(): void {
    this.userService.userInitialized.subscribe((initialized: boolean) => {
      if (initialized) {
        this.userService.user.subscribe((user: User) => {
          this.user = user;
        });
      }
    });
  }
}
