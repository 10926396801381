import { FEATURES } from '@about/app/core/configs/landing.config';
import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, OnInit } from '@angular/core';
import { UserService } from '@sparro/app/core/api/user/user.service';
import { BREAKPOINTS } from '@sparro/app/core/config/constants.config';
import { User } from '@sparro/app/core/models/user.model';
import { FAQService } from '@sparro/app/core/api/faq/faq.service';
import { FAQ } from '@sparro/app/core/models/faq.model';
import { MetaDefinition } from '@angular/platform-browser';
import { MetaService } from '@sparro/app/core/helpers/meta/meta.service';

export class Features {
  title: string;
  description: string;
  icon?: string;
}

export class PackageOrPlan {
  title: string;
  price: number;
  unit: string;
  features: string[];
  buttonText: string;
  url: string;
  isPopular: boolean;
}

@Component({
  selector: 'app-portfolio',
  templateUrl: './portfolio.component.html',
  styleUrls: ['./portfolio.component.scss']
})
export class PortfolioComponent implements OnInit {
  user: User;
  smallView: boolean = false;
  contributionForm: boolean = false;
  competitionLaunchForm: boolean = false;
  imgFrame = FEATURES.BUSINESS_PAGE;
  frequentlyAskedQuestions: FAQ[] = [];
  faqHeadingColor: string = '#016358';

  platformFeatures: Features[] = [
    {
      title: 'UNIfied  portfolio',
      description: `No more dealing with a bunch of links to showcase your work. Build a multi-dimensional portfolio 
        with not just visuals, but writing, and videos, reflecting 360 degrees of you.`,
      icon: 'https://uni-common-assets.s3.amazonaws.com/designers/portfolio/icon-1.svg'
    },
    {
      title: 'Ad-free experience',
      description: `UNI portfolio is as good as having your website, presenting your detailed resumé, and working together. 
        Plus, it is completely free and ad-free, so you get the best of both worlds. `,
      icon: 'https://uni-common-assets.s3.amazonaws.com/designers/portfolio/icon-2.svg'
    },
    {
      title: 'Get discovered',
      description: `Get the experience of a personal website with the benefits of networking! The platform’s features help you get discovered by fellow designers and potential clients.`,
      icon: 'https://uni-common-assets.s3.amazonaws.com/designers/portfolio/icon-3.svg'
    }
  ];

  constructor(
    private userService: UserService,
    public breakpointObserver: BreakpointObserver,
    private faqService: FAQService,
    public metaService: MetaService
  ) {}

  ngOnInit(): void {
    this.updateMetaInfo();
    this.getAuthUser();
    this.initializeBreakpoints();
    this.getFaqs();
  }

  getFaqs(): void {
    this.faqService.getFAQs('designers-portfolio').subscribe((response: FAQ[]) => {
      this.frequentlyAskedQuestions = response;
    });
  }

  getAuthUser(): void {
    this.userService.userInitialized.subscribe((initialized: boolean) => {
      if (initialized) {
        this.userService.user.subscribe((user: User) => {
          this.user = user;
        });
      }
    });
  }

  initializeBreakpoints(): void {
    this.breakpointObserver.observe([BREAKPOINTS.below.md]).subscribe(result => {
      this.smallView = Boolean(result.breakpoints[BREAKPOINTS.below.md]);
    });
  }

  updateMetaInfo(): void {
    const title = 'Build your online architecture portfolio in style';
    const description = `Do you want to build your online architecture portfolio? Look no further than UNI! With UNI, 
      you can easily showcase your work, write blogs, share publications and connect with a global community. 
      Start building your portfolio today!`;
    const cover = `https://uni-common-assets.s3.amazonaws.com/designers/portfolio/cover-img-3.svg`;
    const metaInfo: MetaDefinition[] = [
      { name: 'title', content: title },
      { name: 'description', content: description },
      { name: 'url', content: window.location.href },
      { name: 'image', content: cover },
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:title', content: title },
      { name: 'twitter:description', content: description },
      { name: 'twitter:url', content: window.location.href },
      { name: 'twitter:image', content: cover },
      { property: 'og:title', content: title },
      { property: 'og:description', content: description },
      { property: 'og:url', content: window.location.href },
      { property: 'og:image', content: cover },
      { property: 'og:image:alt', content: 'UNI- Design competitions' },
      { property: 'og:type', content: 'article' }
    ];
    metaInfo.forEach(meta => {
      this.metaService.updateMetaInfo(meta);
    });
    this.metaService.updateMeta(metaInfo);
    this.metaService.updateTitle(title);
    this.metaService.createCanonicalURL();
  }
}
