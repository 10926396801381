import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-institution-group',
  templateUrl: './institution-group.component.html',
  styleUrls: ['./institution-group.component.scss']
})
export class InstitutionGroupComponent implements OnInit {
  institutesImgUrl: string[] = [
    'https://uni-common-assets.s3.amazonaws.com/designers/institutional-access/institute-16.svg',
    'https://uni-common-assets.s3.amazonaws.com/designers/institutional-access/institute-15.svg',
    'https://uni-common-assets.s3.amazonaws.com/designers/institutional-access/institute-14.svg',
    'https://uni-common-assets.s3.amazonaws.com/designers/institutional-access/institute-13.svg',
    'https://uni-common-assets.s3.amazonaws.com/designers/institutional-access/institute-12.svg',
    'https://uni-common-assets.s3.amazonaws.com/designers/institutional-access/institute-11.svg',
    'https://uni-common-assets.s3.amazonaws.com/designers/institutional-access/institute-10.svg',
    'https://uni-common-assets.s3.amazonaws.com/designers/institutional-access/institute-9.svg',
    'https://uni-common-assets.s3.amazonaws.com/designers/institutional-access/institute-8.svg',
    'https://uni-common-assets.s3.amazonaws.com/designers/institutional-access/institute-7.svg',
    'https://uni-common-assets.s3.amazonaws.com/designers/institutional-access/institute-6.svg',
    'https://uni-common-assets.s3.amazonaws.com/designers/institutional-access/institute-5.svg',
    'https://uni-common-assets.s3.amazonaws.com/designers/institutional-access/institute-4.svg',
    'https://uni-common-assets.s3.amazonaws.com/designers/institutional-access/institute-3.svg',
    'https://uni-common-assets.s3.amazonaws.com/designers/institutional-access/institute-2.svg',
    'https://uni-common-assets.s3.amazonaws.com/designers/institutional-access/institute-1.svg'
  ];
  constructor() {}

  ngOnInit(): void {}
}
