<div class="main-container">
  <div class="cover-wrapper">
    <div class="cover-container" fxLayout="column">
      <app-landing-page-header [smallView]="smallView" [user]="user" [blackLogo]="false">
      </app-landing-page-header>
      <div class="content-container" fxLayout="column" fxLayoutAlign="space-between">
        <div class="main-content">
          <div class="text-section" fxLayout="column">
            <div class="title-section">
              <h1 class="title margin-0">Become a mentor on UNI</h1>
            </div>
            <p class="subtitle margin-0">
              Bring your expertise to the creators of tomorrow. Launch workshops, learning programs,
              and exercises that ignite the best minds globally.
            </p>
          </div>
          <div class="img-section">
            <uni-image
              src="https://uni-common-assets.s3.amazonaws.com/experts/mentor/cover-image.svg"
              classes="absolute cover-img-center"
              alt="cover-image">
            </uni-image>
          </div>
        </div>
        <div class="sub-topic-cover-content">
          <div class="wrapper" fxLayout="column">
            <div class="text-section width-100 margin-auto" fxLayout="column">
              <div class="title-section">
                <h2 class="title margin-0">What is a mentor on UNI?</h2>
              </div>
              <p class="subtitle margin-0">
                A mentor is a person of experience hailing from academia/professional background who
                crafts educational programmes with the level of realistic experience that is yet to
                get adopted by traditional institutionalized teaching. The broad canvas of UNI
                allows you to bring remarkable learning programs globally.
              </p>
            </div>
            <div class="width-100" fxLayout="row" fxLayoutAlign="center center">
              <div class="card-container">
                <ng-container *ngFor="let card of platformFeatures">
                  <div class="platform-feature-card" fxLayout="column" fxLayoutGap="40px">
                    <uni-image [src]="card.icon" alt="card-icon" classes="big-dot"></uni-image>
                    <div
                      class="card-text-section"
                      fxLayout="column"
                      fxLayoutAlign="center"
                      fxLayoutGap="24px">
                      <h4 class="title margin-0">{{ card.title }}</h4>
                      <p class="description margin-0">{{ card.description }}</p>
                    </div>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="detail-section">
    <div fxLayout="column" fxLayoutAlign="center center" class="heading-section">
      <h2 class="margin-0 title sparro-grey-900">Why become a mentor at UNI?</h2>
      <p class="subtitle margin-0">
        When pioneers in the industry share their skills with the young and budding members of the
        community, the field grows. Becoming a mentor is a privilege as it allows you to steer the
        direction for growth and earn both scalably to the globe.
      </p>
    </div>
    <div class="inner-container">
      <div class="sub-section">
        <div
          class="text-section"
          fxLayout="column"
          fxLayoutAlign="space-between start"
          fxLayoutGap="24px">
          <h3 class="title margin-0">Curate courses based on your specialization</h3>
          <p class="description margin-0 sparro-grey-800">
            Plan a course on topics and skills you’d like to share with us at UNI. Let your
            experience spark an idea on what we can cover during the course, and our curatorial team
            will help you design a successful course that we feel our community of designers will
            enjoy!
          </p>
        </div>
        <div class="relative image-section">
          <uni-image
            class="section-1-bg absolute"
            alt="section-1-bg"
            src="https://uni-common-assets.s3.amazonaws.com/experts/mentor/yellow-circle.svg"
            classes="fit-cover width-100 height-100"></uni-image>
          <uni-image
            class="card-1 absolute frame"
            alt="competition-card-img"
            src="https://uni-common-assets.s3.amazonaws.com/experts/mentor/competition-card-1.svg"
            classes="fit-cover width-100 height-100"></uni-image>
          <uni-image
            class="card-2 absolute frame"
            alt="competition-card-img"
            src="https://uni-common-assets.s3.amazonaws.com/experts/mentor/competition-card-2.svg"
            classes="fit-cover width-100 height-100"></uni-image>
        </div>
      </div>
      <div class="sub-section odd-section">
        <div class="relative image-section">
          <uni-image
            class="section-2-bg absolute"
            alt="yellow-circle"
            src="https://uni-common-assets.s3.amazonaws.com/experts/mentor/yellow-circle.svg"
            classes="fit-cover width-100 height-100"></uni-image>
          <uni-image
            class="desktop-view-img frame absolute"
            alt="desktop-view"
            src="https://uni-common-assets.s3.amazonaws.com/experts/mentor/desktop-view.svg"
            classes="fit-cover width-100 height-100"></uni-image>
          <uni-image
            class="saly-img absolute"
            alt="saly-img"
            src="https://uni-common-assets.s3.amazonaws.com/experts/mentor/saly.svg"
            classes="fit-cover width-100 height-100"></uni-image>
        </div>
        <div
          class="text-section"
          fxLayout="column"
          fxLayoutAlign="space-between start"
          fxLayoutGap="24px">
          <h3 class="title margin-0">Create exercises with UNI’s inbuilt tools</h3>
          <p class="description margin-0 sparro-grey-800">
            Once the course is launched, you can craft exercises/competitions which will help the
            participants apply what they learned. Take advantage of UNI's inbuilt tools, like
            Competition studio, Discussion forums, Evaluation portal, and other tools to host
            submissions and share them with everyone.
          </p>
        </div>
      </div>
      <div class="sub-section">
        <div
          class="text-section"
          fxLayout="column"
          fxLayoutAlign="space-between start"
          fxLayoutGap="24px">
          <h3 class="title margin-0">Give feedback</h3>
          <p class="description margin-0 sparro-grey-800">
            Like a classroom, gather queries and submissions at your dashboard to give critiques and
            remarks that help participants grow professionally.
          </p>
        </div>
        <div class="relative image-section">
          <uni-image
            class="section-3-bg absolute"
            alt="yellow-circle"
            src="https://uni-common-assets.s3.amazonaws.com/experts/mentor/yellow-circle.svg"
            classes="fit-cover width-100 height-100"></uni-image>
          <uni-image
            class="frame pdf-reader absolute"
            alt="pdf-reader"
            src="https://uni-common-assets.s3.amazonaws.com/experts/mentor/pdf-reader.svg"
            classes="fit-cover width-100 height-100"></uni-image>
          <uni-image
            class="frame comment-card-1 absolute"
            alt="comment-card-1"
            src="https://uni-common-assets.s3.amazonaws.com/experts/mentor/comment-card-1.svg"
            classes="fit-cover width-100 height-100"></uni-image>
          <uni-image
            class="frame comment-card-2 absolute"
            alt="comment-card-2"
            src="https://uni-common-assets.s3.amazonaws.com/experts/mentor/comment-card-2.svg"
            classes="fit-cover width-100 height-100"></uni-image>
        </div>
      </div>
      <div class="sub-section odd-section">
        <div class="relative image-section">
          <uni-image
            class="section-4-bg absolute"
            alt="section-4-bg"
            src="https://uni-common-assets.s3.amazonaws.com/experts/mentor/section-4-bg.svg"
            classes="fit-cover width-100 height-100"></uni-image>
        </div>
        <div
          class="text-section"
          fxLayout="column"
          fxLayoutAlign="space-between start"
          fxLayoutGap="24px">
          <h3 class="title margin-0 width-100">Earn royalty with your content</h3>
          <p class="description margin-0 sparro-grey-800">
            While completing the course, you also earn royalty with every course you host on UNI.
          </p>
        </div>
      </div>
    </div>
  </div>

  <div
    fxLayout="column"
    fxLayoutAlign="center center"
    fxLayoutGap="64px"
    class="how-it-work-section">
    <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="24px">
      <h2 class="margin-0 heading">Inspire the next generation of future makers</h2>
      <p class="subtitle margin-0">How it works?</p>
    </div>
    <div class="inner-section">
      <ng-container *ngFor="let step of workingSteps">
        <div
          fxLayout="column"
          fxLayoutAlign="center center"
          fxLayoutGap="24px"
          class="card-container">
          <uni-image [src]="step.icon" alt="step-icon" classes="steps-icon"></uni-image>
          <h4 class="margin-0 title">{{ step.title }}</h4>
          <p class="margin-0 subtitle">{{ step.description }}</p>
        </div>
      </ng-container>
    </div>
    <div class="button-section">
      <div class="cursor-pointer button font-bolder" (click)="openForm()">Get started</div>
    </div>
    <div class="form-section" *ngIf="formOpened">
      <div class="button-section">
        <button class="fab-button mat-mini-fab" (click)="close()">
          <i
            [ngClass]="{
              'icon-arrow-down-bold': smallView,
              'icon-arrow-right-bold': !smallView
            }"></i>
        </button>
      </div>
      <div class="iframe-container">
        <iframe
          allowtransparency="true"
          allowfullscreen="true"
          allow="geolocation; microphone; camera"
          src="https://forms.gozen.io/3d45yIwgCnfvjqZpEUPt"
          frameborder="0">
        </iframe>
      </div>
    </div>
  </div>

  <uni-frequent-questions [headingColor]="faqHeadingColor" [FAQs]="frequentlyAskedQuestions">
  </uni-frequent-questions>
  <div class="banner-section">
    <div class="text-section margin-auto" fxLayout="column" fxLayoutAlign="center center">
      <h2 class="title margin-0">Heads up!! There are more ways to contribute on UNI</h2>
      <div class="button-section">
        <a [href]="getCuratorPageUrl()" class="no-decor cursor-pointer button font-bolder">
          Become a Curator
        </a>
        <a [href]="getJurorPageUrl()" class="no-decor cursor-pointer button font-bolder">
          Become a Juror
        </a>
      </div>
    </div>
  </div>
</div>
<uni-public-footer [topMargin]="false"></uni-public-footer>
