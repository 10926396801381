export const TESTIMONIES = [
  {
    user: {
      name: 'Thomas CORBASSON',
      avatar_url: 'https://competitions-uni.s3.amazonaws.com/0/22/Jury/thomas-corbassion.jpg',
      designation: 'Architect Leader, CHARTIER-CORBASSON ARCHITECTES, France'
    },
    content: `The competitions on UNI have been one of the most innovative and instructive in true sense.
          The projects that I got a chance to evaluate and the opportunity I've got to be a part of shows real reflection of tomorrow.`
  },
  {
    user: {
      name: 'Stanislav Belykh',
      avatar_url: 'https://competitions-uni.s3.amazonaws.com/0/23/Jury/stanislav_belykh.jpg',
      designation: 'Partner, OSA, Russia'
    },
    content: `We thank UNI for inviting us to be a part of this fantastic competition as a jury partner. The platform is recently being
        discovered in our part of the world and we are extremely impressed by the quality and design input we have found within our
        experience. We'd probably like to get involved a lot more often!`
  },
  {
    user: {
      name: 'Dmitry Gusev',
      avatar_url: 'https://competitions-uni.s3.amazonaws.com/0/23/Jury/Dm.Gusev.jpg',
      designation: 'Project architect, "Ostozhenka" Architects Ltd, Moscow Russia.'
    },
    content: `I thank UNI for inviting me as Jury board member! It was really an interesting experience for me.
        I also want to express my sincere gratitude for all of UNI team, involved in organization of this challenge
        and, of course, to all participants, who did their job really great with all their skill and ingenuity!`
  },
  {
    user: {
      name: 'Cristina Verissimo',
      avatar_url: 'https://competitions-uni.s3.amazonaws.com/0/25/Jury/cristina-foto.jpg',
      designation: 'Office partner, CVDB Arquitectos, Lda, Portugal'
    },
    content: `It was a great pleasure for me to participate as jury member. I am very happy with the results.
        They are one of the most open competition hosting platform I've come across recently, trying something really
        new in this space. I wish them and the entire community all the best!`
  },
  {
    user: {
      name: 'Udi Kassif',
      avatar_url: 'https://competitions-uni.s3.amazonaws.com/0/37/Jury/dd.jpg',
      designation: 'CEO / Founder, Mayslits Kassif Architects, Israel'
    },
    content: `Architectural competitions are one of the best ways to speculate and explore new ways of learning and
        educating young designers of our society that will effect and change the world in their turn.
        UNI is a great platform to participate in these.`
  },
  {
    user: {
      name: 'Juanjo López De La Cruz',
      avatar_url: 'https://competitions-uni.s3.amazonaws.com/0/49/Jury/Juanjo_ByN.jpg',
      designation: 'Founding Partner, Estudio Sol89, Spain'
    },
    content: `I have enjoyed very much colaborating with UNI and reflecting about future cities through the evaluation of
        the projects in this exciting competition. I am glad with the project that has finally won and it makes me proud that
        the reflection we launched the competition initially has actually manifested in the final result.`
  },
  {
    user: {
      name: 'Bruno Campos',
      avatar_url:
        'https://competitions-uni.s3.amazonaws.com/0/91/jury/Bruno_Campos_-_IMG_0311-P_B.jpg',
      designation: 'Director, BCMF Arquitetos, Brazil'
    },
    content: `UNI brings an exciting way to promote research and exploration of architecture beyond our imagination.
        We are all fascinated and stimulated by the changes and developments in the technology and other creative medias.
        Architecture continuously looks for opportunities to borrow and be inspired by other disciplines and this platform
        is successful at bringing about such dialogues.`
  },
  {
    user: {
      name: 'Kourosh Hajizadeh',
      avatar_url: 'https://competitions-uni.s3.amazonaws.com/0/2/Jury/kourosh-hajizadeh.JPG',
      designation: 'Principal Architect, Hajizadeh & Associates, Iran'
    },
    content: `I had a delighting experience with UNI competitions and how they created a unique experience for a web based evaluation
        on an exciting project. I'd be happy to have more work relations with each other in the near future, be it as juror or a curator.`
  }
];

export const IMAGES = {
  FRAME: {
    bottomLeft:
      'https://uni-common-assets.s3.us-east-1.amazonaws.com/juror-page/featured/bottomleft.jpg',
    bottomRight:
      'https://uni-common-assets.s3.us-east-1.amazonaws.com/juror-page/featured/bottomright.jpg',
    center: 'https://uni-common-assets.s3.us-east-1.amazonaws.com/juror-page/featured/center.jpg',
    topLeft: 'https://uni-common-assets.s3.us-east-1.amazonaws.com/juror-page/featured/topleft.jpg',
    topRight:
      'https://uni-common-assets.s3.us-east-1.amazonaws.com/juror-page/featured/topright.jpg'
  },

  PARTNERS: [
    'https://uni-common-assets.s3.amazonaws.com/juror-page/partner/partner1.svg',
    'https://uni-common-assets.s3.amazonaws.com/juror-page/partner/partner2.svg',
    'https://uni-common-assets.s3.amazonaws.com/juror-page/partner/partner3.svg',
    'https://uni-common-assets.s3.amazonaws.com/juror-page/partner/partner4.svg',
    'https://uni-common-assets.s3.amazonaws.com/juror-page/partner/partner5.svg',
    'https://uni-common-assets.s3.amazonaws.com/juror-page/partner/partner6.svg',
    'https://uni-common-assets.s3.amazonaws.com/juror-page/partner/partner7.svg',
    'https://uni-common-assets.s3.amazonaws.com/juror-page/partner/partner8.svg',
    'https://uni-common-assets.s3.amazonaws.com/juror-page/partner/partner9.svg',
    'https://uni-common-assets.s3.amazonaws.com/juror-page/partner/partner10.svg',
    'https://uni-common-assets.s3.amazonaws.com/juror-page/partner/partner11.svg',
    'https://uni-common-assets.s3.amazonaws.com/juror-page/partner/partner12.svg',
    'https://uni-common-assets.s3.amazonaws.com/juror-page/partner/partner13.svg',
    'https://uni-common-assets.s3.amazonaws.com/juror-page/partner/partner14.svg'
  ]
};
