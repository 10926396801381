<!-- <div class="package-section width-100" fxLayout="column" fxLayoutAlign="start center">
    <div class="text-center">
        div
        
      <h2 class="margin-0">UNI Membership</h2>
      <p class="sparro-teal-700 font-sm font-bold">
        *50% of the membership value will be contributed to the prize pool
      </p>
    </div>

    <div class="plan-cards width-100" fxLayout="row" fxLayoutAlign="center center">
        <div class="plan-cards-inner-container" #planCards (scroll)="planCardsScroll()">
            <ng-container *ngFor="let plan of uniMemberhsipPlans">
                <div class="card-small border-radius-3 overflow-hidden padding-x-3 padding-y-7 sparro-bg-white"
                    fxLayout="column" fxLayoutAlign="center center">
                    <p class="margin-top-0 margin-bottom-2 card-title font-bolder sparro-grey-900">
                        {{ plan.title }}
                    </p>
                    <p class="margin-top-0 sparro-teal-700 font-xxs">{{ plan.subTitle }}</p>
                    <h4 class="sparro-teal-700 margin-bottom-2">
                        <span class="price font-bolder">${{ plan.price }}</span>/mo
                    </h4>
                    <p *ngIf="plan.price !== '0'" class="margin-top-0 sparro-teal-700 font-xxs">
                        billed annually
                    </p>
                    <a [href]="plan.url" target="_blank" class="no-decor">
                        <uni-navigation-button [default]="plan.buttonTitle" button_class="secondary" customClass="buy-btn">
                        </uni-navigation-button>
                    </a>
                    <ul class="margin-0 padding-0">
                        <ng-container *ngFor="let data of plan.data; let i = index">
                            <mat-divider *ngIf="i != 0"></mat-divider>
                            <li class="margin-4 text-left sparro-grey-800 bor" [innerHtml]="data | safeContent"></li>
                        </ng-container>
                    </ul>
                </div>
            </ng-container>
        </div>
    </div>
</div> -->

<div class="package-section width-100" fxLayout="column" fxLayoutAlign="start center">
  <div class="text-center">
    <h2 class="margin-0 title sparro-grey-900">UNI Membership</h2>
    <p class="sparro-teal-700 font-sm font-bold">
      *50% of the membership value will be contributed to the prize pool
    </p>
  </div>
  <div class="plan-cards width-100" fxLayout="row" fxLayoutAlign="center center">
    <div class="plan-cards-inner-container" #planCards (scroll)="planCardsScroll()">
      <ng-container *ngFor="let plan of uniMemberhsipPlans">
        <div
          class="plan-card rounded-border"
          [class.move-right]="moveRight"
          [class.move-left]="moveLeft">
          <div>
            <p class="margin-top-0 margin-bottom-2 card-title font-bolder sparro-grey-900">
              {{ plan.title }}
            </p>
            <p class="margin-top-0 sparro-teal-700 font-xxs">{{ plan.subTitle }}</p>
          </div>

          <div>
            <h4 class="sparro-teal-700 margin-bottom-2">
              <span class="price font-bolder">${{ plan.price }}</span>
            </h4>
            <p *ngIf="plan.price !== '0'" class="margin-top-0 sparro-teal-700 font-xxs">
              billed once
            </p>
          </div>
          <a [href]="plan.url" target="_blank" class="no-decor">
            <uni-navigation-button
              [default]="plan.buttonTitle"
              button_class="secondary"
              customClass="mem-buy-btn">
            </uni-navigation-button>
          </a>
          <ul class="margin-0 padding-0">
            <ng-container *ngFor="let data of plan.data; let i = index">
              <mat-divider *ngIf="i != 0"></mat-divider>
              <li
                class="margin-4 text-left sparro-grey-800 bor"
                [innerHtml]="data | safeContent"></li>
            </ng-container>
          </ul>
        </div>
      </ng-container>
    </div>
  </div>
</div>
