import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, HostListener, Input, OnInit } from '@angular/core';
import { BREAKPOINTS } from '@sparro/app/core/config/constants.config';
import { CompetitionService } from '@sparro/app/core/api/competition/competition.service';
import { Competition } from '@sparro/app/core/models/competition.model';
import { Discipline } from '@sparro/app/core/models/supertag.model';
import _ from 'lodash';

@Component({
  selector: 'uni-competitions-card-carousel',
  templateUrl: './competitions-card-carousel.component.html',
  styleUrls: ['./competitions-card-carousel.component.scss']
})
export class CompetitionsCardCarouselComponent implements OnInit {
  @Input() competitions: Competition[];
  selectedDisciplineIndex: number;
  filteredCompetitions: Competition[] = [];
  allFilteredCompetitions: Competition[] = [];
  screenBelowLarge: boolean = false;
  mobileView: boolean = false;
  moveRight: boolean = false;
  moveLeft: boolean = false;
  competitionsDisplayed: Competition[] = [];
  isLoadingDisciplines: boolean;
  disciplines: Discipline[];
  activeDiscipline: string;
  isLoading: boolean = false;

  constructor(
    private competitionService: CompetitionService,
    public breakpointObserver: BreakpointObserver
  ) {}

  ngOnInit(): void {
    if (this.competitions) {
      this.filteredCompetitions = this.competitionsDisplayed = this.allFilteredCompetitions = this.competitions.slice(0,5);
    } else {
      this.getDisciplines();
    }
    this.initializeBreakpoints();
  }

  initializeBreakpoints(viaHostListner: boolean = false): void {
    this.breakpointObserver
      .observe([BREAKPOINTS.below.lg, BREAKPOINTS.below.md])
      .subscribe(result => {
        if (viaHostListner && this.screenBelowLarge != result.breakpoints[BREAKPOINTS.below.lg]) {
          this.screenBelowLarge = result.breakpoints[BREAKPOINTS.below.lg];
          if (this.screenBelowLarge) {
            this.filteredCompetitions = this.competitionsDisplayed =
              this.allFilteredCompetitions.slice(0, 3);
          } else {
            this.filteredCompetitions = this.competitionsDisplayed =
              this.allFilteredCompetitions.slice(0, 5);
          }
        } else {
          this.screenBelowLarge = Boolean(result.breakpoints[BREAKPOINTS.below.lg]);
          this.mobileView = Boolean(result.breakpoints[BREAKPOINTS.below.md]);
        }
      });
  }

  getDisciplines(): void {
    this.isLoadingDisciplines = true;
    this.competitionService.getFilteredDisciplines().subscribe(
      (response: Discipline[]) => {
        response = _.orderBy(response, 'name', 'asc');
        this.disciplines = response.splice(0, 6);
        this.isLoadingDisciplines = false;
        this.getFilteredCompetitions(this.disciplines[0], 0);
      },
      err => {
        console.log(err);
      }
    );
  }

  getFilteredCompetitions(discipline: Discipline, index: number = 0): void {
    if (this.selectedDisciplineIndex != index) {
      this.isLoading = true;
      this.selectedDisciplineIndex = index;
      this.activeDiscipline = discipline.name;
      this.competitionService.getFilteredResults((discipline.id = discipline.id)).subscribe(
        (response: any) => {
          this.allFilteredCompetitions = response.results;
          if (this.screenBelowLarge && !this.mobileView) {
            this.filteredCompetitions = this.competitionsDisplayed = response.results.slice(0, 3);
          } else {
            this.filteredCompetitions = this.competitionsDisplayed = response.results.slice(0, 5);
          }
          this.isLoading = false;
        },
        error => {
          this.isLoading = false;
        }
      );
    }
  }

  @HostListener('window:resize', [])
  getNumberOfCompetitions(): void {
    this.initializeBreakpoints(true);
  }

  moveCards(direction: string): void {
    if (direction === 'right') {
      this.moveRight = true;
      this.moveLeft = false;
      setTimeout(() => {
        this.stopMoving();
        this.arrayRotate(this.filteredCompetitions, true);
      }, 400);
    } else if (direction === 'left') {
      this.moveRight = false;
      this.moveLeft = true;
      setTimeout(() => {
        this.stopMoving();
        this.arrayRotate(this.filteredCompetitions, false);
      }, 400);
    }
  }

  stopMoving(): void {
    this.moveLeft = false;
    this.moveRight = false;
  }

  arrayRotate(arr, rotateRight: boolean = false): void {
    if (rotateRight) {
      arr.unshift(arr.pop());
    } else {
      arr.push(arr.shift());
    }
    this.competitionsDisplayed = JSON.parse(JSON.stringify(arr));
  }
}
