<div class="main-container">
  <div class="cover-wrapper">
    <div class="cover-container" fxLayout="column">
      <app-landing-page-header [smallView]="smallView" [user]="user"> </app-landing-page-header>
      <div class="cover-content">
        <div class="text-section" fxLayout="column">
          <h1 class="title margin-0">Esteemed jury pool of global experts</h1>
          <p class="subtitle margin-0">
            UNI collaborates with a vast range of international professionals and educators. We
            welcome you to contribute to the community with your experience!
          </p>
          <a [href]="getJurorUrl()" mat-button class="button-section button-orange-1 no-decor">
            <div [fxLayoutGap]="smallView ? '8px' : '16px'">Become a juror</div>
          </a>
        </div>
        <div fxflex="50">
          <div class="img-section">
            <div class="frame top" [style.backgroundImage]="'url(' + imgFrame.topLeft + ')'"></div>
            <div
              class="frame right"
              [style.backgroundImage]="'url(' + imgFrame.topRight + ')'"></div>
            <div
              class="frame bottom"
              [style.backgroundImage]="'url(' + imgFrame.bottomRight + ')'"></div>
            <div
              class="frame left"
              [style.backgroundImage]="'url(' + imgFrame.bottomLeft + ')'"></div>
            <div
              class="frame center"
              [style.backgroundImage]="'url(' + imgFrame.center + ')'"></div>
          </div>
        </div>
      </div>
      <div class="fact-section" fxLayout="row">
        <div class="facts">
          <h4 class="margin-0 fact-title">1000+</h4>
          <p class="fact-subtitle font-sm margin-bottom-0">
            Jurors from top institutions and firms from all over the world.
          </p>
        </div>
        <div class="vl margin-auto"></div>
        <div class="facts">
          <h4 class="margin-0 fact-title">70+</h4>
          <p class="fact-subtitle font-sm margin-bottom-0">Countries where our jurors come from.</p>
        </div>
        <div class="vl margin-auto"></div>
        <div class="facts">
          <h4 class="margin-0 fact-title">50+</h4>
          <p class="fact-subtitle font-sm margin-bottom-0">
            Disciplines of the jury pool's fields of expertise.
          </p>
        </div>
      </div>
    </div>
  </div>
  <div class="testimonial-section">
    <ng-container *ngFor="let testimony of testimonies; let i = index">
      <div fxLayout="row" class="inner-container" [class.hidden]="i != testimonyVisibleIndex">
        <uni-image
          [src]="testimony.user.avatar_url"
          [alt]="testimony.user.name + ', ' + testimony.user.designation"
          [width]="360"
          [height]="360"
          auxClassList="testimonial-image">
        </uni-image>
        <div fxLayout="column" class="content-section">
          <p class="content margin-0">{{ testimony.content }}</p>
          <div fxLayout="column" class="user-detail-section">
            <h4 class="username margin-0">{{ testimony.user.name }}</h4>
            <p class="user-detail margin-0">{{ testimony.user.designation }}</p>
          </div>
        </div>
        <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="24px" class="dot-container">
          <ul class="dots">
            <ng-container *ngFor="let testimony of testimonies; let i = index">
              <li
                class="active"
                [ngClass]="{ active: i === testimonyVisibleIndex }"
                (click)="clickedTestimonySlide(i + 1)"></li>
            </ng-container>
          </ul>
        </div>
      </div>
    </ng-container>
  </div>
  <div class="juror-section" *ngIf="jurors" fxLayout="column" fxLayoutAlign="center center">
    <div class="text-section" fxLayout="column" fxLayoutAlign="space-between center">
      <h2 class="title margin-0">Guest jurors</h2>
      <p class="subtitle font-bold margin-0">
        UNI proudly presents our jury members from top institutions and design firms across the
        globe
      </p>
    </div>
    <div class="partner-section margin-y-20 margin-x-auto">
      <ng-container *ngFor="let partner of partnerFeatures; let i = index">
        <div class="partner">
          <uni-image
            class="img"
            [src]="partner"
            [alt]="'featureImage' + i"
            [width]="130"
            [height]="72"
            auxClassList="partner-img">
          </uni-image>
        </div>
      </ng-container>
    </div>

    <uni-juror-cards-group [jurors]="jurors"> </uni-juror-cards-group>

    <div fxLayout="row" fxLayoutAlign="center center" class="padding-y-10">
      <uni-loader *ngIf="isLoading"></uni-loader>
    </div>
  </div>
  <div class="banner-section">
    <div class="text-section margin-auto" fxLayout="column" fxLayoutAlign="center center">
      <h3 class="title">Join our community of 2000+ experts</h3>
      <a
        [href]="getJurorUrl()"
        mat-button
        class="button-section margin-top-4 button-orange-1 no-decor">
        <div [fxLayoutGap]="smallView ? '8px' : '16px'">Become a juror</div>
      </a>
    </div>
  </div>
</div>
<uni-public-footer [topMargin]="false"></uni-public-footer>
