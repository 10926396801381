import { CompetitionService } from '@about/app/core/api/competition/competition.service';
import { IMAGES, TESTIMONIES } from '@about/app/core/configs/jurors.config';
import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, OnInit, HostListener } from '@angular/core';
import { UserService } from '@sparro/app/core/api/user/user.service';
import { BREAKPOINTS } from '@sparro/app/core/config/constants.config';
import { ImageOptimizerService } from '@sparro/app/core/helpers/image-optimizer';
import { Juror, User } from '@sparro/app/core/models/user.model';
import { environment } from '@about/env/environment';
import { MetaDefinition } from '@angular/platform-browser';
import { MetaService } from '@sparro/app/core/helpers/meta/meta.service';

export class Reviewer {
  name: string;
  avatar_url: string;
  designation: string;
}

export class Testimony {
  user: Reviewer;
  content: string;
}

@Component({
  selector: 'app-people',
  templateUrl: './people.component.html',
  styleUrls: ['./people.component.scss']
})
export class PeopleComponent implements OnInit {
  user: User;
  smallView: boolean = false;
  mobileView: boolean = false;
  optimizedCoverImage: string;
  contributionForm: boolean = false;
  competitionLaunchForm: boolean = false;
  jurors: Juror[] = [];
  testimonies: Testimony[] = TESTIMONIES;
  testimonyVisibleIndex: number = 0;
  carouselVisibleIndex: number = 0;
  pageSize: number = 32;
  showCarousel: boolean = false;
  next: boolean = false;
  pageNumber: number = 1;
  imgFrame = IMAGES.FRAME;
  partnerFeatures: string[] = IMAGES.PARTNERS;
  isLoading: boolean = true;

  constructor(
    private userService: UserService,
    public breakpointObserver: BreakpointObserver,
    private imageOptimization: ImageOptimizerService,
    private competitionService: CompetitionService,
    private metaService: MetaService
  ) {}

  ngOnInit(): void {
    this.getAuthUser();
    this.updateMetaInfo();
    this.initializeBreakpoints();
    this.getJurors();
    this.autoPlayTestimonies();
    this.testimonies.forEach(testimony => {
      testimony.user.avatar_url = this.renderImage(testimony.user.avatar_url, 400, 400);
    });
  }

  getAuthUser(): void {
    this.userService.userInitialized.subscribe((initialized: boolean) => {
      if (initialized) {
        this.userService.user.subscribe((user: User) => {
          this.user = user;
        });
      }
    });
  }

  initializeBreakpoints(): void {
    this.breakpointObserver
      .observe([BREAKPOINTS.below.xl, BREAKPOINTS.below.md, BREAKPOINTS.below.sm])
      .subscribe(result => {
        if (
          (result.breakpoints[BREAKPOINTS.below.xl] &&
            !result.breakpoints[BREAKPOINTS.below.sm]) !== this.showCarousel
        ) {
          this.showCarousel = !this.showCarousel;
        }
        this.smallView = Boolean(result.breakpoints[BREAKPOINTS.below.md]);
        this.mobileView = Boolean(result.breakpoints[BREAKPOINTS.below.sm]);
      });
  }

  optimizedFrameImage(image: string): string {
    return this.renderImage(image, 200, 200);
  }

  renderImage(image, width, height): string {
    return this.imageOptimization.renderImage(image, width, height);
  }

  getCompetitionFeedUrl(): string {
    return;
  }

  getJurorUrl(): string {
    return `${environment.aboutUrl}about/experts/juror`;
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(): void {
    const currentPos = document.documentElement.scrollTop || document.body.scrollTop;
    const maxPos = document.documentElement.scrollHeight;
    if (currentPos >= maxPos / 2 && this.next && !this.isLoading) {
      this.getJurors();
    }
  }

  getJurors(): void {
    this.isLoading = true;
    this.competitionService.getJurors(this.pageNumber, this.pageSize).subscribe(
      (response: any) => {
        this.isLoading = false;
        this.jurors = [...this.jurors, ...response.results];
        if (response.next) {
          this.next = true;
          this.pageNumber += 1;
        } else {
          this.next = false;
        }
      },
      error => {
        console.log(error);
        this.isLoading = false;
      }
    );
  }

  autoPlayTestimonies(): void {
    if (window.innerWidth > 960) {
      setTimeout(() => {
        this.nextTestimonySlide();
        this.autoPlayTestimonies();
      }, 8000);
    }
  }

  previousTestimonySlide(): void {
    this.testimonyVisibleIndex--;
    this.testimonyVisibleIndex =
      this.testimonyVisibleIndex < 0 ? this.testimonies.length - 1 : this.testimonyVisibleIndex;
  }

  clickedTestimonySlide(index: number): void {
    this.testimonyVisibleIndex = index - 1;
  }

  nextTestimonySlide(): void {
    this.testimonyVisibleIndex++;
    this.testimonyVisibleIndex %= this.testimonies.length;
  }

  updateMetaInfo(): void {
    const title = 'Esteemed jury pool of global experts on UNI';
    const description = `UNI collaborates with a vast range of international professionals and educators. 
      We welcome you to contribute to the community with your experience! The Esteemed jury pool of global 
      experts is a group of highly qualified individuals who have been selected to provide their expert 
      opinions on a variety of topics.`;
    const cover = `https://uni-common-assets.s3.us-east-1.amazonaws.com/experts/juror/juror-cover.png`;
    const metaInfo: MetaDefinition[] = [
      { name: 'title', content: title },
      { name: 'description', content: description },
      { name: 'url', content: window.location.href },
      { name: 'image', content: cover },
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:title', content: title },
      { name: 'twitter:description', content: description },
      { name: 'twitter:url', content: window.location.href },
      { name: 'twitter:image', content: cover },
      { property: 'og:title', content: title },
      { property: 'og:description', content: description },
      { property: 'og:url', content: window.location.href },
      { property: 'og:image', content: cover },
      { property: 'og:image:alt', content: 'UNI- Design competitions' },
      { property: 'og:type', content: 'article' }
    ];
    metaInfo.forEach(meta => {
      this.metaService.updateMetaInfo(meta);
    });
    this.metaService.updateMeta(metaInfo);
    this.metaService.updateTitle(title);
    this.metaService.createCanonicalURL();
  }
}
