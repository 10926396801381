<div class="cover-wrapper">
  <div class="cover-container" fxLayout="column">
    <app-landing-page-header [smallView]="smallView" [user]="user"> </app-landing-page-header>
    <div class="content-container" fxLayout="column" fxLayoutAlign="space-between">
      <div class="main-content">
        <div class="text-section" fxLayout="column">
          <div class="title-section">
            <h1 class="title margin-0">{{ data.pageTitle }}</h1>
          </div>
          <p class="subtitle margin-0">{{ data.pageSubtitle }}</p>
          <ng-container *ngIf="host == 'list-competition'">
            <a
              class="no-decor"
              style="width: fit-content"
              href="mailto:contact@uni.xyz?subject=List%20%26%20Promote%20our%20competition%20on%20UNI.&body=Hello%2C%0A%0AWe%20would%20like%20to%20list%20our%20competition%20on%20UNI.%20Here%20are%20the%20competition%20details.%0A%0AName%20of%20the%20competition%3A%20%0ACompetition%20Description%3A%0ACompetition%20Location%3A%20%0AAttach%20competition%20cover%20image%20(2000%20x%201000%20px)%3A%20Yes%2FNo%0AAttach%20Organization%20logo%20(500%20x%20500%20px)%3A%20(Yes%2FNo)%0AAttach%20Competition%20Brief%3A%20(Yes%2FNo)%0ACompetition%20Deadlines%3A%0AAward%20Details%3A%20%0AEntry%20Fee%20Details%3A%0AJuror%20of%20the%20competition%3A%0APromotional%20Plan%3A%20(Weekly%2FMonthly%2FPerennial)">
              <uni-navigation-button
                class="btn-border"
                default="List a competition"
                button_class="secondary"
                customClass="list-button">
              </uni-navigation-button>
            </a>
          </ng-container>
        </div>
        <div class="img-section">
          <div class="frame center">
            <uni-image
              [src]="data.coverImage"
              [alt]="'competition detail'"
              [width]="493"
              [height]="326"
              classes="width-100 height-100 fit-cover">
            </uni-image>
          </div>
          <ng-container *ngIf="competitions.length">
            <uni-competition-card class="left frame" [competition]="competitions[0]">
            </uni-competition-card>
          </ng-container>
          <ng-container *ngIf="competitions.length && competitions.length > 1">
            <uni-competition-card class="right frame" [competition]="competitions[1]">
            </uni-competition-card>
          </ng-container>
        </div>
      </div>
      <div class="sub-topic-cover-content">
        <div class="wrapper" fxLayout="column">
          <div class="text-section width-100 margin-auto" fxLayout="column">
            <div class="title-section">
              <h2 class="title margin-0">{{ data.title }}</h2>
            </div>
            <p class="subtitle margin-0" *ngIf="data?.subtitle">{{ data.subtitle }}</p>
          </div>
          <div class="width-100" fxLayout="row" fxLayoutAlign="center center">
            <div class="card-container">
              <ng-container *ngFor="let card of platformFeatures; let i = index">
                <div class="platform-feature-card" fxLayout="column" fxLayoutGap="40px">
                  <uni-image
                    [src]="card.icon"
                    [alt]="'card-icon-img' + i"
                    classes="big-dot"></uni-image>
                  <div
                    class="card-text-section"
                    fxLayout="column"
                    fxLayoutAlign="center"
                    fxLayoutGap="24px">
                    <h4 class="title margin-0">{{ card.title }}</h4>
                    <p class="description margin-0">{{ card.description }}</p>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="detail-section">
  <ng-container *ngFor="let detail of numericDetails">
    <div fxLayout="column" fxLayoutAlign="center" class="text-center text-section">
      <h4 class="title margin-0">{{ detail.title }}</h4>
      <p class="margin-0">{{ detail.description }}</p>
    </div>
  </ng-container>
</div>
