<div class="main-container">
  <app-page-top-section
    [data]="data.extraInfo"
    [smallView]="smallView"
    [platformFeatures]="platformFeatures"
    [competitions]="competitions"
    [host]="host">
  </app-page-top-section>
  <div class="how-it-works-section">
    <h2 class="margin-0 heading sparro-grey-900">Who should host Competitions on UNI?</h2>
    <div class="how-it-works-body">
      <ng-container *ngFor="let section of data.sections; let i = index">
        <div [class]="i % 2 == 0 ? 'even-section' : 'odd-section'">
          <ng-container *ngTemplateOutlet="textSection"></ng-container>
          <div class="image-section">
            <ng-container *ngIf="i == 0">
              <uni-image
                class="number-1 absolute"
                src="https://uni-user.s3.amazonaws.com/so/sorampurifilessorampuri2024-06-19T12-16-34-532609.png"
                alt="number 1"
                classes="fit-cover width-100 height-100">
              </uni-image>
              <a href="https://uni.xyz/competitions/terra-x-terra/info/about" target="_blank">
                <uni-image
                  class="detail-screen frame"
                  src="https://uni-user.s3.amazonaws.com/so/sorampurifilessorampuri2024-06-19T11-48-13-383046.jpg"
                  alt="Terra X Terra Competition Workshop"
                  classes="fit-cover width-100 height-100">
                </uni-image>
              </a>
              <a
                href="https://uni.xyz/projects/upcycled-pavilion-desc/the-pavilion-was"
                target="_blank">
                <uni-image
                  class="option-screen-1 frame"
                  src="https://uni-user.s3.amazonaws.com/un/uni.xyzfilesuni.xyz2024-06-19T14-27-44-945100.jpg"
                  alt="Upcycled Pavilion Project"
                  classes="fit-cover width-100 height-100">
                </uni-image>
              </a>
            </ng-container>
            <ng-container *ngIf="i == 1">
              <uni-image
                class="number-2 absolute"
                src="https://uni-user.s3.amazonaws.com/un/uni.xyzfilesuni.xyz2024-06-19T14-53-05-056185.png"
                alt="Product companies"
                classes="fit-cover width-100 height-100">
              </uni-image>
              <a
                href="https://uni.xyz/projects/olilly-desk-desc/the-desk-is-equipped-wit"
                target="_blank">
                <uni-image
                  class="option-screen-1 frame"
                  src="https://uni-user.s3.amazonaws.com/un/uni.xyzfilesuni.xyz2024-06-19T14-37-39-530108.jpg"
                  alt="Olly Desk Project"
                  classes="fit-cover width-100 height-100">
                </uni-image>
              </a>
              <a
                href="https://uni.xyz/competitions/hidesign-luxury-display-design/info/about"
                target="_blank">
                <uni-image
                  class="option-screen-2 frame"
                  src="https://uni-user.s3.amazonaws.com/un/uni.xyzfilesuni.xyz2024-06-19T14-32-14-595656.jpg"
                  alt="Hidesign luxury display design competition"
                  classes="fit-cover width-100 height-100">
                </uni-image>
              </a>
            </ng-container>
            <ng-container *ngIf="i == 2">
              <uni-image
                class="number-3 absolute"
                src="https://uni-user.s3.amazonaws.com/un/uni.xyzfilesuni.xyz2024-06-19T14-51-53-100858.png"
                alt="SaaS Companies"
                classes="fit-cover width-100 height-100">
              </uni-image>
              <a
                href="https://uni.xyz/competitions/beegraphy-design-awards/info/about"
                target="_blank">
                <uni-image
                  class="about-section-img frame"
                  src="https://uni-user.s3.amazonaws.com/un/uni.xyzfilesuni.xyz2024-06-19T14-03-28-216454.jpg"
                  alt="Beegraphy Design Awards"
                  classes="fit-cover width-100 height-100">
                </uni-image>
              </a>
              <a
                href="https://competitions.uni.xyz/competitions/new-dendities/info/about"
                target="_blank">
                <uni-image
                  class="category-section-img frame"
                  src="https://uni-user.s3.amazonaws.com/un/unisupport2filesunisupport22021-11-30T06-24-51-575807.JPG"
                  alt="Digital Blue Foam Competitions"
                  classes="fit-cover width-100 height-100">
                </uni-image>
              </a>
            </ng-container>
            <ng-container *ngIf="i == 3">
              <uni-image
                class="rocket-img absolute"
                src="https://uni-user.s3.amazonaws.com/so/sorampurifilessorampuri2024-06-19T15-04-37-448917.png"
                alt="Large organizations and Government"
                classes="fit-cover width-100 height-100">
              </uni-image>
            </ng-container>
          </div>
        </div>
        <ng-template #textSection>
          <div
            class="text-section"
            fxLayout="column"
            fxLayoutAlign="space-between start"
            fxLayoutGap="24px">
            <h3 class="title margin-0">{{ section.title }}</h3>
            <p class="description margin-0 sparro-grey-800">
              {{ section.subtitle }}
            </p>
            <div class="expansion-panel-container width-100">
              <ng-container *ngFor="let detail of section.panelData; let index = index">
                <mat-expansion-panel class="expansion-panel">
                  <mat-expansion-panel-header (click)="setActiveIndex(i, index)">
                    <mat-panel-title>
                      <h4
                        [ngStyle]="{
                          color: detail.expanded ? faqHeadingColor : '#232323',
                          'font-weight': detail.expanded ? '700' : '400'
                        }"
                        class="margin-0">
                        {{ detail.title }}
                      </h4>
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <p [innerHtml]="detail.description | safeContent" class="margin-bottom-0"></p>
                </mat-expansion-panel>
              </ng-container>
            </div>
          </div>
        </ng-template>
      </ng-container>
    </div>
  </div>
  <app-page-bottom-section
    [smallView]="smallView"
    [extraSmallView]="smallView"
    [packages]="packages"
    [footerData]="data.footer"
    [demoUrl]="data.demoUrl"
    [host]="host"
    [competitions]="competitions.slice(3, 8)">
  </app-page-bottom-section>
</div>
<uni-public-footer [topMargin]="false"></uni-public-footer>
