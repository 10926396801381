import { Component, OnInit } from '@angular/core';
import { MetaDefinition } from '@angular/platform-browser';
import { MetaService } from '@sparro/app/core/helpers/meta/meta.service';
import { environment } from '@about/env/environment';
import { BreakpointObserver } from '@angular/cdk/layout';
import { BREAKPOINTS } from '@sparro/app/core/config/constants.config';
import { ImageOptimizerService } from '@sparro/app/core/helpers/image-optimizer';
import { Competition } from '@sparro/app/core/models/competition.model';
import { CompetitionService } from '@about/app/core/api/competition/competition.service';
import { FEATURES } from '@about/app/core/configs/landing.config';

export class Features {
  title: string;
  description: string;
  icon?: string;
}

export class PackageOrPlan {
  title: string;
  price: number;
  unit: string;
  features: string[];
  buttonText: string;
  url: string;
}

@Component({
  selector: 'app-sponsor',
  templateUrl: './sponsor.component.html',
  styleUrls: ['./sponsor.component.scss']
})
export class SponsorComponent implements OnInit {
  platformFeatures: Features[] = [
    {
      title: 'Join hands with the creators of tomorrow',
      description: `By sponsoring a design challenge you get an opportunity to join hands with 
        brilliant and award-winning creative people who can work with your brand even 
        once the competition ends.`,
      icon: 'https://uni-common-assets.s3.amazonaws.com/businesses/sponsor/icon-1.svg'
    },
    {
      title: 'Increase brand awareness',
      description: `At UNI creative people thrive and become the creative professionals of tomorrow. 
        Engaging these people with your brand at an early stage will help you to grow your 
        brand exponentially.`,
      icon: 'https://uni-common-assets.s3.amazonaws.com/businesses/sponsor/icon-2.svg'
    },
    {
      title: 'Targeted marketing for lifetime',
      description: `Sponsoring a competition promotes your brand by focusing on the project and 
        encourages designers to create meaningful designs related to your organization.`,
      icon: 'https://uni-common-assets.s3.amazonaws.com/businesses/sponsor/icon-3.svg'
    },
    {
      title: 'Flexibility to choose',
      description: `UNI provides flexibility for you, by helping you to choose your sponsorship amount. 
        One can sponsor a single award to sponsor the whole competition monetarily or in other 
        form of valuable sponsorships.`,
      icon: 'https://uni-common-assets.s3.amazonaws.com/businesses/sponsor/icon-4.svg'
    }
  ];

  packages: PackageOrPlan[] = [
    {
      title: 'Bronze',
      price: 150,
      unit: '16 per competition',
      features: [
        'Featured on competition partner’s page',
        'Visibility on notes to jurors',
        'Stack the amount for sponsoring multiple awards',
        '<b>Sponsored by</b> icon on the winning project (Respective to the award you want to sponsor)',
        'Basic analytics at the end of the campaign'
      ],
      buttonText: 'Select Bronze',
      url: `https://uni.xyz/payment/products/847f811b-1401-443d-ab22-f99ecc920cd9`
    },
    {
      title: 'Gold',
      price: 350,
      unit: '6 per competition',
      features: [
        'All in Bronze sponsorship plan plus',
        'Featured in <b>2 Weekly newsletters </b>',
        'Featured in competition article or PR articles on UNI blog page ',
        'Logo in promotional videos & social media contents'
      ],
      buttonText: 'Select Gold',
      url: `https://uni.xyz/payment/products/2947ea9a-2e54-4088-a88b-fa9b05cf49b7`
    },
    {
      title: 'Title',
      price: 1500,
      unit: '1 per competition',
      features: [
        'All in Gold sponsorship plan plus',
        'Dedicated Sponsor page within the competition.',
        'Large logo on competition cover page.',
        'Opportunity to be a part of the judging panel.',
        'Detailed analytics at the end of the campaign'
      ],
      buttonText: 'Select Title',
      url: `https://uni.xyz/payment/products/2ae73a37-b0fc-450f-9513-eb4d752912b5`
    }
  ];

  data = {
    extraInfo: {
      pageTitle: `Promote your brand`,
      pageSubtitle: `Find meaningful and targeted audience for your product & services through competition sponsorship.`,
      coverImage: `https://uni-common-assets.s3.amazonaws.com/businesses/sponsor/coverImage.svg`,
      title: `Why promote your brand on UNI?`
    },
    sections: [
      {
        title: `Choose an active competition hosted on UNI`,
        subtitle: `Visit the UNI Competitions page to choose a competition from a variety of briefs. 
          Check the brief, awards, jury, and participation criteria before making a choice.`,
        panelData: [
          {
            title: 'How long does a competition remains active?',
            description: `Usually, a competition’s remain active for registration, for a period of 
              5 months and a total of 7 months including evaluation and public voting period. However, 
              the competition page would remain active for lifetime so your sponsorship never expires.`,
            expanded: false
          },
          {
            title: 'What to do if I don’t find a competition of our choice?',
            description: `We must say that you have a UNIque choice. But don’t worry, get in 
              touch with us and we will launch a competition based on your need.`,
            expanded: false
          },
          {
            title: `Wow! That’s a lot of competitions. Can you help us choose the right one?`,
            description: `Yes of course! Just book a demo at the end of the page and one of our representative will 
              get in touch with you to understand your need better and help you to choose the right competition to sponsor`,
            expanded: false
          }
        ]
      },
      {
        title: `Choose awards to sponsor`,
        subtitle: `Choose the competition and award type you would like to sponsor. You can choose single/multiple 
          awards to sponsor or the whole design competition to sponsor. Sponsoring the whole design competition 
          entitles you for the Title sponsor and at the same time makes the entire competition free for all the participants.`,
        panelData: [
          {
            title: `Can I sponsor the award partially?`,
            description: `Yes we accept partial sponsorship as well. However this will be limited to a minimum 
              of 25% of the total award value. Please check the sponsorship plan for details.`,
            expanded: false
          },
          {
            title: `Can I sponsor the award in kind instead of cash prize?`,
            description: `Yes you may sponsor the award in kind as well, however a minimum of 25% of the total 
              award value need to be sponsored in cash. e.g. if the award value is 600$, you need to sponsor 
              a minimum of 150$ in cash.`,
            expanded: false
          }
        ]
      },
      {
        title: `Set your budget and sponsor the competition`,
        subtitle: `Click on the sponsor button, to begin with, a minimum sponsorship amount of 
          150 USD for Honorable mention/People’s choice awards.`,
        panelData: [
          {
            title: `Can I sponsor multiple competitions at a time?`,
            description: `Yes you can sponsor multiple competitions in different/same categories at any time.`,
            expanded: false
          },
          {
            title: `Can I purchase multiple sponsorship packages?`,
            description: `Yes you may purchase multiple sponsorship package. Please do mention the name of 
              the competition you wish to sponsor while making the payments.`,
            expanded: false
          }
        ]
      }
    ],
    footer: {
      title: `Don’t have a competition to sponsor?`,
      links: [
        {
          url: `${environment.aboutUrl}about/business/list`,
          text: `List a competition`
        },
        {
          url: `${environment.aboutUrl}about/business/host`,
          text: `Host a competition`
        }
      ]
    },
    demoUrl: `https://tidycal.com/unixyz/sponsor-a-competition`
  };

  faqHeadingColor: string = '#192999';
  competitions: Competition[] = [];
  smallView: boolean = false;
  mobileView: boolean = false;
  extraSmallView: boolean = false;
  imgFrame = FEATURES.BUSINESS_PAGE;
  host: string = 'sponsor-competition';
  ongoingCompetitions: Competition[];

  constructor(
    public breakpointObserver: BreakpointObserver,
    private imageOptimization: ImageOptimizerService,
    private competitionService: CompetitionService,
    public metaService: MetaService
  ) {}

  ngOnInit(): void {
    this.updateMetaInfo();
    this.initializeBreakpoints();
    this.getOngoingCompetitions();
    this.getFeaturedCompetitions();
  }

  getOngoingCompetitions() {
    this.competitionService.getSponsoredCompetitions().subscribe((response: any) => {
      this.ongoingCompetitions = response.results;
    });
  }

  setActiveIndex(index1: number, index2: number) {
    this.data.sections[index1].panelData[index2].expanded = this.data.sections[index1].panelData[
      index2
    ].expanded
      ? false
      : true;
  }

  getFeaturedCompetitions(): void {
    this.competitionService.getPromotedCompetitions().subscribe((response: any) => {
      this.competitions = response;
    });
  }

  initializeBreakpoints(): void {
    this.breakpointObserver
      .observe([BREAKPOINTS.below.lg, BREAKPOINTS.below.md, BREAKPOINTS.below.sm])
      .subscribe(result => {
        this.smallView = Boolean(result.breakpoints[BREAKPOINTS.below.lg]);
        this.mobileView = Boolean(result.breakpoints[BREAKPOINTS.below.md]);
        this.extraSmallView = Boolean(result.breakpoints[BREAKPOINTS.below.sm]);
      });
  }

  optimizedFrameImage(image: string): string {
    return this.renderImage(image, 493, 326);
  }

  renderImage(image, width?, height?): string {
    return this.imageOptimization.renderImage(image, width, height);
  }

  updateMetaInfo(): void {
    const title = 'Sponsor a competition and promote your brand on UNI';
    const description = `UNI is the perfect platform to launch your Architecture & Design competitions. 
      With our easy-to-use tools, you can publish and host your competition with ease. We also provide 
      support and resources to help you get the most out of your competition. So what are you waiting 
      for? Launch your competition today!`;
    const cover = `https://uni-common-assets.s3.amazonaws.com/businesses/Cover.svg`;
    const metaInfo: MetaDefinition[] = [
      { name: 'title', content: title },
      { name: 'description', content: description },
      { name: 'url', content: window.location.href },
      { name: 'image', content: cover },
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:title', content: title },
      { name: 'twitter:description', content: description },
      { name: 'twitter:url', content: window.location.href },
      { name: 'twitter:image', content: cover },
      { property: 'og:title', content: title },
      { property: 'og:description', content: description },
      { property: 'og:url', content: window.location.href },
      { property: 'og:image', content: cover },
      { property: 'og:image:alt', content: 'UNI- Design competitions' },
      { property: 'og:type', content: 'article' }
    ];
    metaInfo.forEach(meta => {
      this.metaService.updateMetaInfo(meta);
    });
    this.metaService.updateMeta(metaInfo);
    this.metaService.updateTitle(title);
    this.metaService.createCanonicalURL();
  }
}
