import { CompetitionService } from '@about/core/api/competition/competition.service';
import { Component, Input, OnInit } from '@angular/core';
import { Juror } from '@sparro/app/core/models/user.model';

@Component({
  selector: 'uni-juror-cards-group',
  templateUrl: './juror-cards-group.component.html',
  styleUrls: ['./juror-cards-group.component.scss']
})
export class JurorCardsGroupComponent implements OnInit {

  @Input() jurors: Juror[];
  constructor(private competitionService: CompetitionService) { }

  ngOnInit() {
  }
}
