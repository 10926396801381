import { Injectable } from '@angular/core';
import { environment } from '@sparro/env/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CompetitionService {
  private baseUrl = `api/v2/`;

  constructor(private http: HttpClient) {
    this.baseUrl = `${environment.serverUrl}${this.baseUrl}`;
  }

  createCompetition(postData) {
    return this.http.post(this.baseUrl + 'competition_studio/', postData)
  }

  getFilteredDisciplines() {
    return this.http.get(this.baseUrl + 'competitions/disciplines/');
  }

  getFeaturedCompetitions() {
    return this.http.get(this.baseUrl + 'competitions/featured/');
  }

  getFilteredResults(discipline_ids: number) {
    return this.http.get(
      this.baseUrl +
      'competitions/' +
      '?disciplines=' +
      discipline_ids
    );
  }
}
