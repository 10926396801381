<div class="package-section width-100" fxLayout="column" fxLayoutAlign="start center">
  <div class="text-center">
    <h2 class="margin-0 title sparro-grey-900">
      {{
        host == 'list-competition'
          ? 'Promote a competition on UNI'
          : host == 'host-competition'
          ? 'Host competition on UNI'
          : 'Promote your brand - Sponsor a competition'
      }}
    </h2>
    <ng-container *ngIf="host == 'sponsor-competition'">
      <p style="color: #192999" class="sparro-teal-700 font-sm font-bold">
        *50% of the sponsorship value will be contributed to the prize pool
      </p>
    </ng-container>
    <ng-container *ngIf="host == 'list-competition'">
      <p style="color: #192999" class="sparro-teal-700 font-sm font-bold">
        You can write a <a href="https://uni.xyz/journal/" style="color: inherit; text-decoration: underline;">journal</a> about your competition and publish it for free on UNI.
      </p>
    </ng-container>
  </div>
  <div class="plan-cards width-100" fxLayout="row" fxLayoutAlign="center center">
    <div class="plan-cards-inner-container" #planCards (scroll)="planCardsScroll()">
      <ng-container *ngFor="let plan of packages">
        <div
          class="plan-card rounded-border"
          [class.move-right]="moveRight"
          [class.move-left]="moveLeft">
          <ng-container *ngIf="plan?.popular">
            <div class="popular">Popular</div>
          </ng-container>
          <div
            [class]="
              'card-title ' +
              (plan.title == 'Bronze'
                ? 'brown-400'
                : plan.title == 'Gold'
                ? 'yellow-700'
                : plan.title == 'Title'
                ? 'orange-700'
                : 'sparro-grey-900')
            ">
            {{ plan.title }}
          </div>
          <div fxLayout="column">
            <div *ngIf="host == 'sponsor-competition'" class="unit">
              Starts from
              <h4 class="price margin-top-4">${{ plan.price }}</h4>
            </div>
            <h4 *ngIf="!(host == 'sponsor-competition')" class="price">${{ plan.price }}</h4>
            <div class="unit">{{ plan.unit }}</div>
          </div>
          <a [href]="plan.url" target="_blank" class="no-decor">
            <uni-navigation-button
              [default]="plan.buttonText"
              button_class="secondary"
              customClass="bus-buy-btn">
            </uni-navigation-button>
          </a>
          <div fxLayout="column">
            <ul class="margin-0 padding-0">
              <ng-container *ngFor="let feature of plan.features; let i = index">
                <mat-divider *ngIf="i != 0"></mat-divider>
                <li
                  class="margin-4 text-left sparro-grey-800"
                  [innerHtml]="feature | safeContent"></li>
              </ng-container>
            </ul>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>
