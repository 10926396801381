import { Component, OnInit, Input } from '@angular/core';
import { FAQ } from '@sparro/app/core/models/faq.model';
import { FAQService } from '@sparro/app/core/api/faq/faq.service';
@Component({
  selector: 'uni-frequent-questions',
  templateUrl: './frequent-questions.component.html',
  styleUrls: ['./frequent-questions.component.scss']
})
export class FrequentQuestionsComponent implements OnInit {
  @Input() FAQs: FAQ[];
  @Input() headingColor: string;
  @Input() headingText: string = '';
  expanded: number[] = [];

  constructor(public FAQService: FAQService) {}

  ngOnInit(): void {}

  setActiveIndex(index: number) {
    this.expanded.includes(index)
      ? this.expanded.splice(this.expanded.indexOf(index), 1)
      : this.expanded.push(index);
  }
}
