import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@about/env/environment';

@Injectable({
  providedIn: 'root'
})
export class AboutService {
  private baseUrl = `api/v2/`;

  constructor(private http: HttpClient) {
    this.baseUrl = `${environment.serverUrl}${this.baseUrl}`;
  }

  getBusinessPagesDetail(section: string) {
    return this.http.get(this.baseUrl + 'footer_links/?section=' + section);
  }

  getPoolDetails() {
    return this.http.get(this.baseUrl + 'bulletins/pool_details/');
  }
}
