import { FEATURES } from '@about/app/core/configs/landing.config';
import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, OnInit } from '@angular/core';
import { CompetitionService } from '@sparro/app/core/api/competition/competition.service';
import { FAQService } from '@sparro/app/core/api/faq/faq.service';
import { UserService } from '@sparro/app/core/api/user/user.service';
import { BREAKPOINTS } from '@sparro/app/core/config/constants.config';
import { Competition } from '@sparro/app/core/models/competition.model';
import { FAQ } from '@sparro/app/core/models/faq.model';
import { User } from '@sparro/app/core/models/user.model';
import { environment } from '@about/env/environment';
import { MetaDefinition } from '@angular/platform-browser';
import { MetaService } from '@sparro/app/core/helpers/meta/meta.service';

export class Features {
  title: string;
  description: string;
  icon: string;
}

@Component({
  selector: 'app-curator',
  templateUrl: './curator.component.html',
  styleUrls: ['./curator.component.scss']
})
export class CuratorComponent implements OnInit {
  user: User;
  formOpened: boolean = false;
  smallView: boolean = false;
  optimizedCoverImage: string;
  contributionForm: boolean = false;
  competitionLaunchForm: boolean = false;
  imgFrame = FEATURES.EXPERTS.curator;
  competitions: Competition[] = [];
  platformFeatures: Features[] = [
    {
      title: 'Identify the problem',
      description:
        'We encourage our curators to identify real life challenges which can be solved through design intervention',
      icon: 'https://uni-common-assets.s3.amazonaws.com/experts/curator/icon-1.svg'
    },
    {
      title: 'Describe a way forward',
      description:
        'Explain the problem in detail and provide all the necessary information for someone to work on the challenge',
      icon: 'https://uni-common-assets.s3.amazonaws.com/experts/curator/icon-2.svg'
    },
    {
      title: 'Inspire fellow designers',
      description:
        'Launch the brief on UNI and help us spread the word in your groups and communities.',
      icon: 'https://uni-common-assets.s3.amazonaws.com/experts/curator/icon-3.svg'
    }
  ];
  workingSteps: Features[] = [
    {
      title: 'Fill out the form',
      description:
        'Read carefully the criteria & requirements to be a curator on UNI and fill out the application form',
      icon: 'https://uni-common-assets.s3.amazonaws.com/experts/common/icon-form.svg'
    },
    {
      title: 'Get verified',
      description:
        'Our team members will check for your details and approve your profile upon successful verification',
      icon: 'https://uni-common-assets.s3.amazonaws.com/experts/common/icon-verified.svg'
    },
    {
      title: 'Start curating',
      description:
        'Our team will guide you through the whole process of curation so that you can kickstart your competition at earliest',
      icon: 'https://uni-common-assets.s3.amazonaws.com/experts/curator/icon-curator.svg'
    }
  ];
  jurorFormLink: string =
    'https://submissions.uni.xyz/unixyz/form/BecomeaJuroe/formperma/JTPbmQw8mgrpa5EJ1HE4b7vX1MMPV_YLGdPWtPdb6OE';
  isLoading: boolean = true;
  pageNumber: number = 1;
  pageSize: number = 8;
  next: boolean = false;
  faqHeadingColor: string = '#f15a24';
  frequentlyAskedQuestions: FAQ[] = [];

  constructor(
    private userService: UserService,
    public breakpointObserver: BreakpointObserver,
    private competitionService: CompetitionService,
    private faqService: FAQService,
    public metaService: MetaService
  ) {}

  ngOnInit() {
    this.updateMetaInfo();
    this.getAuthUser();
    this.initializeBreakpoints();
    this.getFaqs();
    this.getFeaturedCompetitions();
  }

  openForm() {
    this.formOpened = true;
  }

  close() {
    this.formOpened = false;
  }

  getFeaturedCompetitions(): void {
    this.competitionService.getFeaturedCompetitions().subscribe((response: any) => {
      this.competitions = response.results;
    });
  }

  getFaqs(): void {
    this.faqService.getFAQs('experts-curator').subscribe((response: FAQ[]) => {
      this.frequentlyAskedQuestions = response;
    });
  }

  getAuthUser(): void {
    this.userService.userInitialized.subscribe((initialized: boolean) => {
      if (initialized) {
        this.userService.user.subscribe((user: User) => {
          this.user = user;
        });
      }
    });
  }

  initializeBreakpoints(): void {
    this.breakpointObserver
      .observe([BREAKPOINTS.below.lg, BREAKPOINTS.below.md])
      .subscribe(result => {
        this.smallView = Boolean(result.breakpoints[BREAKPOINTS.below.lg]);
      });
  }

  getJurorPageUrl(): string {
    return `${environment.aboutUrl}about/experts/juror`;
  }

  getMentorPageUrl(): string {
    return `${environment.aboutUrl}about/experts/mentor`;
  }

  getFormUrl(): string {
    return `${environment.loginUrl}authentication?service=sign-up&continue=${this.jurorFormLink}`;
  }

  updateMetaInfo(): void {
    const title = 'Curate your own Architecture & Design competition';
    const description = `Love solving problems and finding creative solutions? Why not put your skills to 
      curate your own Architecture & Design competition? See how creative designs stack up in the competition 
      and give some great feedback along the way. Learn more now.`;
    const cover = `https://uni-common-assets.s3.amazonaws.com/experts/curator/pdf-reader.svg`;
    const metaInfo: MetaDefinition[] = [
      { name: 'title', content: title },
      { name: 'description', content: description },
      { name: 'url', content: window.location.href },
      { name: 'image', content: cover },
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:title', content: title },
      { name: 'twitter:description', content: description },
      { name: 'twitter:url', content: window.location.href },
      { name: 'twitter:image', content: cover },
      { property: 'og:title', content: title },
      { property: 'og:description', content: description },
      { property: 'og:url', content: window.location.href },
      { property: 'og:image', content: cover },
      { property: 'og:image:alt', content: 'UNI- Design competitions' },
      { property: 'og:type', content: 'article' }
    ];
    metaInfo.forEach(meta => {
      this.metaService.updateMetaInfo(meta);
    });
    this.metaService.updateMeta(metaInfo);
    this.metaService.updateTitle(title);
    this.metaService.createCanonicalURL();
  }
}
