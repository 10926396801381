<div class="main-container">
  <div class="cover-wrapper relative">
    <div class="cover-container sparro-white">
      <app-landing-page-header [smallView]="smallView" [user]="user"> </app-landing-page-header>
      <div
        fxLayout="row"
        fxLayoutAlign="space-between center"
        class="title-section margin-x-8 margin-auto">
        <div fxLayout="column" class="title-wrapper">
          <h1 class="title">
            Feel <span class="free">‘free’</span> to explore unlimited content on UNI!
          </h1>
          <p class="sub-title font-bold font-lg-static margin-top-2">
            UNI membership gives you the freedom to participate in any competition hosted on UNI
            without the worry about registration fees. Your membership also opens the door to our
            premium content, including the insightful annual yearbook, all at no extra charge.
          </p>
        </div>
        <div class="cover-frame-wrapper relative">
          <uni-image
            classes="width-100 height-100"
            alt="membership-cover"
            src="https://uni-common-assets.s3.amazonaws.com/designers/membership/membership-cover.svg">
          </uni-image>
        </div>
      </div>
      <div fxLayout="row" fxLayoutAlign="center">
        <div
          class="stat-card-wrapper absolute border-radius-3 sparro-bg-white padding-4"
          fxLayout="row">
          <div *ngFor="let stat of statData; let i = index" fxLayout="row" class="stat-content">
            <div fxLayout="column" class="padding-x-3 stats-wrapper">
              <h4 class="sparro-teal-900 margin-0 font-bolder count">{{ statData[i].count }}</h4>
              <p class="margin-bottom-0 margin-top-4 sparro-grey-900">
                {{ statData[i].description }}
              </p>
            </div>
            <div
              *ngIf="i < 2"
              class="divider border-right border-grey-700 border-width-3 height-100"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div fxLayout="column" fxLayoutGap="252px" class="main-content-container">
    <div fxLayout="row" fxLayoutAlign="space-around" class="content-section-one margin-top-12">
      <div fxLayout="column" fxLayoutAlign="center start" class="content-wrapper margin-x-10">
        <h3 class="content-title font-bolder sparro-grey-900 margin-bottom-8 margin-top-0">
          Explore a World of Curated Challenges
        </h3>
        <p class="content-sub-title font-regular margin-0">
          As a UNI Member, get complimentary access to our curated competitions. Showcase your
          innovative designs and ideas on an international platform."
        </p>
      </div>
      <div class="circle1-bg relative margin-top-20 padding-top-10">
        <ng-container *ngFor="let competition of competitions.slice(0, 2); let i = index">
          <uni-competition-card
            [class]="'absolute frame competition-card-' + (i + 1)"
            [competition]="competition">
          </uni-competition-card>
        </ng-container>
      </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="space-around" class="content-section-two">
      <div class="circle2-bg relative margin-right-14">
        <uni-image
          [src]="imgFrame[2]"
          alt="yearbook-frame"
          classes="yearbook-frame absolute border-radius-3">
        </uni-image>
        <uni-image
          [src]="imgFrame[3]"
          alt="tablet-frame"
          classes="tablet-frame absolute border-radius-3"></uni-image>
      </div>
      <div fxLayout="column" fxLayoutAlign="center start" class="content-wrapper margin-x-10">
        <h3 class="content-title font-bolder sparro-grey-900 margin-bottom-8 margin-top-0">
          Maximize Your Impact with UNI Membership
        </h3>
        <p class="content-sub-title font-regular margin-0">
          UNI Membership unlocks the opportunity to publish unlimited Projects, Journals, and
          Publication on UNI. Expand your professional portfolio and elevate your brand by by
          sharing your projects, thoughts, and research with an engaged audience of over a million
          annual visitors.
        </p>
      </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="space-around" class="content-section-one">
      <div fxLayout="column" fxLayoutAlign="center start" class="content-wrapper margin-x-10">
        <h3 class="content-title font-bolder sparro-grey-900 margin-bottom-8 margin-top-0">
          Guaranteed expert opinion
        </h3>
        <p class="content-sub-title font-regular margin-0">
          With Institutional Membership get constructive feedback from industry experts on your
          competition entries to learn and improve your projects.
        </p>
      </div>
      <div class="circle1-bg relative">
        <uni-image
          [src]="imgFrame[4]"
          alt="design-frame"
          classes="design-frame absolute"></uni-image>
        <uni-image
          [src]="imgFrame[8]"
          alt="comment-card-bottom"
          classes="comment-card-bottom absolute"></uni-image>
        <uni-image
          [src]="imgFrame[9]"
          alt="comment-card-top"
          classes="comment-card-top absolute"></uni-image>
      </div>
    </div>
    <!-- <div fxLayout="row" fxLayoutAlign="space-around" class="content-section-two">
      <uni-image [src]="imgFrame[5]" alt="marketplace" classes="marketplace"></uni-image>
      <div
        fxLayout="column"
        fxLayoutAlign="center start"
        class="content-wrapper marketplace-content margin-x-10">
        <h3 class="content-title font-bolder sparro-grey-900 margin-bottom-8 margin-top-0">
          Get yearly access to Futurly+
        </h3>
        <p class="content-sub-title font-regular margin-top-0 margin-bottom-12">
          Create what you imagine! Learn 3D modeling and computational design from international experts, 
          at the global education platform for architects and designers. Included in large Membership only.
        </p>
        <ul class="detail-section margin-0 padding-0">
          <li class="sub-section margin-0 font-bolder">Maya Core</li>
          <li class="sub-section margin-0 font-bolder">Rhinoceros Core</li>
          <li class="sub-section margin-0 font-bolder">Fluid Architecture</li>
          <li class="sub-section margin-0 font-bolder">Blender Core</li>
          <li class="sub-section margin-0 font-bolder">Houdini for Architects</li>
          <li class="sub-section margin-0 font-bolder">Metaverse & Architecture</li>
        </ul>
        <a href="https://uni.xyz/journal/uni-and-futurly-join-hands-to-bring-you-" class="no-decor">
          <uni-navigation-button
            [default]="'Learn more'"
            button_class="secondary"
            customClass="buy-btn">
          </uni-navigation-button>
        </a>
        <p style="margin-top: -32px;" class="margin-0 sparro-grey-500">
          Get an annual Futurly+ subscription worth $129, FREE with UNI’s Large Membership – for first 100 Members only.
        </p>
      </div>
    </div> -->
    <app-designer-pricing></app-designer-pricing>
    <uni-frequent-questions
      [headingColor]="headingColor"
      headingText="General Terms & FAQs"
      [FAQs]="FAQs">
    </uni-frequent-questions>
  </div>
  <div>
    <uni-public-footer [topMargin]="false"></uni-public-footer>
  </div>
</div>
