import { MaterialModule } from '@sparro/app/material.module';
import { SharedModule } from '@about/app/shared/shared.module';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ShareModule } from 'ngx-sharebuttons';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ButtonsModule } from '@sparro/app/shared/components/buttons/buttons.module';
import { CardsModule } from '@sparro/app/shared/components/cards/cards.module';
import { ImagesModule } from '@sparro/app/shared/components/images/images.module';
import { LayoutModule } from '@sparro/app/shared/components/layout/layout.module';
import { PipesModule } from '@sparro/app/shared/pipes/pipes.module';
import { LoaderModule } from '@sparro/app/shared/components/loader/loader.module';

import { AboutRoutingModule } from './about-routing.module';
import { AboutComponent } from './about/about.component';
import { PeopleComponent } from './people/people.component';
import { JurorComponent } from './experts/juror/juror.component';
import { MentorComponent } from './experts/mentor/mentor.component';
import { CuratorComponent } from './experts/curator/curator.component';
import { PortfolioComponent } from './designers/portfolio/portfolio.component';
import { MembershipComponent } from './designers/membership/membership.component';
import { InstitutionalAccessComponent } from './designers/institutional-access/institutional-access.component';
import { InstitutionGroupComponent } from './designers/institutional-access/institution-group/institution-group.component';
import { ListComponent } from './businesses/list/list.component';
import { HostComponent } from './businesses/host/host.component';
import { SponsorComponent } from './businesses/sponsor/sponsor.component';
import { PageBottomSectionComponent } from './businesses/common/page-bottom-section/page-bottom-section.component';
import { PageTopSectionComponent } from './businesses/common/page-top-section/page-top-section.component';
import { BusinessPagesComponent } from './businesses/business-pages/business-pages.component';
import { UniForComponent } from './uni-for/uni-for.component';
import { DesignerPagesComponent } from './designers/designer-pages/designer-pages.component';
import { ExpertPagesComponent } from './experts/expert-pages/expert-pages.component';
import { NotificationsComponent } from '../notifications/notifications.component';
import { ContributionComponent } from './contribution/contribution.component';
import { DesignerPricingComponent } from './designers/designer-pricing/designer-pricing.component';
import { PricingComponent } from './businesses/pricing/pricing.component';

@NgModule({
  declarations: [
    AboutComponent,
    PeopleComponent,
    JurorComponent,
    MentorComponent,
    CuratorComponent,
    PortfolioComponent,
    MembershipComponent,
    InstitutionalAccessComponent,
    InstitutionGroupComponent,
    ListComponent,
    HostComponent,
    SponsorComponent,
    PageBottomSectionComponent,
    PageTopSectionComponent,
    BusinessPagesComponent,
    UniForComponent,
    DesignerPagesComponent,
    ExpertPagesComponent,
    NotificationsComponent,
    ContributionComponent,
    DesignerPricingComponent,
    PricingComponent
  ],
  imports: [
    CommonModule,
    AboutRoutingModule,
    FlexLayoutModule,
    SharedModule,
    ImagesModule,
    LayoutModule,
    ButtonsModule,
    CardsModule,
    LoaderModule,
    MaterialModule,
    PipesModule,
    ShareModule
  ]
})
export class AboutModule {}
