<div class="main-container">
  <div class="cover-wrapper">
    <div class="cover-container" fxLayout="column">
      <app-landing-page-header [smallView]="smallView" [user]="user"> </app-landing-page-header>
      <div class="content-container" fxLayout="column" fxLayoutAlign="space-between">
        <div class="main-content">
          <div class="text-section" fxLayout="column">
            <div class="title-section">
              <h1 class="title margin-0">Say goodbye to web scattering</h1>
            </div>
            <p class="subtitle margin-0">
              Showcase all your work in one place! “Together.” At UNI, embrace your creative
              diversity, and present your digital portfolio on a single platform.
            </p>
          </div>
          <div class="img-section relative">
            <uni-image
              class="frame cover-img-3 absolute"
              src="https://uni-common-assets.s3.amazonaws.com/designers/portfolio/cover-img-3.svg"
              [alt]="'host/list a competition'"
              classes="fit-cover width-100 height-100">
            </uni-image>
            <uni-image
              class="frame cover-img-1 absolute"
              src="https://uni-common-assets.s3.amazonaws.com/designers/portfolio/cover-img-1.svg"
              [alt]="'number 1'"
              classes="fit-cover width-100 height-100">
            </uni-image>
            <uni-image
              class="frame cover-img-2 absolute"
              src="https://uni-common-assets.s3.amazonaws.com/designers/portfolio/cover-img-2.svg"
              [alt]="'number 1'"
              classes="fit-cover width-100 height-100">
            </uni-image>
            <uni-image
              class="frame cover-img-4 absolute"
              src="https://uni-common-assets.s3.amazonaws.com/designers/portfolio/cover-img-4.svg"
              [alt]="'detail screen'"
              classes="fit-cover width-100 height-100">
            </uni-image>
          </div>
        </div>
        <div class="sub-topic-cover-content">
          <div class="wrapper" fxLayout="column">
            <div class="text-section width-100 margin-auto" fxLayout="column">
              <div class="title-section">
                <h2 class="title margin-0">What is UNI portfolio?</h2>
              </div>
              <p class="subtitle margin-0">
                The UNI portfolio is an all-encompassing place to present everything you need to
                introduce yourself professionally
              </p>
            </div>
            <div class="width-100" fxLayout="row" fxLayoutAlign="center center">
              <div class="card-container">
                <ng-container *ngFor="let card of platformFeatures">
                  <div class="platform-feature-card" fxLayout="column" fxLayoutGap="40px">
                    <uni-image
                      [src]="card.icon"
                      alt="platform-feature-card-img"
                      classes="big-dot"></uni-image>
                    <div
                      class="card-text-section"
                      fxLayout="column"
                      fxLayoutAlign="center"
                      fxLayoutGap="24px">
                      <h4 class="title margin-0">{{ card.title }}</h4>
                      <p class="description margin-0">{{ card.description }}</p>
                    </div>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="how-it-works-section">
    <div class="how-it-works-body">
      <div class="builder-section">
        <div
          class="text-section"
          fxLayout="column"
          fxLayoutAlign="space-between start"
          fxLayoutGap="24px">
          <h3 class="title margin-0">Establish yourself as an expert in your industry.</h3>
          <p class="description margin-0 sparro-grey-800">
            Build your customized profile and get discovered by more quality employers, employees,
            recruiters, brands, and clients.
          </p>
          <div class="detail-section">
            <li fxLayout="column" class="sub-section" fxLayoutGap="4px">
              <p class="margin-0 font-bolder">Profile statistics</p>
              <p class="margin-0 sparro-grey-500" style="font-size: 14px; line-height: 17px">
                Get a realtime feedback of your profile’s performance
              </p>
            </li>
            <li fxLayout="column" class="sub-section" fxLayoutGap="4px">
              <p class="margin-0 font-bolder">Platform visibility</p>
              <p class="margin-0 sparro-grey-500" style="font-size: 14px; line-height: 17px">
                Build credibility, and network with experts and professionals
              </p>
            </li>
            <li fxLayout="column" class="sub-section" fxLayoutGap="4px">
              <p class="margin-0 font-bolder">Privacy</p>
              <p class="margin-0 sparro-grey-500" style="font-size: 14px; line-height: 17px">
                Hide or display your information as per convenience
              </p>
            </li>
            <li fxLayout="column" class="sub-section" fxLayoutGap="4px">
              <p class="margin-0 font-bolder">Share your achievements</p>
              <p class="margin-0 sparro-grey-500" style="font-size: 14px; line-height: 17px">
                Showcase your creative journey and skills with our community
              </p>
            </li>
          </div>
          <a href="https://uni.xyz/profile/" mat-button class="no-decor button-section">
            Create my profile
          </a>
        </div>
        <div class="image-section section-1 relative">
          <uni-image
            class="number-1 absolute"
            src="https://uni-common-assets.s3.amazonaws.com/designers/portfolio/dotted-circle-1.svg"
            [alt]="'number 1'"
            classes="fit-cover width-100 height-100">
          </uni-image>
          <uni-image
            class="frame profile-score-card-img absolute"
            src="https://uni-common-assets.s3.amazonaws.com/designers/portfolio/profile-score-card.svg"
            [alt]="'detail screen'"
            classes="fit-cover width-100 height-100">
          </uni-image>
          <uni-image
            class="frame about-card-img absolute"
            src="https://uni-common-assets.s3.amazonaws.com/designers/portfolio/about-card.svg"
            [alt]="'number 1'"
            classes="fit-cover width-100 height-100">
          </uni-image>
        </div>
      </div>
      <div class="builder-section even-section">
        <div class="image-section section-2 relative">
          <uni-image
            class="absolute number-2"
            src="https://uni-common-assets.s3.amazonaws.com/designers/portfolio/dotted-circle-2.svg"
            [alt]="'number 2'"
            classes="fit-cover width-100 height-100">
          </uni-image>
          <uni-image
            class="frame absolute collab-card-img"
            src="https://uni-common-assets.s3.amazonaws.com/designers/portfolio/collaborator-card.svg"
            [alt]="'pricing detail'"
            classes="fit-cover width-100 height-100">
          </uni-image>
          <uni-image
            class="frame absolute popup-img"
            src="https://uni-common-assets.s3.amazonaws.com/designers/portfolio/popup.svg"
            [alt]="'pricing detail'"
            classes="fit-cover width-100 height-100">
          </uni-image>
        </div>
        <div
          class="text-section"
          fxLayout="column"
          fxLayoutAlign="space-between start"
          fxLayoutGap="24px">
          <h3 class="title margin-0">Showcase your projects</h3>
          <p class="description margin-0 sparro-grey-800">
            Create standalone presentations with detailed options like adding your team, tags,
            locations – everything you need to deliver that kickass presentation in a clutter free
            format.
          </p>

          <div class="detail-section">
            <li fxLayout="column" class="sub-section" fxLayoutGap="4px">
              <p class="margin-0 font-bolder">AI Powered</p>
              <p class="margin-0 sparro-grey-500" style="font-size: 14px; line-height: 17px">
                Builder automatically tags your projects for a better discovery
              </p>
            </li>
            <li fxLayout="column" class="sub-section" fxLayoutGap="4px">
              <p class="margin-0 font-bolder">Project analytics</p>
              <p class="margin-0 sparro-grey-500" style="font-size: 14px; line-height: 17px">
                Get insights of your project performance over time.
              </p>
            </li>
            <li fxLayout="column" class="sub-section" fxLayoutGap="4px">
              <p class="margin-0 font-bolder">Licensing</p>
              <p class="margin-0 sparro-grey-500" style="font-size: 14px; line-height: 17px">
                Creative commons licensing for all your project
              </p>
            </li>
            <li fxLayout="column" class="sub-section" fxLayoutGap="4px">
              <p class="margin-0 font-bolder">Transparent feedback</p>
              <p class="margin-0 sparro-grey-500" style="font-size: 14px; line-height: 17px">
                On different aspects of your project from our experts.
              </p>
            </li>
          </div>
          <a href="https://uni.xyz/builder/new-project" mat-button class="no-decor button-section">
            Publish project
          </a>
        </div>
      </div>
      <div class="builder-section">
        <div
          class="text-section text-section-background"
          fxLayout="column"
          fxLayoutAlign="space-between start"
          fxLayoutGap="24px">
          <h3 class="title margin-0">Write down your experiences</h3>
          <p class="description margin-0 sparro-grey-800">
            Pen your thoughts, ideas, everything written and link it to your project story in our
            journal module. Deliver that inside scoop which you always wanted to share with the
            world.
          </p>
          <div class="detail-section">
            <li fxLayout="column" class="sub-section" fxLayoutGap="4px">
              <p class="margin-0 font-bolder">Rich text editor</p>
              <p class="margin-0 sparro-grey-500" style="font-size: 14px; line-height: 17px">
                Stylize and format your content for your audience.
              </p>
            </li>
            <li fxLayout="column" class="sub-section" fxLayoutGap="4px">
              <p class="margin-0 font-bolder">Zero Ads</p>
              <p class="margin-0 sparro-grey-500" style="font-size: 14px; line-height: 17px">
                Unlike other blogging platform get a clean ad free experience.
              </p>
            </li>
            <li fxLayout="column" class="sub-section" fxLayoutGap="4px">
              <p class="margin-0 font-bolder">Privacy</p>
              <p class="margin-0 sparro-grey-500" style="font-size: 14px; line-height: 17px">
                Maintain your blogs privacy and pernmission all at one place
              </p>
            </li>
            <li fxLayout="column" class="sub-section" fxLayoutGap="4px">
              <p class="margin-0 font-bolder">SEO Friendly</p>
              <p class="margin-0 sparro-grey-500" style="font-size: 14px; line-height: 17px">
                Pre-optimized for search engines for a better discovery
              </p>
            </li>
          </div>
          <a href="https://uni.xyz/journal/edit/" mat-button class="no-decor button-section">
            Start writing
          </a>
        </div>
        <div
          fxLayout="column"
          fxLayoutGap="24px"
          fxLayoutAlign="center center"
          class="blog-card-container">
          <uni-image
            class="frame blog-card-img"
            src="https://uni-common-assets.s3.amazonaws.com/designers/portfolio/journal-card-1.svg"
            [alt]="'number 3'"
            classes="fit-cover width-100 height-100">
          </uni-image>
          <uni-image
            class="frame blog-card-img"
            src="https://uni-common-assets.s3.amazonaws.com/designers/portfolio/journal-card-2.svg"
            [alt]="'pricing detail'"
            classes="fit-cover width-100 height-100">
          </uni-image>
          <uni-image
            class="frame blog-card-img"
            src="https://uni-common-assets.s3.amazonaws.com/designers/portfolio/journal-card-3.svg"
            [alt]="'pricing detail'"
            classes="fit-cover width-100 height-100">
          </uni-image>
        </div>
      </div>
      <div class="builder-section even-section">
        <div class="image-section section-4 relative">
          <uni-image
            class="circles absolute"
            src="https://uni-common-assets.s3.amazonaws.com/designers/portfolio/circles.svg"
            [alt]="'number 1'"
            classes="fit-cover width-100 height-100">
          </uni-image>
          <uni-image
            class="frame pdf-reader-img absolute"
            src="https://uni-common-assets.s3.amazonaws.com/designers/portfolio/pdf-reader.svg"
            [alt]="'detail screen'"
            classes="fit-cover width-100 height-100 pdf-reader">
          </uni-image>
          <uni-image
            class="frame publication-card-img absolute"
            src="https://uni-common-assets.s3.amazonaws.com/designers/portfolio/publication-card.svg"
            [alt]="'host/list a competition'"
            classes="fit-cover width-100 height-100">
          </uni-image>
        </div>
        <div
          class="text-section"
          fxLayout="column"
          fxLayoutAlign="space-between start"
          fxLayoutGap="24px">
          <h3 class="title margin-0">Share your publication</h3>
          <p class="description margin-0 sparro-grey-800">
            Do you have a Book, Research paper, Magazine or even your consolidated portfolio to
            share? Check out our publications sections to quickly create a stellar flip book like
            experience to reflect your work.
          </p>
          <div class="detail-section">
            <li fxLayout="column" class="sub-section" fxLayoutGap="4px">
              <p class="margin-0 font-bolder">Seamless UI</p>
              <p class="margin-0 sparro-grey-500 desc">
                A book that you would love to flip over and over.
              </p>
            </li>
            <li fxLayout="column" class="sub-section" fxLayoutGap="4px">
              <p class="margin-0 font-bolder">Detailed analytics</p>
              <p class="margin-0 sparro-grey-500 desc">
                Access insights of your publications on the go.
              </p>
            </li>
            <li fxLayout="column" class="sub-section" fxLayoutGap="4px">
              <p class="margin-0 font-bolder">Licensing</p>
              <p class="margin-0 sparro-grey-500 desc">
                Creative commons licensing for all your project
              </p>
            </li>
            <li fxLayout="column" class="sub-section" fxLayoutGap="4px">
              <p class="margin-0 font-bolder">Get feedback</p>
              <p class="margin-0 sparro-grey-500 desc">
                From experts and community member on your content.
              </p>
            </li>
          </div>
          <a
            href="https://uni.xyz/publications/edit/new_publication"
            mat-button
            class="no-decor button-section">
            Create publication
          </a>
        </div>
      </div>
    </div>
  </div>
  <uni-frequent-questions [headingColor]="faqHeadingColor" [FAQs]="frequentlyAskedQuestions">
  </uni-frequent-questions>
</div>
<uni-public-footer [topMargin]="false"></uni-public-footer>
