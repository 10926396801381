<div class="main-container">
  <app-page-top-section
    [data]="data.extraInfo"
    [smallView]="smallView"
    [platformFeatures]="platformFeatures"
    [competitions]="competitions"
    [host]="host">
  </app-page-top-section>
  <div class="how-it-works-section">
    <h2 class="margin-0 heading sparro-grey-900">How it works?</h2>
    <div class="how-it-works-body">
      <ng-container *ngFor="let section of data.sections; let i = index">
        <div [class]="i % 2 == 0 ? 'even-section' : 'odd-section'">
          <ng-container *ngTemplateOutlet="textSection"> </ng-container>
          <div class="image-section" *ngIf="ongoingCompetitions.length">
            <ng-container *ngIf="i == 0">
              <uni-image
                class="number-1 absolute"
                src="https://uni-common-assets.s3.amazonaws.com/businesses/number-1-2nd.svg"
                [alt]="'number 1'"
                classes="fit-cover width-100 height-100">
              </uni-image>
              <uni-competition-card class="top absolute" [competition]="ongoingCompetitions[0]">
              </uni-competition-card>
              <uni-competition-card class="left absolute" [competition]="ongoingCompetitions[1]">
              </uni-competition-card>
              <uni-competition-card class="right absolute" [competition]="ongoingCompetitions[2]">
              </uni-competition-card>
              <uni-competition-card class="bottom absolute" [competition]="ongoingCompetitions[3]">
              </uni-competition-card>
            </ng-container>
            <ng-container *ngIf="i == 1">
              <uni-image
                class="section-2-img absolute"
                src="https://uni-common-assets.s3.amazonaws.com/businesses/sponsor/section-2.svg"
                [alt]="'number 2'"
                classes="fit-cover width-100 height-100">
              </uni-image>
            </ng-container>
            <ng-container *ngIf="i == 2">
              <uni-image
                class="section-3-img absolute"
                src="https://uni-common-assets.s3.amazonaws.com/businesses/sponsor/section-3.svg"
                [alt]="'pricing detail'"
                classes="fit-cover width-100 height-100">
              </uni-image>
            </ng-container>
          </div>
        </div>
        <ng-template #textSection>
          <div
            class="text-section"
            fxLayout="column"
            fxLayoutAlign="space-between start"
            fxLayoutGap="24px">
            <h3 class="title margin-0">{{ section.title }}</h3>
            <p class="description margin-0 sparro-grey-800">
              {{ section.subtitle }}
            </p>
            <div class="expansion-panel-container width-100">
              <ng-container *ngFor="let detail of section.panelData; let index = index">
                <mat-expansion-panel class="expansion-panel">
                  <mat-expansion-panel-header (click)="setActiveIndex(i, index)">
                    <mat-panel-title>
                      <h4
                        [ngStyle]="{
                          color: detail.expanded ? faqHeadingColor : '#232323',
                          'font-weight': detail.expanded ? '700' : '400'
                        }"
                        class="margin-0">
                        {{ detail.title }}
                      </h4>
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <p [innerHtml]="detail.description | safeContent" class="margin-bottom-0"></p>
                </mat-expansion-panel>
              </ng-container>
            </div>
          </div>
        </ng-template>
      </ng-container>
    </div>
  </div>
  <app-page-bottom-section
    [smallView]="smallView"
    [extraSmallView]="smallView"
    [packages]="packages"
    [footerData]="data.footer"
    [demoUrl]="data.demoUrl"
    [host]="host"
    [competitions]="competitions.slice(3, 8)">
  </app-page-bottom-section>
</div>
<uni-public-footer [topMargin]="false"></uni-public-footer>
