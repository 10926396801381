import { Component, ElementRef, HostListener, Input, OnInit, ViewChild } from '@angular/core';

export class PackageOrPlan {
  title: string;
  price: number;
  unit: string;
  features: string[];
  buttonText: string;
  url: string;
  popular?: boolean;
}

@Component({
  selector: 'app-pricing',
  templateUrl: './pricing.component.html',
  styleUrls: ['./pricing.component.scss']
})
export class PricingComponent implements OnInit {
  @Input() host: string;

  packages: PackageOrPlan[] = [];

  hostPackages: PackageOrPlan[] = [
    {
      title: 'Starter',
      price: 199,
      unit: 'per competition',
      features: [
        'Dedicated competition page on UNI',
        'Submission & Evaluation Portal',
        'Basic Analytics of the entries',
        'Projects and Competition Page hosted for lifetime'
      ],
      buttonText: 'Select Starter',
      url: `https://uni.xyz/payment/products/f9e011f0-ec64-4c64-845e-872703630a49`
    },
    {
      title: 'Standard',
      price: 329,
      unit: 'per competition',
      features: [
        '<b>Everything in Starter +</b>',
        'Featured on UNI <b>30 Days</b> and in <b>2 weekly newsletters</b>',
        '<b>3 Shoutouts</b> on our Social Media Accounts',
        '<b>2 Blog</b> (Pre-Result and Post-Result)'
      ],
      buttonText: 'Select Standard',
      url: `https://uni.xyz/payment/products/6450f0a2-84b1-4963-be92-ca58cacb0ec2`,
      popular: true
    },
    {
      title: 'Premium',
      price: 699,
      unit: 'per competition',
      features: [
        '<b>Everything in Standard +</b>',
        `Featured on UNI <b>120 Days</b> and in <b>4 weekly newsletter</b>`,
        '<b>10 Shoutouts</b> on our Social Media Accounts',
        'Detailed analytics with Weekly updates',
        'Deadline alerts to all subscribers'
      ],
      buttonText: 'Select Premium',
      url: `https://uni.xyz/payment/products/ac597d01-dede-4924-92c4-e97096ed99ac`
    }
  ];

  listPackages: PackageOrPlan[] = [
    {
      title: 'Weekly',
      price: 20,
      unit: 'per competition',
      features: [
        'Competition featured on UNI homepage banner for <b>7 days</b>',
        'Competition featured in <b>1 weekly newsletter</b>',
        '<b>1 Shoutout</b> on our Social Media Accounts'
      ],
      buttonText: 'Select weekly',
      url: `https://uni.xyz/payment/products/c82be7c9-18e8-4070-8a9d-76f446e017bc`
    },
    {
      title: 'Monthly',
      price: 75,
      unit: 'per competition',
      features: [
        'Competition featured on UNI homepage banner for <b>30 days</b>',
        'Competition featured in <b>2 weekly newsletters</b>',
        '<b>3 Shoutouts</b> on our Social Media Accounts'
      ],
      buttonText: 'Select monthly',
      url: `https://uni.xyz/payment/products/e16e0bb0-2a26-4b31-90cf-22f3356596bc`,
      popular: true
    },
    {
      title: 'Perennial',
      price: 150,
      unit: 'per competition',
      features: [
        'Competition featured on UNI homepage banner for <b>90 days</b>',
        'Competition featured in <b>6 weekly newsletters</b>',
        '<b>10 Shoutouts</b> on our Social Media Accounts',
        '1 Featured blog post on UNI',
        '3 Deadline alerts to all subscribers',
        '<b>2 Blogs</b> (Pre Result and Post Result)'
      ],
      buttonText: 'Select perennial',
      url: `https://uni.xyz/payment/products/397b3245-d355-4e4c-938a-84af1e80aa0d`
    }
  ];

  sponsorPackages: PackageOrPlan[] = [
    {
      title: 'Bronze',
      price: 150,
      unit: '16 per competition',
      features: [
        'Featured on competition partner’s page',
        'Visibility on notes to jurors',
        'Stack the amount for sponsoring multiple awards',
        '<b>Sponsored by</b> icon on the winning project (Respective to the award you want to sponsor)',
        'Basic analytics at the end of the campaign'
      ],
      buttonText: 'Select Bronze',
      url: `https://uni.xyz/payment/products/847f811b-1401-443d-ab22-f99ecc920cd9`
    },
    {
      title: 'Gold',
      price: 350,
      unit: '6 per competition',
      features: [
        'All in Bronze sponsorship plan plus',
        'Featured in <b>2 Weekly newsletters </b>',
        'Featured in competition article or PR articles on UNI blog page ',
        'Logo in promotional videos & social media contents'
      ],
      buttonText: 'Select Gold',
      url: `https://uni.xyz/payment/products/2947ea9a-2e54-4088-a88b-fa9b05cf49b7`
    },
    {
      title: 'Title',
      price: 1500,
      unit: '1 per competition',
      features: [
        'All in Gold sponsorship plan plus',
        'Dedicated Sponsor page within the competition.',
        'Large logo on competition cover page.',
        'Opportunity to be a part of the judging panel.',
        'Detailed analytics at the end of the campaign'
      ],
      buttonText: 'Select Title',
      url: `https://uni.xyz/payment/products/2ae73a37-b0fc-450f-9513-eb4d752912b5`
    }
  ];
  scrollLeft: boolean = false;
  scrollRight: boolean = false;
  moveLeft: boolean = false;
  moveRight: boolean = false;

  @ViewChild('planCards') planCards: ElementRef;

  constructor() {}

  ngOnInit(): void {
    if (this.host == 'host-competition') {
      this.packages = this.hostPackages;
    } else if (this.host == 'list-competition') {
      this.packages = this.listPackages;
    } else {
      this.packages = this.sponsorPackages;
    }
  }
  scrollNext(direction: string): void {
    if (direction == 'left') {
      this.planCards.nativeElement.scrollLeft -= 380;
    } else if (direction == 'right') {
      this.planCards.nativeElement.scrollLeft += 380;
    }
  }

  @HostListener('window:scroll', [])
  onWindowScroll(): void {
    let planCardsScroller = this.planCards.nativeElement;
    if (
      planCardsScroller.offsetWidth < planCardsScroller.scrollWidth &&
      planCardsScroller.scrollWidth - planCardsScroller.offsetWidth != planCardsScroller.scrollLeft
    ) {
      this.scrollRight = true;
    } else {
      this.scrollRight = false;
    }
  }

  planCardsScroll(): void {
    let planCardsScroller = this.planCards.nativeElement;
    if (planCardsScroller.scrollLeft == 0) {
      this.scrollLeft = false;
      this.scrollRight = true;
    } else if (
      planCardsScroller.scrollWidth - planCardsScroller.offsetWidth ==
      planCardsScroller.scrollLeft
    ) {
      this.scrollRight = false;
      this.scrollLeft = true;
    } else {
      this.scrollLeft = true;
      this.scrollRight = true;
    }
  }
}
