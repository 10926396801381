import { FEATURES } from '@about/app/core/configs/landing.config';
import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, OnInit } from '@angular/core';
import { CompetitionService } from '@sparro/app/core/api/competition/competition.service';
import { FAQService } from '@sparro/app/core/api/faq/faq.service';
import { UserService } from '@sparro/app/core/api/user/user.service';
import { BREAKPOINTS } from '@sparro/app/core/config/constants.config';
import { Competition } from '@sparro/app/core/models/competition.model';
import { FAQ } from '@sparro/app/core/models/faq.model';
import { User } from '@sparro/app/core/models/user.model';
import { environment } from '@about/env/environment';
import { MetaDefinition } from '@angular/platform-browser';
import { MetaService } from '@sparro/app/core/helpers/meta/meta.service';

export class Features {
  title: string;
  description: string;
  icon: string;
}

@Component({
  selector: 'app-mentor',
  templateUrl: './mentor.component.html',
  styleUrls: ['./mentor.component.scss']
})
export class MentorComponent implements OnInit {
  user: User;
  smallView: boolean = false;
  optimizedCoverImage: string;
  contributionForm: boolean = false;
  competitionLaunchForm: boolean = false;
  imgFrame = FEATURES.EXPERTS.mentor;
  competitions: Competition[] = [];
  formOpened: boolean = false;
  platformFeatures: Features[] = [
    {
      title: 'Craft interesting programmes',
      description: `Whether you’re an expert on indigenous architecture or designing for outer space, our diverse 
        community is open to learning emerging subjects that are not found in traditional academia.`,
      icon: 'https://uni-common-assets.s3.amazonaws.com/experts/mentor/icon-1.svg'
    },
    {
      title: 'Record your lectures or go live',
      description: `Whether you prefer live classes with real-time interaction for participants or recorded lectures, 
        which you can complete at your own pace, UNI classroom has got you covered through it’s platform.`,
      icon: 'https://uni-common-assets.s3.amazonaws.com/experts/mentor/icon-2.svg'
    },
    {
      title: `Launch exercises for learners`,
      description: `You can launch a competition related to every course you host. It gives students an option to test their 
        skills and learn by doing. Set a brief, pick a deadline, and let students come up with their imaginations.`,
      icon: 'https://uni-common-assets.s3.amazonaws.com/experts/mentor/icon-3.svg'
    }
  ];
  workingSteps: Features[] = [
    {
      title: 'Fill out the form',
      description:
        'Read carefully the criteria & requirements to be a mentor on UNI and fill out the application form',
      icon: 'https://uni-common-assets.s3.amazonaws.com/experts/common/icon-form.svg'
    },
    {
      title: 'Get verified',
      description:
        'Our team members will check for your details and approve your profile upon successful verification',
      icon: 'https://uni-common-assets.s3.amazonaws.com/experts/common/icon-verified.svg'
    },
    {
      title: 'Launch your course',
      description:
        'Our team will guide you through the whole process of launching your course on UNI.',
      icon: 'https://uni-common-assets.s3.amazonaws.com/experts/mentor/icon-mentor.svg'
    }
  ];
  faqHeadingColor: string = '#f15a24';
  frequentlyAskedQuestions: FAQ[] = [];
  jurorFormLink: string =
    'https://submissions.uni.xyz/unixyz/form/BecomeaJuroe/formperma/JTPbmQw8mgrpa5EJ1HE4b7vX1MMPV_YLGdPWtPdb6OE';

  constructor(
    private userService: UserService,
    public breakpointObserver: BreakpointObserver,
    private competitionService: CompetitionService,
    private faqService: FAQService,
    public metaService: MetaService
  ) {}

  ngOnInit() {
    this.updateMetaInfo();
    this.getAuthUser();
    this.initializeBreakpoints();
    this.getFaqs();
    this.getFeaturedCompetitions();
  }

  openForm() {
    this.formOpened = true;
  }

  close() {
    this.formOpened = false;
  }

  getFeaturedCompetitions(): void {
    this.competitionService.getFeaturedCompetitions().subscribe((response: any) => {
      this.competitions = response.results;
    });
  }

  getFaqs(): void {
    this.faqService.getFAQs('experts-mentor').subscribe((response: FAQ[]) => {
      this.frequentlyAskedQuestions = response;
    });
  }

  getAuthUser(): void {
    this.userService.userInitialized.subscribe((initialized: boolean) => {
      if (initialized) {
        this.userService.user.subscribe((user: User) => {
          this.user = user;
        });
      }
    });
  }

  initializeBreakpoints(): void {
    this.breakpointObserver
      .observe([BREAKPOINTS.below.lg, BREAKPOINTS.below.md])
      .subscribe(result => {
        this.smallView = Boolean(result.breakpoints[BREAKPOINTS.below.lg]);
      });
  }

  getJurorPageUrl(): string {
    return `${environment.aboutUrl}about/experts/juror`;
  }

  getCuratorPageUrl(): string {
    return `${environment.aboutUrl}about/experts/curator`;
  }

  getFormUrl(): string {
    return `${environment.loginUrl}authentication?service=sign-up&continue=${this.jurorFormLink}`;
  }

  updateMetaInfo(): void {
    const title = 'Make an impact. Be a mentor.';
    const description = `Looking for a way to make an impact and share your knowledge? 
      Consider becoming a mentor! Our architecture and design community is looking for 
      mentors to help guide students & young professionals through exercises and challenges. 
      If you have a passion for teaching and helping others, this is the perfect opportunity 
      for you! Learn more about the program and how to apply today.`;
    const cover = `https://uni-common-assets.s3.us-east-1.amazonaws.com/experts/mentor/mentor-cover.png`;
    const metaInfo: MetaDefinition[] = [
      { name: 'title', content: title },
      { name: 'description', content: description },
      { name: 'url', content: window.location.href },
      { name: 'image', content: cover },
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:title', content: title },
      { name: 'twitter:description', content: description },
      { name: 'twitter:url', content: window.location.href },
      { name: 'twitter:image', content: cover },
      { property: 'og:title', content: title },
      { property: 'og:description', content: description },
      { property: 'og:url', content: window.location.href },
      { property: 'og:image', content: cover },
      { property: 'og:image:alt', content: 'UNI- Design competitions' },
      { property: 'og:type', content: 'article' }
    ];
    metaInfo.forEach(meta => {
      this.metaService.updateMetaInfo(meta);
    });
    this.metaService.updateMeta(metaInfo);
    this.metaService.updateTitle(title);
    this.metaService.createCanonicalURL();
  }
}
