import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-designer-pricing',
  templateUrl: './designer-pricing.component.html',
  styleUrls: ['./designer-pricing.component.scss']
})
export class DesignerPricingComponent implements OnInit {
  uniMemberhsipPlans = [
    {
      title: 'Weekly',
      subTitle: 'Valid for 1 Week',
      price: '9',
      url: 'https://uni.xyz/payment/products/bc2d3358-d531-4030-9298-cb261155afe5',
      buttonTitle: 'Get weekly pass',
      data: [
        'Access to unlimited content on the platform',
        'Pay per competition to participate in challenges',
        'Community support available on Discord',
        'Enjoy an <b>ad-free</b> experience on the platform'
      ]
    },

    {
      title: 'Monthly',
      subTitle: 'Valid for 1 Month',
      price: '19',
      url: 'https://uni.xyz/payment/products/cce75ec1-feb4-440c-a658-21ebaa8cc3c4',
      buttonTitle: 'Get monthly pass',
      data: [
        '<b>Everything in Weekly+</b>',
        'Publish an unlimited number of projects, journals, and publications',
        'Access to additional resources of all the competitions hosted by UNI',
        'Dedicated support available on email'
      ]
    },
    {
      title: 'Semester',
      subTitle: 'Valid for 6 Months',
      price: '69',
      url: 'https://uni.xyz/payment/products/b04f9889-7696-4bfc-97d0-3471155619f6',
      buttonTitle: 'Get semester pass',
      data: [
        '<b>Everything in Monthly +</b>',
        'Submit upto <b>1 entry</b> in any competition of your choice',
        'Access to weekly newsletter from UNI',
        "Rate other's project anonymously",
        'Dedicated support from UNI on Discord & email'
      ]
    },
    {
      title: 'Annual',
      subTitle: 'Valid for 1 Year',
      price: '107',
      url: 'https://uni.xyz/payment/products/da820dea-850a-4c0d-9565-14a69f598281',
      buttonTitle: 'Get annual pass',
      data: [
        '<b>Everything in Semester Pass +</b>',
        'Submit upto <b>2 entries</b> in any competition of your choice',
        'Detailed analytics of your entries & projects',
        'Keep your collections private',
        'Get <b>Verified Badge</b> on your profile'
        /*'Personalized e-meeting with upto <b>5 project owners</b>'*/
      ]
    },
    {
      title: 'Studio',
      subTitle: 'Valid for 1 Year',
      price: '214',
      url: 'https://uni.xyz/payment/products/e00ecd4c-6983-4f8d-adab-1edc8b02ba13',
      buttonTitle: 'Get Studio',
      data: [
        '<b>Everything in Annual Membership +</b>',
        'Submit upto <b>5 entries</b> in any competition of your choice',
        'Access to all UNI Yearbooks electronic versions <b>worth over $100</b>',
        'Exclusive editorial section to publish your content',
        'Access to AI powered journal editor'
      ]
    },
    {
      title: 'Institutional',
      subTitle: 'Valid for 1 Year',
      price: '537',
      url: 'https://uni.xyz/payment/products/fe049e85-f5e3-4f2e-8551-9a6698a55b4b',
      buttonTitle: 'Get Institutional',
      data: [
        '<b>Everything in Studio +</b>',
        'Submit upto <b>15 entries</b> in any competition of your choice',
        'Curate and launch upto <b>2 community run competitions</b>',
        'Get assured feedback on your entries from verified jurors',
        'On demand Live Online Support'
      ]
    }
  ];

  @ViewChild('planCards') planCards: ElementRef;
  scrollLeft: boolean = false;
  scrollRight: boolean = false;
  moveLeft: boolean = false;
  moveRight: boolean = false;

  constructor() {}

  ngOnInit(): void {}

  @HostListener('window:scroll', [])
  onWindowScroll(): void {
    let planCardsScroller = this.planCards.nativeElement;
    if (
      planCardsScroller.offsetWidth < planCardsScroller.scrollWidth &&
      planCardsScroller.scrollWidth - planCardsScroller.offsetWidth != planCardsScroller.scrollLeft
    ) {
      this.scrollRight = true;
    } else {
      this.scrollRight = false;
    }
  }

  planCardsScroll(): void {
    let planCardsScroller = this.planCards.nativeElement;
    if (planCardsScroller.scrollLeft == 0) {
      this.scrollLeft = false;
      this.scrollRight = true;
    } else if (
      planCardsScroller.scrollWidth - planCardsScroller.offsetWidth ==
      planCardsScroller.scrollLeft
    ) {
      this.scrollRight = false;
      this.scrollLeft = true;
    } else {
      this.scrollLeft = true;
      this.scrollRight = true;
    }
  }
}
