import { Component, Input, OnInit } from '@angular/core';
import { environment } from '@sparro/env/environment';

export class Competition {
  title: string;
  slug: string;
}

@Component({
  selector: 'uni-juror-card',
  templateUrl: './juror-card.component.html',
  styleUrls: ['./juror-card.component.scss']
})
export class JurorCardComponent implements OnInit {
  @Input() juror: any;

  constructor() { }

  ngOnInit(): void { }

  getCompetitionUrl(competition: Competition): string {
    if (competition.slug) {
      return environment.competitionUrl + competition.slug;
    }
    return environment.competitionUrl + 'search?query=' + competition.title;
  }

  getProfileUrl(): string {
    return `${environment.profileUrl}${this.juror.user.username}`;
  }
}
