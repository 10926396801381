import { Component, OnInit } from '@angular/core';
import { User } from '@sparro/app/core/models/user.model';
import { UserService } from '@sparro/app/core/api/user/user.service';
import { BreakpointObserver } from '@angular/cdk/layout';
import { BREAKPOINTS } from '@sparro/app/core/config/constants.config';
import { FAQService } from '@sparro/api/faq/faq.service';
import { FAQ } from '@sparro/app/core/models/faq.model';
import { MetaDefinition } from '@angular/platform-browser';
import { MetaService } from '@sparro/app/core/helpers/meta/meta.service';
import { AboutService } from '@about/app/core/api/about/about.service';

export interface PoolDetail {
  backers: number;
  end_date: string;
  month_year: string;
  received_amount: number;
  threshold_amount: number;
  day_left: number;
}

@Component({
  selector: 'app-contribution',
  templateUrl: './contribution.component.html',
  styleUrls: ['./contribution.component.scss']
})
export class ContributionComponent implements OnInit {
  user: User;
  contributionForm: boolean = false;
  competitionLaunchForm: boolean = false;
  FAQs: FAQ[] = [];

  socialShares = [
    {
      icon: 'https://uni-common-assets.s3.amazonaws.com/icons/social/twitter/color_48x48.svg',
      url: 'https://twitter.com/sorampuri'
    },
    {
      icon: 'https://uni-common-assets.s3.amazonaws.com/icons/social/linkedin/color_48x48.svg',
      url: 'https://www.linkedin.com/in/sorampuri/'
    },
    {
      icon: 'https://uni-common-assets.s3.amazonaws.com/icons/social/discord/color_48x48.svg',
      url: 'https://discord.gg/zWSfz2AtfQ'
    }
  ];

  aboutPages = [
    {
      url: 'https://uni.xyz/about/designers/membership',
      title: 'UNI Membership',
      color: 'green-cont-btn'
    },
    {
      url: 'https://uni.xyz/about/business/list',
      title: 'Promote a competition',
      color: 'blue-cont-btn'
    },
    {
      url: 'https://uni.xyz/about/business/sponsor',
      title: 'Sponsor a competition',
      color: 'blue-cont-btn'
    },
    {
      url: 'https://uni.xyz/about/business/host',
      title: 'Host a competition',
      color: 'blue-cont-btn'
    }
  ];
  mobileView: boolean = false;
  extraSmallView: boolean = false;
  smallView: boolean = false;
  faqHeadingColor: string = '#016358';
  poolDetail: PoolDetail = null;
  barPercent: number = 0;

  constructor(
    private userService: UserService,
    public breakpointObserver: BreakpointObserver,
    private faqService: FAQService,
    public metaService: MetaService,
    public aboutService: AboutService
  ) {}

  ngOnInit(): void {
    this.updateMetaInfo();
    this.getAuthUser();
    this.initializeBreakpoints();
    this.getFAQs();
    this.getPoolDetail();
  }

  getPoolDetail() {
    this.aboutService.getPoolDetails().subscribe(
      (res: PoolDetail) => {
        this.poolDetail = res;
        this.barPercent = parseFloat(
          ((res.received_amount / res.threshold_amount) * 100).toFixed(2)
        );
      },
      error => {
        console.log(error);
      }
    );
  }

  getAuthUser(): void {
    this.userService.userInitialized.subscribe((initialized: boolean) => {
      if (initialized) {
        this.userService.user.subscribe((user: User) => {
          this.user = user;
        });
      }
    });
  }

  initializeBreakpoints(): void {
    this.breakpointObserver.observe([BREAKPOINTS.below.md]).subscribe(result => {
      this.smallView = Boolean(result.breakpoints[BREAKPOINTS.below.lg]);
      this.mobileView = Boolean(result.breakpoints[BREAKPOINTS.below.md]);
      this.extraSmallView = Boolean(result.breakpoints[BREAKPOINTS.below.sm]);
    });
  }

  getFAQs() {
    this.faqService.getFAQs('contribute-to-unifid').subscribe(
      (response: FAQ[]) => {
        this.FAQs = response;
      },
      error => console.log(error)
    );
  }

  updateMetaInfo(): void {
    const title = 'UNI-Fund for Innovation & Design | Join us in shaping the future';
    const description = `Explore the UNI-Fund for Innovation & Design and join us in shaping the future. 
      Discover our innovative projects, cutting-edge designs, and collaborative initiatives that drive 
      positive change. Partner with us to revolutionize the world through creativity and forward-thinking solutions.`;
    const cover = `https://uni-user.s3.amazonaws.com/ab/abhishek719filesabhishek7192023-07-21T06-46-15-596321.png`;
    const metaInfo: MetaDefinition[] = [
      { name: 'title', content: title },
      { name: 'description', content: description },
      { name: 'url', content: window.location.href },
      { name: 'image', content: cover },
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:title', content: title },
      { name: 'twitter:description', content: description },
      { name: 'twitter:url', content: window.location.href },
      { name: 'twitter:image', content: cover },
      { property: 'og:title', content: title },
      { property: 'og:description', content: description },
      { property: 'og:url', content: window.location.href },
      { property: 'og:image', content: cover },
      { property: 'og:image:alt', content: 'UNI- Design competitions' },
      { property: 'og:type', content: 'article' }
    ];
    metaInfo.forEach(meta => {
      this.metaService.updateMetaInfo(meta);
    });
    this.metaService.updateMeta(metaInfo);
    this.metaService.updateTitle(title);
    this.metaService.createCanonicalURL();
  }
}
