import { FEATURES } from '@about/app/core/configs/landing.config';
import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, OnInit } from '@angular/core';
import { CompetitionService } from '@sparro/app/core/api/competition/competition.service';
import { CompetitionService as b2bService } from '@about/app/core/api/competition/competition.service';
import { FAQService } from '@sparro/app/core/api/faq/faq.service';
import { UserService } from '@sparro/app/core/api/user/user.service';
import { BREAKPOINTS } from '@sparro/app/core/config/constants.config';
import { Competition } from '@sparro/app/core/models/competition.model';
import { FAQ } from '@sparro/app/core/models/faq.model';
import { User, Juror } from '@sparro/app/core/models/user.model';
import { environment } from '@about/env/environment';
import { MetaDefinition } from '@angular/platform-browser';
import { MetaService } from '@sparro/app/core/helpers/meta/meta.service';

export class Features {
  title: string;
  description: string;
  icon: string;
}

@Component({
  selector: 'app-juror',
  templateUrl: './juror.component.html',
  styleUrls: ['./juror.component.scss']
})
export class JurorComponent implements OnInit {
  user: User;
  formOpened: boolean = false;
  smallView: boolean = false;
  mobileView: boolean = false;
  extraSmallView: boolean = false;
  optimizedCoverImage: string;
  contributionForm: boolean = false;
  competitionLaunchForm: boolean = false;
  jurors: Juror[] = [];
  imgFrame = FEATURES.EXPERTS.juror;
  moveLeft: boolean = false;
  moveRight: boolean = false;
  competitions: Competition[] = [];
  platformFeatures: Features[] = [
    {
      title: 'Constructive inputs from eminent experts',
      description: `Share your expert insights based on the design brief of the competitions and 
      prior expertise in the projects you have worked upon.`,
      icon: 'https://uni-common-assets.s3.amazonaws.com/experts/juror/icon-1.svg'
    },
    {
      title: 'World class challenging problems',
      description: ` Be a part of our esteemed jury panel in the competitions of your expertise. 
        UNI launches more than 200 issue-based competitions every year.`,
      icon: 'https://uni-common-assets.s3.amazonaws.com/experts/juror/icon-2.svg'
    },
    {
      title: 'International industry experience',
      description: `UNI is a global community. Bringing your expertise on an international platform 
        provides vivid and unmatchable insights for the projects.`,
      icon: 'https://uni-common-assets.s3.amazonaws.com/experts/juror/icon-3.svg'
    }
  ];
  workingSteps: Features[] = [
    {
      title: 'Fill out the form',
      description:
        'Read carefully the criteria & requirements to be a juror on UNI and fill out the Juror application form',
      icon: 'https://uni-common-assets.s3.amazonaws.com/experts/common/icon-form.svg'
    },
    {
      title: 'Get verified',
      description:
        'Our team members will check for your details and approve your profile upon successful verification ',
      icon: 'https://uni-common-assets.s3.amazonaws.com/experts/common/icon-verified.svg'
    },
    {
      title: 'Start evaluating',
      description:
        'Once verified we will provide you a demo of the evaluation platform to get involved in the evaluation process',
      icon: 'https://uni-common-assets.s3.amazonaws.com/experts/juror/icon-evaluate.svg'
    }
  ];
  isLoading: boolean = true;
  pageNumber: number = 1;
  pageSize: number = 8;
  faqHeadingColor: string = '#f15a24';
  frequentlyAskedQuestions: FAQ[] = [];
  jurorFormLink: string =
    'https://submissions.uni.xyz/unixyz/form/BecomeaJuroe/formperma/JTPbmQw8mgrpa5EJ1HE4b7vX1MMPV_YLGdPWtPdb6OE';

  constructor(
    private userService: UserService,
    public breakpointObserver: BreakpointObserver,
    private competitionService: CompetitionService,
    private b2bService: b2bService,
    private faqService: FAQService,
    public metaService: MetaService
  ) {}

  ngOnInit() {
    this.updateMetaInfo();
    this.getAuthUser();
    this.initializeBreakpoints();
    this.getFaqs();
    this.getFeaturedCompetitions();
    this.getJurors();
  }

  getFeaturedCompetitions(): void {
    this.competitionService.getFeaturedCompetitions().subscribe((response: any) => {
      this.competitions = response.results;
    });
  }

  openForm() {
    this.formOpened = true;
  }

  close() {
    this.formOpened = false;
  }

  getFaqs(): void {
    this.faqService.getFAQs('experts-juror').subscribe((response: FAQ[]) => {
      this.frequentlyAskedQuestions = response;
    });
  }

  getAuthUser(): void {
    this.userService.userInitialized.subscribe((initialized: boolean) => {
      if (initialized) {
        this.userService.user.subscribe((user: User) => {
          this.user = user;
        });
      }
    });
  }

  getJurors(): void {
    this.isLoading = true;
    this.b2bService.getJurors(this.pageNumber, this.pageSize).subscribe(
      (response: any) => {
        this.jurors = [...this.jurors, ...response.results];
        this.isLoading = false;
      },
      error => {
        console.log(error);
        this.isLoading = false;
      }
    );
  }

  initializeBreakpoints(): void {
    this.breakpointObserver
      .observe([BREAKPOINTS.below.lg, BREAKPOINTS.below.md])
      .subscribe(result => {
        this.smallView = Boolean(result.breakpoints[BREAKPOINTS.below.lg]);
        this.mobileView = Boolean(result.breakpoints[BREAKPOINTS.below.md]);
      });

    this.pageSize = this.smallView ? (this.mobileView ? 4 : 6) : 8;
  }

  getMentorPageUrl(): string {
    return `${environment.aboutUrl}about/experts/mentor`;
  }

  getCuratorPageUrl(): string {
    return `${environment.aboutUrl}about/experts/curator`;
  }

  getFormUrl(): string {
    return `${environment.loginUrl}authentication?service=sign-up&continue=${this.jurorFormLink}`;
  }

  getJurorPageUrl(): string {
    return `${environment.aboutUrl}about/jurors`;
  }

  updateMetaInfo(): void {
    const title = 'Become a Juror on UNI, get recognized, feel valued';
    const description = `If you're an expert in the field of architecture or design, we 
      invite you to become a juror on UNI. As a juror, you'll have the opportunity to help 
      shape the future of the industry by recognizing the best and brightest talent. Plus, 
      you'll feel valued for your expertise and experience. Ready to get started?`;
    const cover = `https://uni-common-assets.s3.us-east-1.amazonaws.com/experts/juror/juror-cover.png`;
    const metaInfo: MetaDefinition[] = [
      { name: 'title', content: title },
      { name: 'description', content: description },
      { name: 'url', content: window.location.href },
      { name: 'image', content: cover },
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:title', content: title },
      { name: 'twitter:description', content: description },
      { name: 'twitter:url', content: window.location.href },
      { name: 'twitter:image', content: cover },
      { property: 'og:title', content: title },
      { property: 'og:description', content: description },
      { property: 'og:url', content: window.location.href },
      { property: 'og:image', content: cover },
      { property: 'og:image:alt', content: 'UNI- Design competitions' },
      { property: 'og:type', content: 'article' }
    ];
    metaInfo.forEach(meta => {
      this.metaService.updateMetaInfo(meta);
    });
    this.metaService.updateMeta(metaInfo);
    this.metaService.updateTitle(title);
    this.metaService.createCanonicalURL();
  }

  getProfileUrl(juror: Juror): string {
    return `${environment.profileUrl}${juror.user.username}/`;
  }
}
