<ng-container *ngIf="competitions.length">
  <div class="competition-carousel-container margin-bottom-12">
    <h2 class="title sparro-grey-900 margin-0">
      {{
        'Competitions ' +
          (host == 'list-competition'
            ? 'listed'
            : host == 'host-competition'
            ? 'hosted'
            : 'sponsored') +
          ' on UNI'
      }}
    </h2>
    <uni-competitions-card-carousel [competitions]="competitions"></uni-competitions-card-carousel>
  </div>
</ng-container>

<app-pricing [host]="host"></app-pricing>

<div
  fxLayout="column"
  fxLayoutAlign="center center"
  fxLayoutGap="40px"
  class="request-demo-section">
  <h3 class="title">Looking for something more?</h3>
  <p class="sparro-grey-500 margin-0 desc">Get in touch with us for exclusive services.</p>
  <a [href]="demoUrl" class="no-decor cursor-pointer">
    <uni-navigation-button default="Request Demo" button_class="secondary" customClass="demo-btn">
    </uni-navigation-button>
  </a>
</div>
<uni-frequent-questions [headingColor]="faqHeadingColor" [FAQs]="frequentlyAskedQuestions">
</uni-frequent-questions>
<div class="banner-section">
  <div class="text-section margin-auto" fxLayout="column" fxLayoutAlign="center center">
    <h3 class="title margin-0">{{ footerData.title }}</h3>
    <div class="button-section">
      <ng-container *ngFor="let link of footerData.links">
        <a [href]="link.url" class="no-decor cursor-pointer button font-bolder">
          {{ link.text }}
        </a>
      </ng-container>
    </div>
  </div>
</div>
