import { Component, OnInit } from '@angular/core';
import { MetaDefinition } from '@angular/platform-browser';
import { MetaService } from '@sparro/app/core/helpers/meta/meta.service';
import { environment } from '@about/env/environment';
import { BreakpointObserver } from '@angular/cdk/layout';
import { BREAKPOINTS } from '@sparro/app/core/config/constants.config';
import { ImageOptimizerService } from '@sparro/app/core/helpers/image-optimizer';
import { Competition } from '@sparro/app/core/models/competition.model';
import { CompetitionService } from '@about/app/core/api/competition/competition.service';
import { FEATURES } from '@about/app/core/configs/landing.config';

export class Features {
  title: string;
  description: string;
  icon?: string;
}

export class PackageOrPlan {
  title: string;
  price: number;
  unit: string;
  features: string[];
  buttonText: string;
  url: string;
  popular?: boolean;
}

@Component({
  selector: 'app-host',
  templateUrl: './host.component.html',
  styleUrls: ['./host.component.scss']
})
export class HostComponent implements OnInit {
  platformFeatures: Features[] = [
    {
      title: 'User-friendly interface',
      description: `Our platform offers a user-friendly interface for launching competitions, tailored 
	for architects, designers, and organizations. Easily create and manage competitions whether you 
	are an educational institution, product company, SaaS provider, or large organization.`,
      icon: 'https://uni-common-assets.s3.amazonaws.com/businesses/host/icon-1.svg'
    },
    {
      title: 'Diverse community',
      description: `Educational institutions can engage students in global challenges, product companies 
	can tap into a vast talent pool, SaaS companies can showcase their tools to a broad audience, 
	and large organizations can find innovative solutions to public challenges.`,
      icon: 'https://uni-common-assets.s3.amazonaws.com/businesses/host/icon-2.svg'
    },
    {
      title: 'Great design solutions',
      description: `This means educational institutions will see novel student projects, product companies 
	will receive cutting-edge designs, SaaS companies will get creative use cases for their tools, 
	and large organizations will find fresh approaches to public sector issues.`,
      icon: 'https://uni-common-assets.s3.amazonaws.com/businesses/host/icon-3.svg'
    }
  ];

  packages: PackageOrPlan[] = [
    {
      title: 'Starter',
      price: 199,
      unit: 'per competition',
      features: [
        'Dedicated competition page on UNI',
        'Submission & Evaluation Portal',
        'Basic Analytics',
        'Projects and Competition Page hosted for lifetime'
      ],
      buttonText: 'Select Starter',
      url: `https://uni.xyz/payment/products/f9e011f0-ec64-4c64-845e-872703630a49`
    },
    {
      title: 'Standard',
      price: 329,
      unit: 'per competition',
      features: [
        '<b>Everything in Starter +</b>',
        'Featured on UNI homepage for <b>30 Days</b> and in <b>2 weekly newsletters</b>',
        '<b>3 Shoutouts</b> on our Social Media Accounts',
        '<b>2 Blogs</b> (Pre Result and Post Result)'
      ],
      buttonText: 'Select Standard',
      url: `https://uni.xyz/payment/products/6450f0a2-84b1-4963-be92-ca58cacb0ec2`,
      popular: true
    },
    {
      title: 'Premium',
      price: 699,
      unit: 'per competition',
      features: [
        '<b>Everything in Premium +</b>',
        `Featured on UNI homepage <b>120 Days</b> and in <b>4 weekly newsletter</b>`,
        '<b>10 Shoutouts</b> on our Social Media Accounts',
        'Detailed analytics with Weekly updates',
        'Deadline alerts to all subscribers'
      ],
      buttonText: 'Select Premium',
      url: `https://uni.xyz/payment/products/ac597d01-dede-4924-92c4-e97096ed99ac`
    }
  ];

  data = {
    extraInfo: {
      pageTitle: `Launch a competition on UNI`,
      pageSubtitle: `Designers on UNI are keen to solve the most radical design problems in the world, 
	ranging from the future of transportation to preserving thousand-year-old monuments.`,
      coverImage: `https://uni-user.s3.amazonaws.com/so/sorampurifilessorampuri2024-06-19T19-29-18-393524.jpg`,
      title: `Why host on UNI?`,
      subtitle: `At UNI we have a pool of award-winning designers and a rich, diverse community of 
        creative people. Projects on UNI invites everyone, including client, participants, and jurors to 
        give feedback, discuss and build upon the ideas openly. Our open and transparent approach in the 
        field of design and architecture is the biggest reason you should host with us to find a great 
        audience for your competitions.`
    },
    sections: [
      {
        title: `Educational Institutions`,
        subtitle: `Foster innovation at Colleges and Universities. Encourage innovation and practical 
		learning among students by hosting competitions at college and university levels, such 
		as design challenges, hackathons, and research paper submissions.`,
        panelData: [
          {
            title: 'Engage students in real-world problem-solving',
            description: `Competitions like hackathons challenge students to apply their 
		theoretical knowledge to real-world problems, enhancing their critical thinking 
		and innovation skills. For example, a hackathon focused on renewable energy can 
		inspire students to create innovative prototypes that address environmental sustainability.`,
            expanded: false
          },
          {
            title: 'Enhance student portfolios with hands-on projects',
            description: `Competitions offer students the chance to work on tangible projects 
		that demonstrate their skills and creativity. For instance, winning an architectural 
		design contest allows students to add award-winning projects to their portfolios, 
		making them more attractive to future employers.`,
            expanded: false
          },
          {
            title: `Bridge the gap between academic learning and industry applications`,
            description: `Competitions provide a platform where students can apply academic 
		knowledge to industry-relevant problems. Participating in a competition such as 
		transportation design challenge helps students understand industry standards and 
		expectations, preparing them for professional roles in the future.`,
            expanded: false
          }
        ]
      },
      {
        title: `Product Companies`,
        subtitle: `Crowdsource innovative product designs. Run competitions to gather creative 
	and innovative product designs and solutions from talented individuals, such as new product 
	designs (e.g., fashion, electronics), concept development for future products, and user 
	experience and interface design challenges.`,
        panelData: [
          {
            title: `Access a wide range of creative ideas`,
            description: `Competitions attract a global pool of talent, bringing a variety of perspectives 
		and creative ideas to the table. For instance, a competition to design a new smartwatch 
		can yield numerous unique and innovative concepts, providing a wealth of options for product development.`,
            expanded: false
          },
          {
            title: `Cost-effective research and development`,
            description: `Competitions serve as an efficient and cost-effective way to conduct research 
		and development. Instead of investing heavily in internal R&D, companies can leverage the 
		creativity of external participants. For example, a competition for developing a new ergonomic 
		chair can result in a variety of high-quality designs at a fraction of the traditional R&D cost.`,
            expanded: false
          },
          {
            title: `Increase brand engagement within creative communities`,
            description: `Hosting competitions fosters engagement and loyalty within creative communities. 
		For example, a fashion brand that organizes a design contest for its upcoming collection can 
		connect with aspiring designers and fashion enthusiasts, enhancing brand visibility and 
		creating a sense of community around the brand.`,
            expanded: false
          }
        ]
      },
      {
        title: `SaaS Companies for Designers`,
        subtitle: `Market your Software tools to designers. Organize competitions to promote software tools 
		to designers and architects, demonstrating their capabilities in real-world applications, 
		such as parametric design challenges, architectural visualization contests, and software utilization projects.`,
        panelData: [
          {
            title: `Direct product marketing to the target audience`,
            description: `Competitions provide an effective way to market software tools directly to designers 
		and architects. For instance, a parametric design competition can highlight the advanced features 
		of your software, attracting and engaging potential users who experience its capabilities firsthand.`,
            expanded: false
          },
          {
            title: `User-generated content showcasing product capabilities`,
            description: `Competitions generate high-quality, user-created content that showcases your software's capabilities. 
		For example, an architectural visualization contest can produce impressive 3D renderings created 
		with your software, which you can use in marketing campaigns to demonstrate the software's potential to other designers.`,
            expanded: false
          },
          {
            title: `Obtain feedback and improve product offerings`,
            description: `Competitions offer valuable insights into how users interact with your software, helping you 
		identify areas for improvement. For example, a software utilization project competition can provide 
		direct feedback from participants, allowing you to make informed enhancements to better meet user needs.`,
            expanded: false
          }
        ]
      },
      {
        title: `Large Organizations and Government Institutions`,
        subtitle: `Find innovative solutions for Public challenges. Conduct competitions to discover innovative 
		solutions for large-scale challenges in urban planning, infrastructure, and public policy, such as 
		urban planning and smart city projects, infrastructure development ideas, and public policy innovation contests.`,
        panelData: [
          {
            title: `Engage communities in public sector projects`,
            description: `Competitions can involve the community in public sector projects, fostering a sense of 
		ownership and collaboration. For instance, a community park design competition allows residents to 
		contribute their ideas, ensuring the project reflects local needs and preferences.`,
            expanded: false
          },
          {
            title: `Discover innovative solutions for societal challenges`,
            description: `Competitions can uncover creative and effective solutions to societal challenges. 
		For example, a smart city project competition focused on traffic management can generate cutting-edge 
		ideas that improve urban mobility and reduce congestion.`,
            expanded: false
          },
          {
            title: `Foster a sense of participation in public development`,
            description: `Competitions encourage public participation in the development of policies and initiatives. 
		For instance, a public policy innovation contest allows citizens to contribute their ideas, 
		fostering a collaborative approach to addressing local issues and enhancing civic engagement.`,
            expanded: false
          }
        ]
      }
    ],
    footer: {
      title: `Don’t have a competition to host?`,
      links: [
        {
          url: `${environment.aboutUrl}about/business/list`,
          text: `List a competition`
        },
        {
          url: `${environment.aboutUrl}about/business/sponsor`,
          text: `Sponsor a competition`
        }
      ]
    },
    demoUrl: `https://tidycal.com/unixyz/host-a-competition`
  };

  faqHeadingColor: string = '#192999';
  competitions: Competition[] = [];
  smallView: boolean = false;
  mobileView: boolean = false;
  extraSmallView: boolean = false;
  imgFrame = FEATURES.BUSINESS_PAGE;
  host: string = 'host-competition';

  constructor(
    public breakpointObserver: BreakpointObserver,
    private imageOptimization: ImageOptimizerService,
    private competitionService: CompetitionService,
    public metaService: MetaService
  ) {}

  ngOnInit(): void {
    this.updateMetaInfo();
    this.initializeBreakpoints();
    this.getFeaturedCompetitions();
  }
  setActiveIndex(index1: number, index2: number) {
    this.data.sections[index1].panelData[index2].expanded = this.data.sections[index1].panelData[
      index2
    ].expanded
      ? false
      : true;
  }

  getFeaturedCompetitions(): void {
    this.competitionService.getHostedCompetitions().subscribe((response: any) => {
      this.competitions = response.results;
    });
  }

  initializeBreakpoints(): void {
    this.breakpointObserver
      .observe([BREAKPOINTS.below.lg, BREAKPOINTS.below.md, BREAKPOINTS.below.sm])
      .subscribe(result => {
        this.smallView = Boolean(result.breakpoints[BREAKPOINTS.below.lg]);
        this.mobileView = Boolean(result.breakpoints[BREAKPOINTS.below.md]);
        this.extraSmallView = Boolean(result.breakpoints[BREAKPOINTS.below.sm]);
      });
  }

  optimizedFrameImage(image: string): string {
    return this.renderImage(image, 493, 326);
  }

  renderImage(image, width?, height?): string {
    return this.imageOptimization.renderImage(image, width, height);
  }

  updateMetaInfo(): void {
    const title = 'Launch your Architecture & Design competitions on UNI';
    const description = `UNI is the perfect platform to launch your Architecture & Design competitions. 
      With our easy-to-use tools, you can publish and host your competition with ease. We also provide 
      support and resources to help you get the most out of your competition. So what are you waiting 
      for? Launch your competition today!`;
    const cover = `https://uni-common-assets.s3.amazonaws.com/businesses/Cover.svg`;
    const metaInfo: MetaDefinition[] = [
      { name: 'title', content: title },
      { name: 'description', content: description },
      { name: 'url', content: window.location.href },
      { name: 'image', content: cover },
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:title', content: title },
      { name: 'twitter:description', content: description },
      { name: 'twitter:url', content: window.location.href },
      { name: 'twitter:image', content: cover },
      { property: 'og:title', content: title },
      { property: 'og:description', content: description },
      { property: 'og:url', content: window.location.href },
      { property: 'og:image', content: cover },
      { property: 'og:image:alt', content: 'UNI- Design competitions' },
      { property: 'og:type', content: 'article' }
    ];
    metaInfo.forEach(meta => {
      this.metaService.updateMetaInfo(meta);
    });
    this.metaService.updateMeta(metaInfo);
    this.metaService.updateTitle(title);
    this.metaService.createCanonicalURL();
  }
}
