<mat-drawer-container #matDrawerContainer (backdropClick)="closeBackdrop()">
  <mat-drawer #leftnav role="navigation" class="left-sidenav-drawer" [opened]="leftSideNav">
    <uni-left-sidenav-collapsed
      (toggle)="closeBackdrop()"
      [showSidenav]="true"
      [showDiscoverSection]="true">
    </uni-left-sidenav-collapsed>
  </mat-drawer>
  <mat-drawer-content>
    <div class="fixed top-0" fxLayout="row" *ngIf="!mobileView">
      <uni-left-sidenav-collapsed [showSidenav]="true" (toggle)="leftToggle($event)">
      </uni-left-sidenav-collapsed>
    </div>
    <div>
      <uni-header
        [user]="user"
        [search]="false"
        [transparentHeader]="true"
        (leftSidenav)="leftToggle($event)">
      </uni-header>
    </div>
    <main class="app-main">
      <div class="main-section" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="16px">
        <h1 #title>{{ pageDetail.title }}</h1>
        <p class="margin-0 subtitle text-center">{{ pageDetail.desc }}</p>
        <div fxLayout="row" class="page-share-wrapper">
          <ng-container *ngFor="let socialSare of socialShares">
            <a [url]="pageDetail.url" [shareButton]="socialSare.name" class="no-decor">
              <i [class]="socialSare.icon"></i>
            </a>
          </ng-container>
        </div>
        <div class="cards-container width-100">
          <ng-container *ngFor="let card of internalPages">
            <div class="cards width-100 overflow-hidden">
              <uni-image
                [src]="card.image"
                [width]="720"
                [height]="360"
                [alt]="card.title + ' image'"
                classes="height-100 width-100 fit-cover card-img"></uni-image>
              <a [href]="card.link" class="no-decor">
                <h2 class="absolute margin-0 text-center width-100">{{ card.title }}</h2>
              </a>
              <i class="icon-uni sparro-white"></i>
              <div fxLayout="row" class="card-share-icons-container">
                <a [url]="card.link" [shareButton]="'facebook'" class="no-decor">
                  <i class="icon-facebook-color sparro-white"></i>
                </a>
                <a [url]="card.link" [shareButton]="'twitter'" class="no-decor">
                  <i class="icon-twitter-square-fill sparro-white"></i>
                </a>
                <a [url]="card.link" [shareButton]="'linkedin'" class="no-decor">
                  <i class="icon-linkedIn-square-fill sparro-white"></i>
                </a>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
      <footer>
        <uni-public-footer [topMargin]="false"> </uni-public-footer>
      </footer>
    </main>
  </mat-drawer-content>
</mat-drawer-container>
