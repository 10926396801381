import { Component, OnInit, Input, ElementRef, ViewChild, HostListener } from '@angular/core';
import { User } from '@sparro/app/core/models/user.model';
import { BREAKPOINTS } from '@sparro/core/config/constants.config';
import { BreakpointObserver } from '@angular/cdk/layout';
import { MatDrawer } from '@angular/material/sidenav';
import { HeaderService } from '@sparro/app/core/api/header/header.service';
@Component({
  selector: 'app-uni-for',
  templateUrl: './uni-for.component.html',
  styleUrls: ['./uni-for.component.scss']
})
export class UniForComponent implements OnInit {
  @Input() user: User;
  @Input() internalPages: any = [];
  @Input() pageDetail: any = null;
  mobileView: boolean = false;
  leftSideNav: boolean = false;

  socialShares = [
    {
      name: 'facebook',
      icon: 'icon-facebook-color'
    },
    {
      name: 'twitter',
      icon: 'icon-twitter-square-fill'
    },
    {
      name: 'linkedin',
      icon: 'icon-linkedIn-square-fill'
    }
  ];
  showFloatingTitle: boolean = false;

  @ViewChild('title') title: ElementRef;
  @ViewChild('leftnav') leftnav: MatDrawer;

  constructor(
    public breakpointObserver: BreakpointObserver,
    private headerService: HeaderService
  ) {}

  ngOnInit(): void {
    this.instantiateBreakpoints();
  }

  instantiateBreakpoints() {
    this.breakpointObserver.observe([BREAKPOINTS.below.md]).subscribe(result => {
      this.mobileView = result.breakpoints[BREAKPOINTS.below.md];
    });
  }

  closeBackdrop(): void {
    this.leftSideNav = false;
    this.leftnav.toggle();
  }

  leftToggle(event): void {
    this.leftSideNav = true;
  }

  @HostListener('window:scroll', [])
  onWindowScroll(): void {
    const windowTop = window.pageYOffset;

    this.showFloatingTitle = Boolean(windowTop > this.title.nativeElement.offsetTop);
    this.headerService.setHeaderTitle(this.showFloatingTitle ? this.pageDetail.title : '');
  }
}
