<div
  fxLayout="column"
  fxLayoutAlign="center center"
  fxLayoutGap="64px"
  class="institution-group-section">
  <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="24px">
    <h2 class="title margin-0 font-bolder">Trusted by</h2>
    <p class="desc margin-0">
      Previously partnering institutions from all across the globe under Institutional Access
    </p>
  </div>

  <div class="icon-section-1">
    <div class="inner-section do-not-show-section-1">
      <img class="icon-container" [src]="institutesImgUrl[0]" [alt]="'institute-0'" />
    </div>
    <div class="inner-section">
      <ng-container *ngFor="let institute of institutesImgUrl.slice(1, 3); let i = index">
        <img class="icon-container" [src]="institute" [alt]="'institute' + i" />
      </ng-container>
    </div>
    <div class="inner-section">
      <ng-container *ngFor="let institute of institutesImgUrl.slice(3, 6); let i = index">
        <img class="icon-container" [src]="institute" [alt]="'institute' + i" />
      </ng-container>
    </div>
    <div class="inner-section do-not-show-section-2">
      <ng-container *ngFor="let institute of institutesImgUrl.slice(6, 10); let i = index">
        <img class="icon-container" [src]="institute" [alt]="'institute' + i" />
      </ng-container>
    </div>
    <div class="inner-section">
      <ng-container *ngFor="let institute of institutesImgUrl.slice(10, 13); let i = index">
        <img class="icon-container" [src]="institute" [alt]="'institute' + i" />
      </ng-container>
    </div>
    <div class="inner-section">
      <ng-container *ngFor="let institute of institutesImgUrl.slice(13, 15); let i = index">
        <img class="icon-container" [src]="institute" [alt]="'institute' + i" />
      </ng-container>
    </div>
    <div class="inner-section do-not-show-section-1">
      <img class="icon-container" [src]="institutesImgUrl[15]" alt="institute-15" />
    </div>
  </div>

  <div class="icon-section-2">
    <ng-container *ngFor="let institute of institutesImgUrl.slice(0, 14); let i = index">
      <img class="icon-container" [src]="institute" [alt]="'institute' + i" />
    </ng-container>
  </div>
</div>
