<ng-container *ngIf="!mobileView; else mobile">
  <div class="outer-wrapper" fxLayout="column" fxLayoutAlign="center center">
    <div *ngIf="disciplines"
      class="button-section"
      fxLayout="row wrap"
      fxLayoutAlign="center center"
      fxLayoutGap="16px">
      <ng-container *ngFor="let discipline of disciplines; let index = index">
        <button
          class="button"
          [ngClass]="{ 'active-button': index === selectedDisciplineIndex }"
          (click)="getFilteredCompetitions(discipline, index)">
          {{ discipline.name }}
        </button>
      </ng-container>
    </div>
    <div class="carousel">
      <button class="fab-button mat-mini-fab">
        <i class="icon-arrow-left-bold icon-arrow" (click)="moveCards('left')"></i>
      </button>
      <div fxLayout="row" class="cards-container" fxLayoutAlign="center center">
        <ng-container *ngIf="isLoading; else displayContent">
          <uni-loader></uni-loader>
        </ng-container>
        <ng-template #displayContent>
          <ng-container
            *ngIf="!screenBelowLarge && competitionsDisplayed?.length > 3; else smallView">
            <ng-container
              *ngFor="
                let competition of competitionsDisplayed;
                let index = index;
                let first = first;
                let last = last
              ">
              <uni-competition-card
                class="card"
                [ngClass]="{
                  'left-most-card': index == 0,
                  'left-to-center-card': index == 1,
                  'center-card': index == 2,
                  'right-to-center-card': index == 3,
                  'right-most-card': index == 4,
                  'move-right': moveRight == true && !last,
                  'move-left': moveLeft == true && !first
                }"
                [host]="index !=2 ? 'carousel' : ''"
                [competition]="competition">
              </uni-competition-card>
            </ng-container>
            <uni-competition-card
              class="card left-most-hidden-card"
              [ngClass]="{
                'move-right': moveRight == true
              }"
              [competition]="competitionsDisplayed[4]">
            </uni-competition-card>
            <uni-competition-card
              class="card right-most-hidden-card"
              [ngClass]="{
                'move-left': moveLeft == true
              }"
              [competition]="competitionsDisplayed[0]">
            </uni-competition-card>
          </ng-container>
          <ng-template #smallView>
            <ng-container
              *ngFor="
                let competition of competitionsDisplayed;
                let index = index;
                let first = first;
                let last = last
              ">
              <uni-competition-card
                class="card"
                [ngClass]="{
                  'left-to-center-card': index == 0 && !last,
                  'center-card': index == 1 || (last && first),
                  'right-to-center-card': index == 2,
                  'move-right': moveRight == true && !last,
                  'move-left': moveLeft == true && !first
                }"
                [host]="index == 1 || (last && first)  ? '' : 'carousel'"
                [competition]="competition">
              </uni-competition-card>
            </ng-container>
            <uni-competition-card
              class="card left-to-center-hidden-card"
              [ngClass]="{
                'move-right': moveRight == true
              }"
              [competition]="competitionsDisplayed[2]">
            </uni-competition-card>
            <uni-competition-card
              class="card right-to-center-hidden-card"
              [ngClass]="{
                'move-left': moveLeft == true
              }"
              [competition]="competitionsDisplayed[0]">
            </uni-competition-card>
          </ng-template>
        </ng-template>
      </div>
      <button class="fab-button mat-mini-fab" (click)="moveCards('right')">
        <i class="icon-arrow-right-bold icon-arrow"></i>
      </button>
    </div>
  </div>
</ng-container>
<ng-template #mobile>
  <div fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="40px">
    <div *ngIf="disciplines"
      fxLayout="row"
      class="drop-down-section"
      fxLayoutAlign="space-between center"
      fxLayoutGap="8px"
      fxFlex="30">
      <div>Category</div>
      <div class="drop-down">
        <mat-form-field class="width-100 sparro-grey-800" floatLabel="never">
          <mat-select [placeholder]="activeDiscipline">
            <ng-container *ngFor="let discipline of disciplines; let index = index">
              <mat-option
                [value]="discipline.name"
                (click)="getFilteredCompetitions(discipline, index)">
                {{ discipline.name }}
              </mat-option>
            </ng-container>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <ng-container *ngIf="!isLoading; else displayLoader">
      <ng-container *ngIf="competitionsDisplayed.length">
        <ng-container *ngFor="let competition of competitionsDisplayed.slice(0, 4)">
          <uni-competition-card [competition]="competition"> </uni-competition-card>
        </ng-container>
      </ng-container>
    </ng-container>
    <ng-template #displayLoader>
      <uni-loader *ngIf="isLoading"></uni-loader>
    </ng-template>
  </div>
</ng-template>
