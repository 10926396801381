<a [href]="getProfileUrl()" class="juror-card border rounded-top-3 rounded-bottom-3 no-decor">
  <uni-user-avatar
    [user]="juror.user"
    avatarClass="juror-image"
    shape="square"
    [viewProfile]="false">
  </uni-user-avatar>
  <div fxLayout="column" fxLayoutAlign="center center" class="padding-2" fxLayoutGap="16px">
    <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="5px">
        <h4 class="font-md-static margin-0">{{(juror.user.first_name + ' ' + juror.user.last_name) | truncate: 22 }}</h4>
        <ng-container *ngIf="juror?.bio">
          <p class="sparro-grey-700 font-xxs-static text-center margin-0">{{juror.bio | truncate: 60}}</p>
        </ng-container>
    </div>
    <ng-container *ngIf="juror?.desc">
      <p class="sparro-grey-800 font-sm-static text-center margin-0">{{juror.desc | truncate: 60}}</p>
    </ng-container>
    <ng-container *ngIf="juror?.competition">
      <a [href]="getCompetitionUrl(juror.competition)" class="font-bolder font-xxs-static no-decor-hover">
        {{juror.competition.title | truncate: 40}}
      </a>
    </ng-container>
  </div>
</a>
