import { FEATURES } from '@about/app/core/configs/landing.config';
import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UserService } from '@sparro/app/core/api/user/user.service';
import { BREAKPOINTS } from '@sparro/app/core/config/constants.config';
import { Competition } from '@sparro/app/core/models/competition.model';
import { User } from '@sparro/app/core/models/user.model';
import { FAQService } from '@sparro/app/core/api/faq/faq.service';
import { FAQ } from '@sparro/app/core/models/faq.model';
import { MetaDefinition } from '@angular/platform-browser';
import { MetaService } from '@sparro/app/core/helpers/meta/meta.service';

export class Features {
  title: string;
  description: string;
  icon?: string;
}

export class PackageOrPlan {
  title: string;
  price: number;
  unit: string;
  features: string[];
  buttonText: string;
  url: string;
  isPopular: boolean;
}

@Component({
  selector: 'app-institutional-access',
  templateUrl: './institutional-access.component.html',
  styleUrls: ['./institutional-access.component.scss']
})
export class InstitutionalAccessComponent implements OnInit {
  user: User;
  smallView: boolean = false;
  contributionForm: boolean = false;
  competitionLaunchForm: boolean = false;
  imgFrame = FEATURES.BUSINESS_PAGE;
  frequentlyAskedQuestions: FAQ[] = [];
  faqHeadingColor: string = '#B2101B';

  platformFeatures: Features[] = [
    {
      title: 'World-class design challenges',
      description: `IA offers a chance to push beyond limited classroom environments and work 
        on anything from real to idea-based problems on the global level.`,
      icon: 'https://uni-common-assets.s3.amazonaws.com/designers/institutional-access/icon-1.svg'
    },
    {
      title: 'Gain international industry experience',
      description: `As the gaps between the borders reduce, the design is no more a local 
        profession. Problems like these propel your work from local to global.`,
      icon: 'https://uni-common-assets.s3.amazonaws.com/designers/institutional-access/icon-2.svg'
    },
    {
      title: 'Exchange ideas & inspiration',
      description: `Gauge your skills on an international level. Get engaged in discussions 
        with our designer community from all across the globe.`,
      icon: 'https://uni-common-assets.s3.amazonaws.com/designers/institutional-access/icon-3.svg'
    },
    {
      title: 'Flexible participation modes',
      description: `Institutional access gives an exclusive right to participate in a group 
        or as an individual for up to 15 entries from your institution.`,
      icon: 'https://uni-common-assets.s3.amazonaws.com/designers/institutional-access/icon-4.svg'
    }
  ];

  subSections: Features[] = [
    {
      title: 'Discounted registeration fee for Institutions',
      description: `We encourage more institutions to take part in this collaborative environment, 
        therefore we offer a discounted fee than regular registrations. The entry fee for institutions 
        under IA is slashed upto 65%. This also reserves your seat in the competition of your choice irrespective of Static or Dynamic entry fee.`
    },
    {
      title: 'Verification process for Institution Access',
      description: `The registration for the IA should be made by the mentor only. Your verification can be established automatically through your university email ID. 
      In case the mentor is not using the university email ID to register for the competition, we will contact within 3 business days for a proof of verification such as University 
      identity or a letter of Authorization from the institution.`
    },
    {
      title: `15 Entries & 1 Mentor`,
      description: `IA offers youto register upto 15 entries with a group of 4 students in each entries to enter a competition with one faculty supervisor 
        (who mentors /coordinates this endeavor) to participate in one or more eligible competition of your choice..`
    },
    {
      title: `Adding and submitting your entries for the competition.`,
      description: `You can add a minimum of 1 and a maximum of 15 entries in a competition within a year from the date of purchase of Institutional membership. All the 
        entries will be supervised by 1 faculty member (this information cannot be changed later) <br><br> Submit 
        your entries using UNI’s submission portal before the submission deadline as specified in the brief.`
    },
    {
      title: `Creating team for your participation`,
      description: `Like working solo? You Can!<br><br> The combinations to participate can range from all students 
        working solo (Eg: 1 participant per entry) to all students in maximum size of team 
        permitted in competition (Eg. 4 participants per entry), or any custom structure of 
        teams, solo duo, trio. One student can be a part of multiple teams and can submit multiple entries as well. Students can create their account on UNI, build their profile and publish a project. 
        However to validate their participation in IA they will have to invite their mentor in the team.
        This also ensures that mentors can review the final work of their student before submission.
        <br><br>An institute can buy multiple passes to fit their entire classroom.`
    },
    {
      title: `What happens when we purchase multiple Institutional Access pass?`,
      description: `In case of multiple IA pass purchases, the process of registration and participation will 
        remain the same and you will be matched with your partner institution other than yours. <br><br>
        In case there are no other partner institutions your IA pass will remain intact and students 
        may participate in the competition as usual.`
    },
    {
      title: `What role does my partner institution play in the competition?`,
      description: `A partner institution will play the same role as yours. Students can use the competition discussion 
        channel and our Discord server to interact with each other while working on their projects. You and your partner 
        institution can interact with each other over the competition discussion channel. We will create and invite you on a dedicated Discord channel, only if there's a matching partner institution. <br><br> We offer 
        supervisors/mentors to evaluate and provide feedback on other projects (barring the projects submitted by their studensts) for parallel knowledge exchange.`
    },
    {
      title: `Partner institutions in case of solo IA registration`,
      description: `In case a competition receives only single IA registration then you will not have any 
        partner institution. However, students may participate in the competition as usual. Your institutional 
        access will remain intact and you can still submit up to 15 entries overall in one or more competition in a year from the date of registration.`
    },
    {
      title: `Get constructive inputs from the eminent jurors`,
      description: `All the entries submitted through this channel will also be transferred to the Lead jury panel 
        (like an individual participant entry) for the final reward as well.`
    },
    {
      title: `How many winners will be there in each institutional access?`,
      description: `Apart from the regular awards listed on the competition page, we also declare 1 institutional 
        excellence award coming from the particular institute, based on the combined score of mentors and lead 
        jurors (if applicable). Institutional excellence award will be given only if there are a minimum of 10 entries from the same institution in a competition.`
    }
  ];

  expanded: number[] = [];

  constructor(
    private userService: UserService,
    public breakpointObserver: BreakpointObserver,
    private faqService: FAQService,
    public metaService: MetaService
  ) {}

  ngOnInit(): void {
    this.updateMetaInfo();
    this.getAuthUser();
    this.initializeBreakpoints();
    this.getFaqs();
  }

  setActiveIndex(index: number) {
    this.expanded.includes(index)
      ? this.expanded.splice(this.expanded.indexOf(index), 1)
      : this.expanded.push(index);
  }

  getFaqs(): void {
    this.faqService.getFAQs('institutional-access').subscribe((response: FAQ[]) => {
      this.frequentlyAskedQuestions = response;
    });
  }

  getAuthUser(): void {
    this.userService.userInitialized.subscribe((initialized: boolean) => {
      if (initialized) {
        this.userService.user.subscribe((user: User) => {
          this.user = user;
        });
      }
    });
  }

  initializeBreakpoints(): void {
    this.breakpointObserver.observe([BREAKPOINTS.below.md]).subscribe(result => {
      this.smallView = Boolean(result.breakpoints[BREAKPOINTS.below.md]);
    });
  }

  updateMetaInfo(): void {
    const title = 'Institutional Access- A new world of academic collaboration.';
    const description = `Institutional Access is a new world of academic collaboration 
      that provides students with the challenges and opportunities they need to succeed. 
      We provide universities, the ability to connect and share resources, so that 
      everyone can benefit from the best and brightest minds. Join us today and help 
      change the way the architecture community learns!`;
    const cover = `	https://uni-common-assets.s3.amazonaws.com/designe…nstitutional-access/institutional-access-icon.svg`;
    const metaInfo: MetaDefinition[] = [
      { name: 'title', content: title },
      { name: 'description', content: description },
      { name: 'url', content: window.location.href },
      { name: 'image', content: cover },
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:title', content: title },
      { name: 'twitter:description', content: description },
      { name: 'twitter:url', content: window.location.href },
      { name: 'twitter:image', content: cover },
      { property: 'og:title', content: title },
      { property: 'og:description', content: description },
      { property: 'og:url', content: window.location.href },
      { property: 'og:image', content: cover },
      { property: 'og:image:alt', content: 'UNI- Design competitions' },
      { property: 'og:type', content: 'article' }
    ];
    metaInfo.forEach(meta => {
      this.metaService.updateMetaInfo(meta);
    });
    this.metaService.updateMeta(metaInfo);
    this.metaService.updateTitle(title);
    this.metaService.createCanonicalURL();
  }
}
