import { Component, ElementRef, OnInit, ViewChild, Input } from '@angular/core';
import { FAQService } from '@sparro/app/core/api/faq/faq.service';
import { Competition } from '@sparro/app/core/models/competition.model';
import { FAQ } from '@sparro/app/core/models/faq.model';

@Component({
  selector: 'app-page-bottom-section',
  templateUrl: './page-bottom-section.component.html',
  styleUrls: ['./page-bottom-section.component.scss']
})
export class PageBottomSectionComponent implements OnInit {
  @Input() smallView: boolean = false;
  @Input() extraSmallView: boolean = false;
  @Input() packages;
  @Input() footerData;
  @Input() host: string;
  @Input() demoUrl: string;
  @Input() competitions: Competition[] = [];
  frequentlyAskedQuestions: FAQ[] = [];
  faqHeadingColor: string = '#192999';

  @ViewChild('planCards') planCards: ElementRef;
  constructor(private faqService: FAQService) {}

  ngOnInit(): void {
    this.getFaqs();
  }

  getFaqs(): void {
    this.faqService.getFAQs(this.host).subscribe((response: FAQ[]) => {
      this.frequentlyAskedQuestions = response;
    });
  }
}
