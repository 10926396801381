import { Component, OnInit } from '@angular/core';
import { User } from '@sparro/app/core/models/user.model';
import { UserService } from '@sparro/app/core/api/user/user.service';
import { BreakpointObserver } from '@angular/cdk/layout';
import { BREAKPOINTS } from '@sparro/app/core/config/constants.config';
import { FAQService } from '@sparro/api/faq/faq.service';
import { FAQ } from '@sparro/app/core/models/faq.model';
import { FEATURES } from '@about/app/core/configs/landing.config';
import { MetaDefinition } from '@angular/platform-browser';
import { MetaService } from '@sparro/app/core/helpers/meta/meta.service';
import { CompetitionService } from '@about/app/core/api/competition/competition.service';
import { Competition } from '@sparro/app/core/models/competition.model';

export class Stats {
  count: string;
  description: string;
}

export class ImageFrame {
  host: string[];
}

export class CommentModel {
  comment: string;
  avatar_url: string;
  bio: string;
  first_name: string;
  last_name: string;
}

@Component({
  selector: 'app-membership',
  templateUrl: './membership.component.html',
  styleUrls: ['./membership.component.scss']
})
export class MembershipComponent implements OnInit {
  user: User;
  smallView: boolean = false;
  contributionForm: boolean = false;
  competitionLaunchForm: boolean = false;
  imgFrame = FEATURES.DESIGNERS_MEMBERSHIP;
  FAQs: FAQ[] = [];
  competitions: Competition[] = [];
  headingColor: string = '#016358';
  statData: Stats[] = [
    {
      count: '1000+ Members',
      description: 'Enjoy UNI membership access to exclusive content and competitions.'
    },
    {
      count: '10000+ Projects',
      description: 'Explore unlimited exclusive design concepts and details from award-winning designers.'
    },
    {
      count: '500+ Challenges',
      description:
        'Issue based architectural challenges launched every year in 20+ different typologies with growing number every month.'
    }
  ];

  constructor(
    private userService: UserService,
    public breakpointObserver: BreakpointObserver,
    private faqService: FAQService,
    public metaService: MetaService,
    private competitionService: CompetitionService
  ) {}

  ngOnInit(): void {
    this.updateMetaInfo();
    this.getAuthUser();
    this.initializeBreakpoints();
    this.getFAQs();
    this.getCompetitions();
  }

  getAuthUser(): void {
    this.userService.userInitialized.subscribe((initialized: boolean) => {
      if (initialized) {
        this.userService.user.subscribe((user: User) => {
          this.user = user;
        });
      }
    });
  }

  getCompetitions(): void {
    this.competitionService.getMembershipCompetitions().subscribe((response: any) => {
      this.competitions = response.results;
    });
  }

  initializeBreakpoints(): void {
    this.breakpointObserver.observe([BREAKPOINTS.below.md]).subscribe(result => {
      this.smallView = Boolean(result.breakpoints[BREAKPOINTS.below.md]);
    });
  }

  getFAQs() {
    this.faqService.getFAQs('designers-membership').subscribe(
      (response: FAQ[]) => {
        this.FAQs = response;
      },
      error => console.log(error)
    );
  }

  updateMetaInfo(): void {
    const title = 'UNI Membership - The new way to join the community of thousands of Architects';
    const description = `UNI Membership is the new way to engage with the community of thousands of Architects. 
      With UNI Membership, you'll get access to our competitions, awards, courses, and yearbooks. Showcase your 
      work and get feedback from experts. Sign up today!`;
    const cover = `https://uni-common-assets.s3.amazonaws.com/designers/membership/futurly.svg`;
    const metaInfo: MetaDefinition[] = [
      { name: 'title', content: title },
      { name: 'description', content: description },
      { name: 'url', content: window.location.href },
      { name: 'image', content: cover },
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:title', content: title },
      { name: 'twitter:description', content: description },
      { name: 'twitter:url', content: window.location.href },
      { name: 'twitter:image', content: cover },
      { property: 'og:title', content: title },
      { property: 'og:description', content: description },
      { property: 'og:url', content: window.location.href },
      { property: 'og:image', content: cover },
      { property: 'og:image:alt', content: 'UNI- Design competitions' },
      { property: 'og:type', content: 'article' }
    ];
    metaInfo.forEach(meta => {
      this.metaService.updateMetaInfo(meta);
    });
    this.metaService.updateMeta(metaInfo);
    this.metaService.updateTitle(title);
    this.metaService.createCanonicalURL();
  }
}
