<div class="main-container">
  <div class="cover-wrapper">
    <div class="cover-container" fxLayout="column">
      <app-landing-page-header [smallView]="smallView" [user]="user" [blackLogo]="false">
      </app-landing-page-header>
      <div class="content-container" fxLayout="column" fxLayoutAlign="space-between">
        <div class="main-content">
          <div class="text-section" fxLayout="column">
            <div class="title-section">
              <h1 class="title margin-0">Become a curator</h1>
            </div>
            <p class="subtitle margin-0">
              Frame the next big design problem for UNI’s community of Architects & Designers
            </p>
          </div>
          <div class="img-section relative">
            <uni-image
              classes="absolute cover-img-center"
              alt="cover-img-center"
              src="https://uni-common-assets.s3.amazonaws.com/experts/curator/cover-img.svg">
            </uni-image>
          </div>
        </div>
        <div class="sub-topic-cover-content">
          <div class="wrapper" fxLayout="column">
            <div class="text-section width-100 margin-auto" fxLayout="column">
              <div class="title-section">
                <h2 class="title margin-0">What is a curator on UNI?</h2>
              </div>
              <p class="subtitle margin-0">
                A Lead Curator at Uni frames a design challenge opportunity that is not only
                relevant but resourceful as a learning exercise for its participants. It enables the
                curator to fuse a purposeful problem with her/his/their expertise into a time-bound
                event for students and young professionals globally.
              </p>
            </div>
            <div class="width-100" fxLayout="row" fxLayoutAlign="center center">
              <div class="card-container">
                <ng-container *ngFor="let card of platformFeatures">
                  <div class="platform-feature-card" fxLayout="column" fxLayoutGap="40px">
                    <uni-image [src]="card.icon" alt="card-icon" classes="big-dot"></uni-image>
                    <div
                      class="card-text-section"
                      fxLayout="column"
                      fxLayoutAlign="center"
                      fxLayoutGap="24px">
                      <h4 class="title margin-0">{{ card.title }}</h4>
                      <p class="description margin-0">{{ card.description }}</p>
                    </div>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="detail-section">
    <div fxLayout="column" fxLayoutAlign="center center" class="heading-section">
      <h4 class="margin-0 title sparro-grey-900">Why to become a curator on UNI?</h4>
      <p class="subtitle margin-0">
        As a curator at UNI, you empower other designers and the entire creative community to come
        up with solutions to some of the most challenging problems in the world identified by you.
        We invite you to bring out these purpose-driven design problems, which can bring a colossal
        change to how we designers work in general and bring change for the betterment of society.
      </p>
    </div>
    <div class="inner-container">
      <div class="sub-section">
        <div
          class="text-section"
          fxLayout="column"
          fxLayoutAlign="space-between start"
          fxLayoutGap="24px">
          <h3 class="title margin-0">Increase your visibility on our platform</h3>
          <p class="description margin-0 sparro-grey-800">
            As a curator you get featured throughout our platform and in the competition you
            curated. You can build your profile and get a verified badge on our platform along with
            an exclusive feature page for curators.
          </p>
        </div>
        <div class="relative image-section">
          <uni-image
            class="section-1-bg absolute"
            alt="section-1-bg"
            src="https://uni-common-assets.s3.amazonaws.com/experts/curator/section-1-bg.svg"
            classes="fit-cover width-100 height-100"></uni-image>
          <uni-image
            class="card-1 absolute frame"
            alt="card-img"
            src="https://uni-common-assets.s3.amazonaws.com/experts/curator/comment-card-1.svg"
            classes="fit-cover width-100 height-100"></uni-image>
          <uni-image
            class="card-2 absolute frame"
            alt="comment-card-img"
            src="https://uni-common-assets.s3.amazonaws.com/experts/curator/comment-card-2.svg"
            classes="fit-cover width-100 height-100"></uni-image>
        </div>
      </div>
      <div class="sub-section odd-section">
        <div class="relative image-section">
          <uni-image
            class="section-2-bg absolute"
            alt="section-2-bg-img"
            src="https://uni-common-assets.s3.amazonaws.com/experts/curator/section-2-bg.svg"
            classes="fit-cover width-100 height-100"></uni-image>
        </div>
        <div
          class="text-section"
          fxLayout="column"
          fxLayoutAlign="space-between start"
          fxLayoutGap="24px">
          <h3 class="title margin-0">Work at your own pace</h3>
          <p class="description margin-0 sparro-grey-800">
            A good brief takes 20-30 hrs on an average spread over 3 weeks. However there’s no time
            limit and you can work at your own pace. We recommend spending a fair share of time
            doing background research to have strong narrative.
          </p>
        </div>
      </div>
      <div class="sub-section">
        <div
          class="text-section"
          fxLayout="column"
          fxLayoutAlign="space-between start"
          fxLayoutGap="24px">
          <h3 class="title">Assistance throughout the curation process</h3>
          <div class="description margin-0 sparro-grey-800">
            We assist in all way along the curation process. From helping you getting started to
            shaping your ideas to reaching the end goal of launching, we’ve got your back. <br />We
            are here to help you out with
            <ul>
              <li class="font-sm font-bold sparro-grey-900">Identifying suitable site</li>
              <li class="font-sm font-bold sparro-grey-900">Additional resources</li>
              <li class="font-sm font-bold sparro-grey-900">Creative inputs</li>
              <li class="font-sm font-bold sparro-grey-900">Spreading the word</li>
            </ul>
          </div>
        </div>
        <div class="relative image-section">
          <uni-image
            class="section-3-bg absolute"
            alt="section-3-bg"
            src="https://uni-common-assets.s3.amazonaws.com/experts/curator/section-3-bg.svg"
            classes="fit-cover width-100 height-100"></uni-image>
          <uni-image
            class="frame pdf-reader absolute"
            alt="pdf-reader"
            src="https://uni-common-assets.s3.amazonaws.com/experts/curator/pdf-reader.svg"
            classes="fit-cover width-100 height-100"></uni-image>
        </div>
      </div>
      <div class="sub-section odd-section">
        <div class="relative image-section">
          <uni-image
            class="section-4-bg absolute"
            alt="section-4-bg-img"
            src="https://uni-common-assets.s3.amazonaws.com/experts/curator/section-4-bg.svg"
            classes="fit-cover width-100 height-100"></uni-image>
        </div>
        <div
          class="text-section"
          fxLayout="column"
          fxLayoutAlign="space-between start"
          fxLayoutGap="24px">
          <h3 class="title margin-0 width-100">Remuneration for curation</h3>
          <p class="description margin-0 sparro-grey-800">
            We offer 10% of the registration fee/sponsorship amount to our curators at the end of
            every quarter as remuneration. In the past curators have earned up to 750 USD as a
            curator of a single challenge and an average of ~400 USD.
          </p>
        </div>
      </div>
    </div>
  </div>

  <div
    fxLayout="column"
    fxLayoutAlign="center center"
    fxLayoutGap="64px"
    class="how-it-work-section">
    <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="24px">
      <h2 class="margin-0 heading">Create revolution through your words</h2>
      <span class="subtitle">How it works?</span>
    </div>
    <div class="inner-section">
      <ng-container *ngFor="let step of workingSteps">
        <div
          fxLayout="column"
          fxLayoutAlign="center center"
          fxLayoutGap="24px"
          class="card-container">
          <uni-image [src]="step.icon" alt="steps-icon" classes="steps-icon"></uni-image>
          <h4 class="margin-0 title">{{ step.title }}</h4>
          <p class="margin-0 subtitle">{{ step.description }}</p>
        </div>
      </ng-container>
    </div>
    <div class="button-section">
      <div class="cursor-pointer button font-bolder" (click)="openForm()">Get started</div>
    </div>
    <div class="form-section" *ngIf="formOpened">
      <div class="button-section">
        <button class="fab-button mat-mini-fab" (click)="close()">
          <i
            [ngClass]="{
              'icon-arrow-down-bold': smallView,
              'icon-arrow-right-bold': !smallView
            }"></i>
        </button>
      </div>
      <div class="iframe-container">
        <iframe
          allowtransparency="true"
          allowfullscreen="true"
          allow="geolocation; microphone; camera"
          src="https://forms.gozen.io/P8tSGc2xWBy1sBV7LHNp"
          frameborder="0">
        </iframe>
      </div>
    </div>
  </div>

  <uni-frequent-questions [headingColor]="faqHeadingColor" [FAQs]="frequentlyAskedQuestions">
  </uni-frequent-questions>
  <div class="banner-section">
    <div class="text-section margin-auto" fxLayout="column" fxLayoutAlign="center center">
      <h2 class="title margin-0">Heads up!! There are more ways to contribute on UNI</h2>
      <div class="button-section">
        <a [href]="getMentorPageUrl()" class="no-decor cursor-pointer button font-bolder">
          Become a Mentor
        </a>
        <a [href]="getJurorPageUrl()" class="no-decor cursor-pointer button font-bolder">
          Become a Juror
        </a>
      </div>
    </div>
  </div>
</div>
<uni-public-footer [topMargin]="false"></uni-public-footer>
