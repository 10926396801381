<div style="background-color: #fcfcfc">
  <app-landing-page-header [smallView]="mobileView" [blackLogo]="true" [user]="user">
  </app-landing-page-header>
  <div class="main-sec">
    <p class="margin-0 text-center font-bolder tl-sec">
      Join us in shaping the future with the <br />
      UNI-Fund for Innovation & Design
    </p>
    <div>
      <div class="lt-cont relative">
        <div class="lt-sec">
          <p class="margin-0 lt-content">
            <span class="lt-main-content">
              Dear <span class="font-bolder">UNI Community</span>,
            </span>
            <br />
            <br />
            We hope this message finds you well.
            <br />
            <br />
            Our platform, UNI, has always been a space driven by the passionate involvement and
            shared creativity of our extraordinary community of architects and designers. Today,
            we're reaching out with a crucial initiative that invites your participation and
            support.
            <br />
            <br />
            We are excited to announce the launch of the
            <span class="font-bolder">"UNI-Fund for Innovation & Design (UNIFID)."</span> This
            initiative is more than a fundraiser; it's a commitment to the future of design and a
            call for collective action to build and expand our innovation reward pool for ongoing
            Awards and Competitions on UNI.
            <br />
            <br />
            The "UNIFID Rewards pool" aims to bolster our collective pursuit of creativity and
            innovation, providing designers with the motivation and recognition they deserve. We
            believe that, with your support, we can offer more opportunities to our community
            members and stimulate the essence of what uni.xyz stands for - fostering innovation and
            design excellence.
            <br />
            <br />
            In line with our philosophy of shared ownership and decision-making, we are exploring
            the creation of a
            <a
              href="https://en.wikipedia.org/wiki/Decentralized_autonomous_organization"
              target="_blank"
              class="sparro-grey-900 font-bolder">
              Decentralized Autonomous Organization (DAO)<i
                class="icon-open-in-new-tab-bold sparro-blue-600"></i>
            </a>
            , giving our community the power to influence the direction of our platform.
            <br />
            <br />
            We are committed to making this journey transparent and inclusive. For this purpose, we
            provide regular updates on our dedicated
            <a
              href="https://discord.com/invite/zWSfz2AtfQ"
              target="_blank"
              class="sparro-grey-900 font-bolder">
              Discord channel<i class="icon-open-in-new-tab-bold sparro-blue-600"></i> </a
            >. We welcome you to join, follow our progress, share your thoughts, and engage with the
            broader community.
            <br />
            <br />
            As part of this campaign, we are offering a range of services in return for your
            generous contributions. If you choose not to avail the services within one year, we will
            issue credits of an equivalent amount for future use on our platform or other associated
            services.
            <br />
            <br />
            With the <span class="font-bolder">"UNI-Fund for Innovation & Design (UNIFID)"</span> we
            invite you to invest in a future powered by design, innovation, and creativity. This is
            a unique opportunity to actively shape the next version of uni.xyz and ensure that our
            platform continues to be a thriving space for emerging architects and designers
            globally.
            <br />
            <br />
            I understand these are challenging times, and any support you can provide will be
            immensely valuable. Your contribution will benefit not only us but also the countless
            designers who gain from our platform and competitions.
            <br />
            <br />
            Support us today, spread the word, and join us in this collective journey of creating a
            brighter, more innovative future for the design community.
            <br />
            Thank you for your continued support and belief in our mission.
          </p>

          <img
            src="https://uni-common-assets.s3.amazonaws.com/contribute-to-unifid/signature1.svg"
            alt=""
            class="sign-img1" />
          <p class="margin-0 lt-content">
            Best regards,
            <br />
            <span class="font-bolder">Abhishek Sorampuri</span>
            <br />
            Co-Founder & CEO
            <br />
            <br />
            Connect with me on
          </p>
          <div class="lt-social-sec" fxLayout="row">
            <ng-container *ngFor="let socialShare of socialShares">
              <a [href]="socialShare.url" target="_blank">
                <img [src]="socialShare.icon" alt="social-icon" />
              </a>
            </ng-container>
          </div>
        </div>

        <div class="absolute dt-top-sec" fxLyaout="row" fxLayoutAlign="center center">
          <div class="dot-top-left"></div>
          <p class="margin-0 tl">UNIFID</p>
          <div class="dot-top-right"></div>
        </div>
        <div class="absolute club-rgt">
          <img
            src="https://uni-common-assets.s3.us-east-1.amazonaws.com/contribute-to-unifid/clubs.svg"
            alt="club-img" />
        </div>
        <div class="absolute club-lft">
          <img
            src="https://uni-common-assets.s3.us-east-1.amazonaws.com/contribute-to-unifid/clubs.svg"
            alt="club-img" />
        </div>
        <div class="absolute dt-btm-sec" fxLyaout="row" fxLayoutAlign="center center">
          <div class="dot-btm-left"></div>
          <img
            src="https://uni-common-assets.s3.us-east-1.amazonaws.com/contribute-to-unifid/thank_you.svg"
            class="margin-0 thank-svg" />
          <div class="dot-btm-right"></div>
        </div>
      </div>
    </div>

    <div class="card-cont" fxLayout="column">
      <ng-container *ngIf="poolDetail">
        <div class="card-sec" fxLayout="column">
          <p class="tl margin-0 font-bolder text-center">
            UNIFID Rewards Pool - {{ poolDetail.month_year }}
          </p>
          <p class="margin-0 sb-tl sparro-grey-800">
            The contributions to the UNIFID pool will invigorate our award system, directly funding
            the prizes for both current and upcoming competitions on UNI. By participating, you're
            not only enhancing the creative endeavors of our community but also shaping the
            competitive landscape, making each victory even more rewarding.
          </p>
          <div fxLayout="column" fxLayoutGap="32px" class="pool-sec">
            <div fxLayout="column" fxLayoutGap="16px">
              <p class="margin-0 amt">${{ poolDetail.received_amount }}</p>
              <div class="bar-cont">
                <div class="bar" [ngStyle]="{ width: '' + barPercent + '%' }"></div>
              </div>
              <p class="margin-0 meta">{{ barPercent }}% of ${{ poolDetail.threshold_amount }}</p>
            </div>

            <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="24px">
              <div fxLayout="column" fxLayoutGap="8px">
                <p class="margin-0 num-meta font-bolder">
                  {{ poolDetail.day_left >= 1 ? poolDetail.day_left : '0' }}
                </p>
                <p class="margin-0 meta">{{ poolDetail.day_left > 1 ? 'days' : 'day' }} left</p>
              </div>
              <div fxLayout="column" fxLayoutGap="8px">
                <p class="margin-0 num-meta font-bolder">{{ poolDetail.backers }}</p>
                <p class="margin-0 meta">backers</p>
              </div>
            </div>
          </div>
        </div>
      </ng-container>

      <div class="card-sec" fxLayout="column">
        <p class="tl margin-0 font-bolder text-center">Whitepaper</p>
        <p class="margin-0 sb-tl sparro-grey-800">
          We're nearing a key juncture at UNI.xyz with our upcoming future roadmap and whitepaper
          set to release this quarter. This path is a shared one - our community's thoughts and
          engagement are vital. We're introducing 'UNIFID' (UNI Fund for Innovation and Design), and
          invite you to join discussions on our Discord channel. More details about contributing to
          UNIFID and the associated benefits will be shared soon.
          <br />
          <br />
          Let's shape UNI.xyz's future, together.
        </p>

        <a href="https://discord.gg/zWSfz2AtfQ" target="_blank" class="join-btn-cont no-decor">
          <div class="join-btn">
            <img
              src="https://uni-common-assets.s3.amazonaws.com/icons/social/discord/color_48x48.svg"
              alt="club-img"
              height="20px" />
            <p class="margin-0 txt font-bold">Join us on Discord</p>
          </div>
        </a>
      </div>

      <div class="card-sec" fxLayout="column">
        <p class="tl margin-0 font-bolder text-center">How to contribute?</p>
        <p class="margin-0 sb-tl sparro-grey-800">
          Contributing to UNIFID (UNI Fund for Innovation and Design) is straightforward. If you're
          a business, you have the options to host, list, or sponsor a competition. As a
          participant, you can opt for a UNI membership. In the case that these services aren't
          utilized within a year, we'll issue equivalent credits for future use on our platform or
          other associated services. More details about each contribution method will be shared
          soon. Your support is invaluable in shaping the future of UNI.xyz.
        </p>
        <div class="link-sections">
          <ng-container *ngFor="let aboutPage of aboutPages">
            <a
              [href]="aboutPage.url"
              target="_blank"
              [class]="aboutPage.color + ' no-decor cont-btn sparro-grey-800'">
              <span class="width-100">{{ aboutPage.title }}</span>
            </a>
          </ng-container>
        </div>
      </div>
    </div>

    <app-designer-pricing></app-designer-pricing>

    <app-pricing host="list-competition"></app-pricing>
    <app-pricing host="sponsor-competition"></app-pricing>
    <app-pricing host="host-competition"></app-pricing>

    <uni-frequent-questions [headingColor]="faqHeadingColor" [FAQs]="FAQs">
    </uni-frequent-questions>
  </div>

  <uni-public-footer [topMargin]="false"></uni-public-footer>
</div>

