import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AboutComponent } from './about/about.component';
import { MembershipComponent } from './designers/membership/membership.component';
import { PortfolioComponent } from './designers/portfolio/portfolio.component';
import { InstitutionalAccessComponent } from './designers/institutional-access/institutional-access.component';
import { HostComponent } from './businesses/host/host.component';
import { ListComponent } from './businesses/list/list.component';
import { SponsorComponent } from './businesses/sponsor/sponsor.component';
import { PeopleComponent } from './people/people.component';
import { JurorComponent } from './experts/juror/juror.component';
import { MentorComponent } from './experts/mentor/mentor.component';
import { CuratorComponent } from './experts/curator/curator.component';
import { BusinessPagesComponent } from './businesses/business-pages/business-pages.component';
import { ExpertPagesComponent } from './experts/expert-pages/expert-pages.component';
import { DesignerPagesComponent } from './designers/designer-pages/designer-pages.component';
import { ContributionComponent } from './contribution/contribution.component';

const routes: Routes = [
  {
    path: '',
    component: AboutComponent,
    children: [
      {
        path: 'jurors',
        component: PeopleComponent
      },
      {
        path: 'designers',
        component: DesignerPagesComponent
      },
      {
        path: 'designers/membership',
        component: MembershipComponent
      },
      {
        path: 'designers/portfolio',
        component: PortfolioComponent
      },
      {
        path: 'designers/institutional-access',
        component: InstitutionalAccessComponent
      },
      {
        path: 'business',
        component: BusinessPagesComponent
      },
      {
        path: 'business/list',
        component: ListComponent
      },
      {
        path: 'business/host',
        component: HostComponent
      },
      {
        path: 'business/sponsor',
        component: SponsorComponent
      },
      {
        path: 'experts',
        component: ExpertPagesComponent
      },
      {
        path: 'experts/juror',
        component: JurorComponent
      },
      {
        path: 'experts/mentor',
        component: MentorComponent
      },
      {
        path: 'experts/curator',
        component: CuratorComponent
      },
      {
        path: 'contribute-to-unifid',
        component: ContributionComponent
      },
      {
        path: '',
        redirectTo: 'jurors'
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AboutRoutingModule {}
