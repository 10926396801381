import { Component, OnInit } from '@angular/core';
import { MetaDefinition } from '@angular/platform-browser';
import { MetaService } from '@sparro/app/core/helpers/meta/meta.service';
import { environment } from '@about/env/environment';
import { BreakpointObserver } from '@angular/cdk/layout';
import { BREAKPOINTS } from '@sparro/app/core/config/constants.config';
import { ImageOptimizerService } from '@sparro/app/core/helpers/image-optimizer';
import { Competition } from '@sparro/app/core/models/competition.model';
import { CompetitionService } from '@about/app/core/api/competition/competition.service';
import { FEATURES } from '@about/app/core/configs/landing.config';

export class Features {
  title: string;
  description: string;
  icon?: string;
}

export class PackageOrPlan {
  title: string;
  price: number;
  unit: string;
  features: string[];
  buttonText: string;
  url: string;
  popular?: boolean;
}

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent implements OnInit {
  platformFeatures: Features[] = [
    {
      title: 'A competitive community',
      description: `UNI has a collective audience of more than 250k designers from around the globe 
        that are solving exciting design exercises everyday on our platform.`,
      icon: 'https://uni-common-assets.s3.amazonaws.com/businesses/list/icon-1.svg'
    },
    {
      title: 'User-friendly interface',
      description: `Our listing interface is one of the most comprehensively designed web layouts 
        for launching competitions with a very user friendly interface.`,
      icon: 'https://uni-common-assets.s3.amazonaws.com/businesses/list/icon-2.svg'
    },
    {
      title: 'Ad-free, dedicated competition page',
      description: `List your competition on UNI for a dedicated, ad-free page, providing a clean, 
        distraction-free environment for enhanced visibility and participant interaction.`,
      icon: 'https://uni-common-assets.s3.amazonaws.com/businesses/list/icon-3.svg'
    }
  ];

  packages: PackageOrPlan[] = [
    {
      title: 'Weekly',
      price: 20,
      unit: 'per competition',
      features: [
        'Competition featured on UNI homepage banner for <b>7 days</b>',
        'Competition featured in <b>1 weekly newsletter</b>',
        '<b>1 Shoutout</b> on our Social Media Accounts'
      ],
      buttonText: 'Select weekly',
      url: `https://uni.xyz/payment/products/c82be7c9-18e8-4070-8a9d-76f446e017bc`
    },
    {
      title: 'Monthly',
      price: 75,
      unit: 'per competition',
      features: [
        'Competition featured on UNI homepage banner for <b>30 days</b>',
        'Competition featured in <b>2 weekly newsletters</b>',
        '<b>3 Shoutouts</b> on our Social Media Accounts'
      ],
      buttonText: 'Select monthly',
      url: `https://uni.xyz/payment/products/e16e0bb0-2a26-4b31-90cf-22f3356596bc`,
      popular: true
    },
    {
      title: 'Perennial',
      price: 150,
      unit: 'per competition',
      features: [
        'Competition featured on UNI homepage banner for <b>90 days</b>',
        'Competition featured in <b>6 weekly newsletters</b>',
        '<b>10 Shoutouts</b> on our Social Media Accounts',
        '1 Featured blog post on UNI',
        '3 Deadline alerts to all subscribers',
        '<b>2 Blogs</b> (Pre Result and Post Result)'
      ],
      buttonText: 'Select perennial',
      url: `https://uni.xyz/payment/products/397b3245-d355-4e4c-938a-84af1e80aa0d`
    }
  ];

  data = {
    extraInfo: {
      pageTitle: `List your competition on UNI`,
      pageSubtitle: `Designers on UNI are keen to solve the most radical design problems in the world. 
        They are designing the brand strategy for corporates and are also solving the congestion in cities.`,
      coverImage: `https://uni-common-assets.s3.amazonaws.com/businesses/cover/competitionDetailImage.svg`,
      title: `Why list on UNI?`,
      subtitle: `UNI is a rich and diverse community of creative people. A pool of award-winning designers 
        who always look for challenging real-world problems to solve. Get visibility of your competition 
        among 1 Million annual visitors on our platform.`
    },
    sections: [
      {
        title: `Register your organization to access competition studio`,
        subtitle: `In order to list your competition on UNI you will need to provide basic details of 
          your organization and get verified once by UNI.`,
        panelData: [
          {
            title: 'How long does the verification process take?',
            description: `Usually, the verification process starts once you have completed all the details of 
              your competition. It may take up to 2 business days to complete the verification once you mark it 
              ready to publish. The verification of your organization is one-time only. Once verified, you may 
              publish competitions on your own in future. However we may still do a background check of the 
              published competitions to meet our community guidelines & standards.`,
            expanded: false
          },
          {
            title: 'What is competition studio? ',
            description: `Competition studio is our flagship product for listing/hosting competition. It has 
              all the necessary details such as prize module, competition schedule, automated alerts, jury 
              evaluation portal, a transparent analytics & feedback system and a lot more. It has been carefully 
              crafted to meet your need so that you can list/host your competition on our platform in just few clicks.`,
            expanded: false
          },
          {
            title: `How do I register my Organization?`,
            description: `To register your organization you will have to first create an account on UNI and then 
              create an organization under the “manage my organization” section. Enter basic details about your 
              organization, e.g. Registered business name, short bio, website, email address, and logo. We will 
              verify these details before listing your competition on our platform.`,
            expanded: false
          }
        ]
      },
      {
        title: `Add competition details schedule & fees structure`,
        subtitle: `Provide basic details of your competition, e.g. name, title, tags, etc, and create a schedule 
          of the competition. You may also add fee structure for the competition.`,
        panelData: []
      },
      {
        title: `Add competition brief and additional resources`,
        subtitle: `Use our rich text editor to publish competition brief, upload additional resources, add 
          award structure, jurors and partners of the competition. `,
        panelData: [
          {
            title: `Is it mandatory to provide all the details for listing the competition?`,
            description: `We recommend organizers provide all the necessary details of the competition for participants. 
              Following are the minimum necessary details one has to provide before launching the competition: <br>
              <ul>
              <li>Competition brief</li>
              <li>Location</li>
              <li>Schedule</li>
              <li>Entry fee details</li>
              <li>Awards detail</li>
              <li>Competition Rules</li>
              <li>Jury Member (if any, not mandatory)</li>
              </ul>`,
            expanded: false
          },
          {
            title: `Can we change competition details after listing?`,
            description: `No. Once the competition has been approved and listed, you cannot change the details of the competition. 
              However, you can add any update/announcement of the competition under the discussion section of the competition.`,
            expanded: false
          }
        ]
      },
      {
        title: `Publish the competition`,
        subtitle: `Once published, your competition will go through a regular verification process 
          internally before being made live on our platform for public viewing`,
        panelData: [
          {
            title: `How can I feature my competition on UNI?`,
            description: `Although listing your competition is free on UNI, however to feature your competition on UNI 
              you may choose one of the promotional plan to promote your listed competition on UNI. UNI gives you the 
              access to reach it’s community of 200,000 members who are constantly looking for design challenges to participate.`,
            expanded: false
          },
          {
            title: `Is there a limit to list the number of competition on UNI?`,
            description: `No, there is no limit to the number of competitions you can list on UNI, 
              provided you have added all the mandatory details of the competition.`,
            expanded: false
          },
          {
            title: `How long does the verification process take?`,
            description: `Once you have added all the mandatory details of the competition, it may take up to 2-3 business 
              days before your competition goes live. For those who list these competitions regularly the approval could be 
              instant. We will also send you a notification if any other necessary/missing details are required.`,
            expanded: false
          }
        ]
      }
    ],
    footer: {
      title: `Don’t have a competition to list?`,
      links: [
        {
          url: `${environment.aboutUrl}about/business/host`,
          text: `Host a competition`
        },
        {
          url: `${environment.aboutUrl}about/business/sponsor`,
          text: `Sponsor a competition`
        }
      ]
    },
    demoUrl: `https://tidycal.com/unixyz/list-a-competition`
  };
  faqHeadingColor: string = '#192999';
  competitions: Competition[] = [];
  smallView: boolean = false;
  mobileView: boolean = false;
  extraSmallView: boolean = false;
  imgFrame = FEATURES.BUSINESS_PAGE;
  host: string = 'list-competition';

  constructor(
    public breakpointObserver: BreakpointObserver,
    private imageOptimization: ImageOptimizerService,
    private competitionService: CompetitionService,
    public metaService: MetaService
  ) {}

  ngOnInit(): void {
    this.updateMetaInfo();
    this.initializeBreakpoints();
    this.getFeaturedCompetitions();
  }

  setActiveIndex(index1: number, index2: number) {
    this.data.sections[index1].panelData[index2].expanded = this.data.sections[index1].panelData[
      index2
    ].expanded
      ? false
      : true;
  }

  getFeaturedCompetitions(): void {
    this.competitionService.getListedCompetitions().subscribe((response: any) => {
      this.competitions = response.results;
    });
  }

  initializeBreakpoints(): void {
    this.breakpointObserver
      .observe([BREAKPOINTS.below.lg, BREAKPOINTS.below.md, BREAKPOINTS.below.sm])
      .subscribe(result => {
        this.smallView = Boolean(result.breakpoints[BREAKPOINTS.below.lg]);
        this.mobileView = Boolean(result.breakpoints[BREAKPOINTS.below.md]);
        this.extraSmallView = Boolean(result.breakpoints[BREAKPOINTS.below.sm]);
      });
  }

  optimizedFrameImage(image: string): string {
    return this.renderImage(image, 493, 326);
  }

  renderImage(image, width?, height?): string {
    return this.imageOptimization.renderImage(image, width, height);
  }

  updateMetaInfo(): void {
    const title = 'List your Design competitions on UNI';
    const description = `Looking for a way to get your architecture or design competition 
      noticed? UNI offers a listing service for competitions that gets your competition in 
      front of it’s community of Architects & Designers. With UNI, you can list your 
      competition on our site and reach a wider audience of potential participants. Plus, 
      our listing service is free of charge. So why not give us a try?`;
    const cover = `https://uni-common-assets.s3.amazonaws.com/businesses/Cover.svg`;
    const metaInfo: MetaDefinition[] = [
      { name: 'title', content: title },
      { name: 'description', content: description },
      { name: 'url', content: window.location.href },
      { name: 'image', content: cover },
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:title', content: title },
      { name: 'twitter:description', content: description },
      { name: 'twitter:url', content: window.location.href },
      { name: 'twitter:image', content: cover },
      { property: 'og:title', content: title },
      { property: 'og:description', content: description },
      { property: 'og:url', content: window.location.href },
      { property: 'og:image', content: cover },
      { property: 'og:image:alt', content: 'UNI- Design competitions' },
      { property: 'og:type', content: 'article' }
    ];
    metaInfo.forEach(meta => {
      this.metaService.updateMetaInfo(meta);
    });
    this.metaService.updateMeta(metaInfo);
    this.metaService.updateTitle(title);
    this.metaService.createCanonicalURL();
  }
}
