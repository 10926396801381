<div class="main-container">
  <div class="cover-wrapper">
    <div class="cover-container" fxLayout="column">
      <app-landing-page-header [smallView]="smallView" [user]="user"> </app-landing-page-header>
      <div class="content-container" fxLayout="column" fxLayoutAlign="space-between">
        <div class="main-content">
          <div class="text-section" fxLayout="column">
            <div class="title-section">
              <h1 class="title margin-0">
                Enabling institutions to push beyond academic challenges!
              </h1>
            </div>
            <p class="subtitle margin-0">
              A first-of-its-kind platform enabling knowledge exchange for universities globally.
            </p>
          </div>
          <uni-image
            class="img-section"
            src="https://uni-common-assets.s3.amazonaws.com/designers/institutional-access/cover-img.svg"
            [alt]="'host/list a competition'"
            classes="fit-cover width-100 height-100">
          </uni-image>
        </div>
        <div class="sub-topic-cover-content">
          <div class="wrapper" fxLayout="column">
            <div class="text-section width-100 margin-auto" fxLayout="column">
              <div class="title-section">
                <h2 class="title margin-0">What is Institutional Access (IA)?</h2>
              </div>
              <p class="subtitle margin-0">
                IA is designed to allow students to participate in larger groups, along with
                mentor(s), to solve some of the most challenging problems across the globe, thus,
                expanding their reach beyond the studio.
              </p>
            </div>
            <div class="width-100" fxLayout="row" fxLayoutAlign="center center">
              <div class="card-container">
                <ng-container *ngFor="let card of platformFeatures">
                  <div class="platform-feature-card" fxLayout="column">
                    <uni-image
                      [src]="card.icon"
                      alt="platform-feature-card-img"
                      classes="big-dot"></uni-image>
                    <div
                      class="card-text-section"
                      fxLayout="column"
                      fxLayoutAlign="center"
                      fxLayoutGap="24px">
                      <h4 class="title margin-0">{{ card.title }}</h4>
                      <p class="description margin-0">{{ card.description }}</p>
                    </div>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="how-it-works-section">
    <h2 class="margin-0 heading sparro-grey-900">How it works?</h2>
    <div class="how-it-works-body">
      <div class="builder-section">
        <div
          class="text-section"
          fxLayout="column"
          fxLayoutAlign="space-between center"
          fxLayoutGap="24px">
          <h3 class="title margin-0">Book your spot</h3>
          <p class="description margin-0 sparro-grey-800">
            Registration process for Institutional Access is similar to individual/ team
            registration. Registration should ideally be done by the mentor on behalf of entire
            class. You will receive a confirmation upon registration.
          </p>
          <div class="expansion-panel-container width-100">
            <ng-container *ngFor="let detail of subSections.slice(0, 3); let index = index">
              <mat-expansion-panel class="expansion-panel">
                <mat-expansion-panel-header (click)="setActiveIndex(index)">
                  <mat-panel-title>
                    <h4
                      [ngStyle]="{
                        color: expanded.includes(index) ? faqHeadingColor : '#232323',
                        'font-weight': expanded.includes(index) ? '700' : '400'
                      }"
                      class="margin-0">
                      {{ detail.title }}
                    </h4>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <p [innerHtml]="detail.description | safeContent" class="margin-bottom-0"></p>
              </mat-expansion-panel>
            </ng-container>
          </div>
        </div>
        <div class="image-section section-1 relative">
          <uni-image
            class="number-1 absolute"
            src="https://uni-common-assets.s3.amazonaws.com/designers/institutional-access/number-1.svg"
            [alt]="'number 1'"
            classes="fit-cover width-100 height-100">
          </uni-image>
          <uni-image
            class="frame left-sidenav-img absolute"
            src="https://uni-common-assets.s3.amazonaws.com/designers/institutional-access/left-sidenav.svg"
            [alt]="'host/list a competition'"
            classes="fit-cover width-100 height-100">
          </uni-image>
          <uni-image
            class="frame popup-img absolute"
            src="https://uni-common-assets.s3.amazonaws.com/designers/institutional-access/popup.svg"
            [alt]="'detail screen'"
            classes="fit-cover width-100 height-100">
          </uni-image>
          <uni-image
            class="frame icon-img absolute"
            src="https://uni-common-assets.s3.amazonaws.com/designers/institutional-access/institutional-access-icon.svg"
            alt="institutional access icon"
            classes="fit-cover width-100 height-100">
          </uni-image>
        </div>
      </div>
      <div class="builder-section even-section">
        <div class="image-section section-2 relative">
          <uni-image
            class="absolute number-2"
            src="https://uni-common-assets.s3.amazonaws.com/designers/institutional-access/number-2.svg"
            [alt]="'number 2'"
            classes="fit-cover width-100 height-100">
          </uni-image>
          <uni-image
            class="frame absolute empty-screenshot-img"
            src="https://uni-common-assets.s3.amazonaws.com/designers/institutional-access/empty-screenshot.svg"
            [alt]="'pricing detail'"
            classes="fit-cover width-100 height-100">
          </uni-image>
          <uni-image
            class="frame absolute project-card-img"
            src="https://uni-common-assets.s3.amazonaws.com/designers/institutional-access/project-card.svg"
            [alt]="'pricing detail'"
            classes="fit-cover width-100 height-100">
          </uni-image>
        </div>
        <div
          class="text-section"
          fxLayout="column"
          fxLayoutAlign="space-between start"
          fxLayoutGap="24px">
          <h3 class="title margin-0">Add entries for the competition</h3>
          <p class="description margin-0 sparro-grey-800">
            Mentor can add up to 15 entries across one or more competitions on UNI.
            Students can <b><a href="https://helpdesk.uni.xyz/en/articles/11-how-to-upload-your-project-on-uni-a-comprehensive-guide" target="_blank" style= "color: #545454;">create projects</a></b> 
            from their account and <b><a href="https://helpdesk.uni.xyz/en/articles/12-how-to-invite-team-members-to-your-project-on-uni" target="_blank" style="color: #545454;">invite mentors to their team</a></b>.
            Mentor can then review the published project and <b><a href="https://helpdesk.uni.xyz/en/articles/14-guide-to-submitting-your-project-in-a-uni-competition-entry" target="_blank" style="color: #545454;">submit them to the respective registered entries</a></b>.
          </p>
          <div class="expansion-panel-container width-100">
            <ng-container *ngFor="let detail of subSections.slice(3, 5); let index = index">
              <mat-expansion-panel class="expansion-panel">
                <mat-expansion-panel-header (click)="setActiveIndex(index + 3)">
                  <mat-panel-title>
                    <h4
                      [ngStyle]="{
                        color: expanded.includes(index + 3) ? faqHeadingColor : '#232323',
                        'font-weight': expanded.includes(index + 3) ? '700' : '400'
                      }"
                      class="margin-0">
                      {{ detail.title }}
                    </h4>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <p [innerHtml]="detail.description | safeContent" class="margin-bottom-0"></p>
              </mat-expansion-panel>
            </ng-container>
          </div>
        </div>
      </div>
      <div class="builder-section">
        <div
          class="text-section"
          fxLayout="column"
          fxLayoutAlign="space-between start"
          fxLayoutGap="24px">
          <h3 class="title margin-0">Meet your partner institution</h3>
          <p class="description margin-0 sparro-grey-800">
            As soon as we match you with your partner institution, you will get notified and we will open a discussion channel between all the participating members. 
            Everyone can discuss ideas and exchange resources related to the competition. Faculty members/mentors can explore the
            entry of their partner institutions and evaluate other entries as well..
          </p>
          <div class="expansion-panel-container width-100">
            <ng-container *ngFor="let detail of subSections.slice(5, 8); let index = index">
              <mat-expansion-panel class="expansion-panel">
                <mat-expansion-panel-header (click)="setActiveIndex(index + 5)">
                  <mat-panel-title>
                    <h4
                      [ngStyle]="{
                        color: expanded.includes(index + 5) ? faqHeadingColor : '#232323',
                        'font-weight': expanded.includes(index + 5) ? '700' : '400'
                      }"
                      class="margin-0">
                      {{ detail.title }}
                    </h4>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <p [innerHtml]="detail.description | safeContent" class="margin-bottom-0"></p>
              </mat-expansion-panel>
            </ng-container>
          </div>
        </div>
        <uni-image
          src="https://uni-common-assets.s3.amazonaws.com/designers/institutional-access/number-3.svg"
          [alt]="'number 3'"
          classes="fit-cover width-100 height-100">
        </uni-image>
      </div>
      <div class="builder-section even-section">
        <div class="image-section">
          <uni-image
            class="number-4-img"
            src="https://uni-common-assets.s3.amazonaws.com/designers/institutional-access/number-4.svg"
            [alt]="'number 4'"
            classes="fit-cover width-100 height-100">
          </uni-image>
        </div>
        <div
          class="text-section"
          fxLayout="column"
          fxLayoutAlign="space-between start"
          fxLayoutGap="24px">
          <h3 class="title margin-0">Achievement and Awards</h3>
          <p class="description margin-0 sparro-grey-800">
            Once the competition period is over, we declare the result openly with one winner for
            every institutional access and an international winner of the competition.
          </p>
          <div class="expansion-panel-container width-100">
            <ng-container *ngFor="let detail of subSections.slice(8, 10); let index = index">
              <mat-expansion-panel class="expansion-panel">
                <mat-expansion-panel-header (click)="setActiveIndex(index + 8)">
                  <mat-panel-title>
                    <h4
                      [ngStyle]="{
                        color: expanded.includes(index + 8) ? faqHeadingColor : '#232323',
                        'font-weight': expanded.includes(index + 8) ? '700' : '400'
                      }"
                      class="margin-0">
                      {{ detail.title }}
                    </h4>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <p [innerHtml]="detail.description | safeContent" class="margin-bottom-0"></p>
              </mat-expansion-panel>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    fxLayout="column"
    fxLayoutAlign="center center"
    fxLayoutGap="40px"
    class="request-demo-section">
    <h3 class="title">Have any queries?</h3>
    <p class="sparro-grey-500 margin-0 desc">
      Reach out to us at
      <a href="mailto:support@uni.xyz" class="no-decor-hover"> support@uni.xyz </a>
    </p>
    <a href="https://uni.xyz/payment/products/fe049e85-f5e3-4f2e-8551-9a6698a55b4b" class="no-decor cursor-pointer">
      <uni-navigation-button default="Get IA Pass" button_class="secondary" customClass="demo-btn">
      </uni-navigation-button>
    </a>
  </div>
  <app-institution-group class="margin-auto"></app-institution-group>
  <uni-frequent-questions
    [headingColor]="faqHeadingColor"
    headingText="General Terms & FAQs"
    [FAQs]="frequentlyAskedQuestions">
  </uni-frequent-questions>
</div>
<uni-public-footer [topMargin]="false"></uni-public-footer>
